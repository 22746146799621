import { useCallback, useEffect, useState } from "react";
import cn from "classnames";
// @ts-ignore
import union from "set.prototype.union";
// @ts-ignore
import difference from "set.prototype.difference";
import { TableColumn, TableRowType } from "./TableTypes";
import "./Table.css";
import MemoizedTableRow from "./TableRow.tsx";
import Checkbox from "../Checkbox/Checkbox.tsx";
import { FilterModel } from '@hooks/useFiltersSearchParams';

export type TableProps<T> = {
  columns: TableColumn<T>[];
  columnsSort?: string[];
  filters?: FilterModel[];
  data: T[];
  selectable?: boolean;
  isRowSelectable?: (data: T) => boolean;
  onSelectionChange?: (selectedRowIds: string[], data: T[]) => void;
  selectedIds?: string[];
  rowId?: (row: T) => string;
  rowClassName?: string | ((row: TableRowType<T>) => string);
  rowOnClick?: (row: T) => void;
  className?: string;
  wrapperClassName?: string;
  isGrey?: boolean;
  isHeadless?: boolean;
  isSmall?: boolean;
  isStriped?: boolean;
  noBorder?: boolean;
  ref?: React.RefObject<HTMLDivElement>;
  idKey?: string;
};

function defaultRowId<T>(row: T, idKey?: string) {
  // @ts-expect-error
  return String(row[idKey || "id"])
}

function Table<T>(props: TableProps<T>): JSX.Element {
  const [selectedRowIds, setSelectedRowIds] = useState<Set<string>>(
    new Set<string>(),
  );

  useEffect(() => {
    if (props.selectedIds) setSelectedRowIds(new Set(props.selectedIds));
  }, []);

  const rowId = props.rowId || defaultRowId<T>;

  useEffect(() => {
    if (typeof props.onSelectionChange === "function") {
      props.onSelectionChange(
        Array.from(selectedRowIds),
        props.data
      );
    }
  }, [selectedRowIds]);

  const handleToggleSelect = useCallback((rowId: string) => {
    setSelectedRowIds((currentRowIds: Set<string>) => {
      const newRowIds = new Set(currentRowIds);
      if (newRowIds.has(rowId)) {
        newRowIds.delete(rowId);
      } else {
        newRowIds.add(rowId);
      }
      return newRowIds;
    });
  }, []);

  const selectedRowIdsFromCurPage = props.data.map(
    (item) => rowId(item, props.idKey)
  ).filter((item) => selectedRowIds.has(item));

  const selectableData = props.data.filter((item) => (
    (typeof props.isRowSelectable !== "function")
    || props.isRowSelectable(item)
  ))
  const allPageSelected = Boolean(selectedRowIdsFromCurPage.length) && (selectableData.length === selectedRowIdsFromCurPage.length);
  const pagePartialySelected = Boolean(selectedRowIdsFromCurPage.length)
    && (selectableData.length > selectedRowIdsFromCurPage.length);

  const toggleSelectAll = useCallback(() => {
    const curPageIds = new Set(
      props.data.filter((item) => (
        (typeof props.isRowSelectable !== "function")
        || props.isRowSelectable(item)
      )).map((item) => rowId(item, props.idKey))
    );
    if (allPageSelected) {
      setSelectedRowIds(difference(selectedRowIds, curPageIds));
    } else {
      setSelectedRowIds(union(selectedRowIds, curPageIds));
    }
  }, [selectedRowIds.size, allPageSelected, props.idKey]);

  return (
    <div
      className={cn(
        "table-wrapper",
        props.noBorder && "table-wrapper--no-border",
        props.wrapperClassName,
      )}
      ref={props.ref}
    >
      <table
        className={cn(
          "table",
          props.isSmall && "table--small",
          props.isGrey && "table--grey",
          props.className,
        )}>
        {props.isHeadless ? null : (
          <thead>
            <tr className={"table__row table__row--head"}>
              {props.selectable && (
                <th className="table__cell table__cell--header table__cell--checkbox">
                  <Checkbox
                    id={"all"}
                    checked={allPageSelected}
                    partial={pagePartialySelected}
                    onChange={toggleSelectAll}
                  />
                </th>
              )}
              {props.columns.map((column, index) => (
                <th
                  className={cn(
                    "table__cell table__cell--header",
                    column.alignment && `table__cell--${column.alignment}`,
                  )}
                  key={index}
                  style={
                    column.fixWidth === true
                      ? { width: column.width }
                      : { maxWidth: column.width }
                  }
                >
                  {column.sortable ? (
                      <div
                          className={cn('table__cell--sortable',
                              column.alignment && `table__cell--sortable-${column.alignment}`,
                          )}
                      >
                        {column.header}
                        <div className="table__cell--action">
                          <button
                              className={`table__cell--sorting table__cell--sorting-${props.columnsSort![index]}`}
                              onClick={() => {
                                column.sortableFn!(
                                    index,
                                    props.columnsSort![index] === 'asc' ? 'desc' : 'asc',
                                    props.filters!
                                );
                              }}
                          />
                        </div>
                      </div>
                  ) : (
                      <>{column.header}</>
                  )}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {props.data.map((row, idx) => (
            <MemoizedTableRow<T>
              index={idx}
              key={rowId(row)}
              selectable={props.selectable}
              isRowSelectable={props.isRowSelectable}
              row={row}
              isSelected={selectedRowIds.has(rowId(row, props.idKey))}
              isStriped={props.isStriped}
              onToggleSelect={handleToggleSelect}
              columns={props.columns}
              id={rowId(row, props.idKey)}
              rowClassName={props.rowClassName}
              rowOnClick={props.rowOnClick}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
