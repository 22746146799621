import { Address } from '@api/apiClient';

const getFullAddress = (address: Address): string => {
  const rawLineParts: (string | undefined)[] = [
    address.zipCode,
    address.country,
    address.region,
    address.city,
    address.cityDistrict,
    address.street,
    address.house,
    address.entrance,
    address.floor,
    address.flat,
  ];

  return rawLineParts.filter(v => Boolean(v)).join(', ');
};

export default getFullAddress;
