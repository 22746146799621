import styles from './DisposalOrder.module.scss';
import OrderListItem from '../OrderListItem/OrderListItem';
import { formatSumWithCurrency } from '@utils/formatValues';
import { IDisposalOrderProps } from '../../types';
import { TAwaitingParcel } from '@api/apiClient/returns';
import { Text, TextColor, TextSize, TextWeight } from '@components/Text';
import { useProfile } from '@contexts/ProfileContext';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const DisposalOrder: React.FC<IDisposalOrderProps> = (props: IDisposalOrderProps): React.ReactElement => {
  const { parcels, selectedSumCny, selectedSumRub } = props;

  const [numberOfVisibleItems, setNumberOfVisibleItems] = useState<number>(8);

  const { account } = useProfile();
  const { t } = useTranslation();

  const variableCurrency: string = account?.region === 'TR' ? '$' : '¥';

  return (
      <div className={styles.order}>
        {parcels.size > 1 && (
            <div className={styles.info}>
              <div className={styles.point}>
                <div className={styles.pointList}>
                  <div className={styles.pointListItem}>
                    <Text
                        color={TextColor.PRIMARY}
                        size={TextSize.XS}
                        weight={TextWeight.MEDIUM}
                    >
                      {t('disposalOrder.pointListItem1')}
                    </Text>
                    <div className={styles.pointListItemValue}>
                      <Text
                          color={TextColor.PRIMARY}
                          size={TextSize.L}
                          weight={TextWeight.BOLD}
                      >
                        {parcels.size}
                      </Text>
                    </div>
                  </div>
                  <div className={styles.pointListItem}>
                    <Text
                        color={TextColor.PRIMARY}
                        size={TextSize.XS}
                        weight={TextWeight.MEDIUM}
                    >
                      {t('disposalOrder.pointListItem2')}
                    </Text>
                    <div className={styles.pointListItemValue}>
                      <Text
                          color={TextColor.PRIMARY}
                          size={TextSize.L}
                          weight={TextWeight.BOLD}
                      >
                        {formatSumWithCurrency(selectedSumRub, '₽')}
                      </Text>
                      <Text
                          color={TextColor.DARK_MUTED}
                          size={TextSize.XS}
                      >
                        {formatSumWithCurrency(selectedSumCny, variableCurrency)}
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        )}
        <div className={styles.info}>
          <ul className={styles.infoList}>
            {Array.from(parcels.values()).map((item: TAwaitingParcel, index: number) => {
              if (parcels.size === numberOfVisibleItems || index < numberOfVisibleItems) {
                return (
                    <OrderListItem
                        item={item}
                        key={index}
                    />
                );
              }
            })}
          </ul>
          {parcels.size > numberOfVisibleItems && (
              <button
                  className={styles.infoAction}
                  onClick={() => setNumberOfVisibleItems(parcels.size)}
              >
                {`+ ${t('disposalOrder.more')} ${(parcels.size - numberOfVisibleItems)}`}
              </button>
          )}
        </div>
      </div>
  );
};

export default DisposalOrder;