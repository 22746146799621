import { useMemo, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
// import cn from "classnames";
import {
  getWarehause,
  WarehouseStockItem,
} from "@api/apiClient/fulfillment";
import { ResponsePages } from "@api/apiClient/types";
import { weightKg, getCurrency } from "@utils/formatValues";
import { TableColumn, TableColumnType } from "@components/Table/TableTypes";
import DataTable, { DataViewHandle } from "@containers/DataTable";
import { PaginationModel } from "@hooks/usePagingSearchParams";
import EmptyWarehouse from "../components/EmptyWarehouse";
import styles from "./AvailableTab.module.css";

type RequestType = ({ page }: { page: PaginationModel }) => Promise<ResponsePages<WarehouseStockItem>>

const AvailableTab = ({
  onCreateOrder,
  warehouseId,
}: {
  warehouseId: string,
  onCreateOrder: () => void,
}) => {
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  const dataRef = useRef<DataViewHandle>(null);

  const getWarehauseMethod: RequestType = useCallback(
    () => getWarehause({ warehouseId }),
    [warehouseId],
  );

  const columns: TableColumn<WarehouseStockItem>[] = useMemo(() => [
    {
      header: t("fulfillmentPage.tabAvailable.article"),
      accessor: (row) => row.data.ozonSku,
      type: TableColumnType.SECONDARY,
      alignment: "left",
      width: "80px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: "",
      accessor: (row) => (
        <img className={styles.producImage} src={row.data.primaryImage} alt="" />
      ),
      alignment: "left",
      width: "50px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("fulfillmentPage.tabAvailable.name"),
      accessor: (row) => (
        <a
          href={row.data.ozonUrl}
          target="_blank"
          rel="noreferrer"
          className={styles.productLink}
        >
          {row.data.name}
        </a>
      ),
      alignment: "left",
      width: "700px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("fulfillmentPage.tabAvailable.weight"),
      accessor: (row) => weightKg(row.data.weight),
      type: TableColumnType.TERTIARY,
      alignment: "right",
      width: "80px",
      fixWidth: true,
    },
    {
      header: t("fulfillmentPage.tabAvailable.cost"),
      accessor: (row) => `${row.data.price?.amount} ${getCurrency(row.data.price?.currency)}`,
      type: TableColumnType.SECONDARY,
      alignment: "right",
      width: "80px",
      fixWidth: true,
    },
    {
      header: t("fulfillmentPage.tabAvailable.count"),
      accessor: (row) => row.data.present,
      type: TableColumnType.SECONDARY,
      alignment: "right",
      width: "80px",
      fixWidth: true,
    },
  ], [currentLanguage]);

  const EmptyWarehouseComponent = useMemo(() => () => (
    <EmptyWarehouse onCreateOrder={onCreateOrder} />
  ), [onCreateOrder])

  return (
    <div>
      <DataTable
        queryKey={`getWarehause-${warehouseId}`}
        rowId={(item: WarehouseStockItem) => item.productId}
        // pending={!isInitialized}
        getData={getWarehauseMethod}
        columns={columns}
        notFound={EmptyWarehouseComponent}
        filters={[]}
        defaultPageSize={20}
        dataRef={dataRef}
        withPagination={false}
      />
    </div>
  );
};

export default AvailableTab;
