import FormErrorMessage from '@components/FormErrorMessage';
import OzonAddApiKey from './components/OzonAddApiKey';
import OzonConfirmPhone from './components/OzonConfirmPhone';
import OzonSignUpForm from './components/OzonSignUpForm';
import { getOzonInfo, ozonSendCode, ozonSignUpByKey } from '@api/apiClient/signUp';
import { Grid } from '@components/Grid';
import {
  IApiKeyProps,
  IConfirmProps,
  IFormProps,
  IOzonSignUpPageProps,
  TOzonSignUpForm
} from './types';
import { storeToken } from '@utils/tokenUtils';
import { updateToken } from '@api/apiClient/sellerApiClient';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';

const OzonSignUpPage: React.FC<IOzonSignUpPageProps> = (props: IOzonSignUpPageProps): React.ReactElement => {
  const { email, refetch } = props;

  const [countryCode, setCountryCode] = useState<string>('');
  const [emailBox, setEmailBox] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [step, setStep] = useState<string>('form');

  const params = new URLSearchParams(location.search);
  const key = params.get('key');

  const {
    data: dataOzonInfo,
    error: errorOzonInfo,
    isError: isErrorOzonInfo
  } = useQuery({
    queryFn: () => getOzonInfo(key!),
    queryKey: ['ozonInfo'],
    retry: 1,
    enabled: !refetch
  });

  useEffect(() => {
    email && setEmailBox(email);
  }, [email]);

  useEffect(() => {
    refetch && setStep('apiKey');
  }, [refetch]);

  useEffect(() => {
    if (dataOzonInfo?.phone) {
      setEmailBox(dataOzonInfo?.email);
      setPhone(dataOzonInfo?.phone);
      setValue('phone', dataOzonInfo?.phone);
    }
  }, [dataOzonInfo]);

  const {
    error: errorOzonSendCode,
    isLoading: isLoadingOzonSendCode,
    mutate: mutateOzonSendCode,
    isError: isErrorOzonSendCode
  } = useMutation({
    mutationFn: ozonSendCode
  });

  const {
    error: errorOzonSignUpByKey,
    mutate: mutateOzonSignUpByKey,
    isError: isErrorOzonSignUpByKey
  } = useMutation({
    mutationFn: ozonSignUpByKey,
    onSuccess(result) {
      const now = new Date().getTime();
      const tokenData = {
        ...result,
        received_at: now
      };
      setStep('apiKey');
      storeToken(tokenData);
      updateToken(tokenData.access_token);
    }
  });

  const { control, handleSubmit, setValue, watch } = useForm<TOzonSignUpForm>({
    defaultValues: {
      countryCode: '',
      name: '',
      password: '',
      phone: ''
    }
  });

  const submit = (form: TOzonSignUpForm) => {
    mutateOzonSendCode(
        {
          key: key!,
          phone: form.phone
        },
        {
          onSuccess() {
            setCountryCode(form.countryCode);
            setName(form.name);
            setPassword(form.password);
            setPhone(form.phone);
            step !== 'confirm' && setStep('confirm');
          }
        });
  };

  const formProps: IFormProps = {
    control: control,
    handlerOnSubmit: handleSubmit(submit),
    isErrorOzonInfo: isErrorOzonInfo,
    isLoading: isLoadingOzonSendCode,
    watch: watch
  };

  const confirmProps: IConfirmProps = {
    goBack: () => setStep('form'),
    ozonKey: key!,
    handlerOnSuccess: () => {
      mutateOzonSignUpByKey({
        clientId: 'sellers-dashboard-webui',
        countryCode: countryCode,
        key: key!,
        name: name,
        password: password
      });
    },
    phone: phone,
    resendForm: handleSubmit(submit)
  };

  const apiKeyProps: IApiKeyProps = {
    email: emailBox,
    refetch: refetch
  };

  return (
      <Grid>
        {step === 'form' && <OzonSignUpForm {...formProps}/>}
        {step === 'confirm' && <OzonConfirmPhone {...confirmProps}/>}
        {step === 'apiKey' && <OzonAddApiKey {...apiKeyProps}/>}
        {isErrorOzonInfo && <FormErrorMessage error={errorOzonInfo}/>}
        {isErrorOzonSendCode && <FormErrorMessage error={errorOzonSendCode}/>}
        {isErrorOzonSignUpByKey && <FormErrorMessage error={errorOzonSignUpByKey}/>}
      </Grid>
  );
};

export default OzonSignUpPage;