import styles from './NewsPage.module.scss';
import cn from 'classnames';
import InfoPanel, { InfoPanelType } from '@components/InfoPanel';
import Page from '@components/Page';
import parse from 'html-react-parser';
import { formatDate } from '@utils/formatValues';
import { getCurrentLanguage } from '@utils/lang';
import { getNews, sendNewsRead, TOneNews, useNewsCounter } from '@api/apiClient/news';
import { Link, useSearchParams } from 'react-router-dom';
import { Loader } from '@components/Loader';
import { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NewsPage = () => {
  const [searchParams] = useSearchParams();
  const currentLanguage = getCurrentLanguage();
  const navigate = useNavigate();
  const newsId = searchParams.get('newsId');
  const { refetch: refetchNewsCounter } = useNewsCounter(currentLanguage);
  const { t } = useTranslation();

  const {
    data: dataGetNews,
    isFetching: isFetchingGetNews,
    refetch: refetchGetNews
  } = useQuery({
    queryFn: () => getNews(currentLanguage),
    queryKey: ['news'],
    retry: 1,
  });

  const { mutate: mutateSendNewsRead } = useMutation({
    mutationFn: sendNewsRead,
    onSuccess: () => refetchNewsCounter()
  });

  const currentItem = newsId ? dataGetNews?.items.find(item => item.link === newsId) : null;

  useEffect(() => {
    if (newsId && !isFetchingGetNews && !currentItem) {
      navigate('/news');
    }
  }, [newsId, currentItem, isFetchingGetNews]);

  useEffect(() => {
    if (!isFetchingGetNews) refetchGetNews();
  }, [newsId]);

  useEffect(() => {
    refetchGetNews();
    refetchNewsCounter();
  }, [currentLanguage]);

  if (isFetchingGetNews) {
    return (
        <Page title={t('news.title')}>
          <Loader size="s"/>
        </Page>
    );
  }

  if (currentItem) {
    return (
        <Page
            title={currentItem.title}
            backLink="/news"
        >
          <div className={styles.newsDetail}>
            {parse(currentItem.content.replaceAll('iksweb', 'button button--rounded'))}
          </div>
        </Page>
    );
  }

  return (
      <Page title={t('news.title')}>
        {dataGetNews?.items.length ? (
            <div className={styles.list}>
              {dataGetNews?.items.map((item: TOneNews, index: number) => (
                  <Link
                      className={styles.item}
                      key={item.link}
                      onClick={() => !item.isRead && mutateSendNewsRead(item.link)}
                      to={`/news?newsId=${item.link}`}
                  >
                    {!item.isRead && (
                        <span className={styles.itemImgNew}>NEW</span>
                    )}
                    {index < 4 && (
                        <div className={styles.itemImg}>
                          <img
                              className={styles.itemImgSelf}
                              alt={item.title}
                              src={item.imgUrl}
                          />
                        </div>
                    )}
                    <div className={styles.itemInfo}>
                      <span className={cn(styles.itemTitle, index > 3 && styles.itemTitleShortened)}>
                        {item.title}
                      </span>
                      <span className={styles.itemDescription}>
                        {item.description}
                      </span>
                      <span className={styles.itemDate}>
                        {formatDate(item.pubDate, 'dd.MM.yy HH:mm')}
                      </span>
                    </div>
                  </Link>
              ))}
            </div>
        ) : (
            <InfoPanel
                title={t('news.noNews')}
                type={InfoPanelType.INFO}
            />
        )}
      </Page>
  );
};

export default NewsPage;
