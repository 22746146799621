import { useCallback, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { ProblematicParcel, getProblematicParcels } from "@api/apiClient/problematicParcels";
import DataTable, { DataViewHandle } from "@containers/DataTable";
import TableSelectionPanel from "@components/Table/TableSelectionPanel";
import {
  TableColumn,
  TableColumnType,
  TableRowType,
} from "@components/Table/TableTypes";
import Avatar from "@components/Avatar";
import { Row } from "@components/Grid";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import ProgressBar from "@components/ProgressBar";
import { Text, TextColor, TextSize, TextWeight } from "@components/Text";
import CopyTextToClipboard from "@components/CopyTextToClipboard";
import Car from "@assets/icons/car.svg";
import Fire from "@assets/icons/fire.svg";
import { getProgressFromTo, getDaysBetweenDates } from "@utils/progress";
import { getCurrentLanguage } from "@utils/lang";
import { formatSumFromObject } from "@utils/formatValues";
import Reason from "./Reason";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import styles from "./AwaitingTable.module.css";
import { useProfile } from '@contexts/ProfileContext';

interface AwaitingTableProps {
  selectedSum: number;
  onClickDisposal: (item?: ProblematicParcel) => void;
  onClickDeliver: (item?: ProblematicParcel) => void;
  onSelectionChange: (selected: ProblematicParcel[]) => void;
  onParcelClick: (parcelId: string) => void;
  dataRef?: React.MutableRefObject<DataViewHandle | null>;
  pending?: boolean;
  filters: FilterModel[];
  selectedRowIds: string[];
  selectedDelivery: number;
}

const AwaitingTable = ({
  selectedRowIds,
  onClickDisposal,
  onClickDeliver,
  onParcelClick,
  selectedSum,
  pending,
  onSelectionChange,
  filters,
  dataRef,
  selectedDelivery,
}: AwaitingTableProps) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  const handleSelectionChange = useCallback((ids: string[], data: ProblematicParcel[]) => {
    const selected = (data || []).filter((v: ProblematicParcel) => ids.includes(v.id));
    onSelectionChange && onSelectionChange(selected);
  }, [onSelectionChange])

  const { account } = useProfile();

  const variableCurrency: string = account?.region === 'TR' ? '$' : '¥';

  const columns: TableColumn<ProblematicParcel>[] = useMemo(() => [
    {
      header: t("problematicParcelsPage.trackNumber"),
      accessor: (row: TableRowType<ProblematicParcel>) => (
        <>
          <CopyTextToClipboard visibleOnHover text={row.data?.trackNumber}>
            <div onClick={(e) => {
              e.stopPropagation()
              onParcelClick(row.data?.id)
            }}>
              <Text
                size={TextSize.S}
                color={TextColor.PRIMARY}
                onHoverColor={TextColor.BLUE}
                weight={TextWeight.BOLD}
              >
                {row?.data?.trackNumber}
              </Text>
            </div>
          </CopyTextToClipboard>
          <CopyTextToClipboard visibleOnHover text={row.data?.externalId ?? ""}>
            <div onClick={(e) => {
              e.stopPropagation()
              onParcelClick(row.data?.id)
            }}>
              <Text
                size={TextSize.XS}
                color={TextColor.SECONDARY}
              >
                {row?.data?.externalId}
              </Text>
            </div>
          </CopyTextToClipboard>
        </>
      ),
      alignment: "left",
      width: "150px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("problematicParcelsPage.reason"),
      accessor: (row: TableRowType<ProblematicParcel>) => <Reason value={row.data.reason} />,
      alignment: "left",
      width: "200px",
      fixWidth: true,
    },
    {
      header: t("problematicParcelsPage.service"),
      accessor: (row: TableRowType<ProblematicParcel>) => row.data.serviceName,
      type: TableColumnType.SECONDARY,
      alignment: "left",
      width: "200px",
      fixWidth: true,
    },
    {
      header: t("problematicParcelsPage.sum"),
      accessor: (row: TableRowType<ProblematicParcel>) => (
        formatSumFromObject(row.data.declaredValue)
      ),
      type: TableColumnType.SECONDARY,
      alignment: "left",
      width: "90px",
      fixedWidth: true,
    },
    {
      header: t("problematicParcelsPage.seller"),
      accessor: (row: TableRowType<ProblematicParcel>) => (
        <Row noWrap align="center">
          <Avatar
            className={styles.avatar}
            name={row.data.sellerName}
            id={row.data.sellerId}
          />
          <span className={styles.sellerText}>
            {row.data.sellerName}
          </span>
        </Row>
      ),
      type: TableColumnType.SECONDARY,
      alignment: "left",
      width: "200px",
      fixedWidth: true,
    },
    {
      header: t("problematicParcelsPage.toRecycle"),
      accessor: (row: TableRowType<ProblematicParcel>) => {
        const restDays = getDaysBetweenDates(null, row.data.estimatedUtilizationDate);
        return (
          <ProgressBar
            reverseColors
            progress={getProgressFromTo(row.data.createAt, row.data.estimatedUtilizationDate, true)}
            label={restDays ? `${restDays} ${t("problematicParcelsPage.days")}` : t("problematicParcelsPage.lastDay")}
          />
        )
      },
      alignment: "left",
      fixWidth: true,
      width: "100px",
    },
    {
      header: "",
      accessor: (row: TableRowType<ProblematicParcel>) => (
        <div className={styles.actionBtns}>
          <Button
            onClick={() => onClickDisposal(row.data)}
            size={ButtonSize.XS}
            title={t("problematicParcelsPage.recycleBtn")}
            type={ButtonType.DANGER}
          />

          <Button
            onClick={() => onClickDeliver(row.data)}
            size={ButtonSize.XS}
            title={t("problematicParcelsPage.returnBtn").replace('{{delivery}}', (account?.region === 'TR' ? 12 : row.data.isExtraSmall ? 10 : 20).toString()).replace('{{currency}}', variableCurrency)}
          />
        </div>
      ),
      alignment: "left",
      noOverflow: true,
      width: "350px",
    },
  ], [currentLanguage]);

  return (
    <TableSelectionPanel
      isShown={selectedRowIds.length > 0}
      items={[
        {
          label: t("awaitingTableSelectionPanel.selected"),
          value: selectedRowIds.length,
        },
        {
          label: t("awaitingTableSelectionPanel.sum"),
          value: `${Intl.NumberFormat().format(selectedSum)} ₽`,
        },
        {
          label: t("awaitingTableSelectionPanel.delivery"),
          value: `${Intl.NumberFormat().format(selectedDelivery)} ${variableCurrency}`
          ,
        },
      ]}
      buttons={[
        (<Button
          key="disposal"
          onClick={() => onClickDisposal()}
          size={ButtonSize.S}
          icon={Fire}
          type={ButtonType.DANGER}
          title={t("problematicParcelsPage.recycleBtn")}
        />),
        (<Button
          key="deliver"
          onClick={() => onClickDeliver()}
          size={ButtonSize.S}
          icon={Car}
          title={t("problematicParcelsPage.returnBtn").replace('{{delivery}}', selectedDelivery.toString()).replace('{{currency}}', variableCurrency)}
        />),
      ]}
    >
      <DataTable<ProblematicParcel>
        queryKey="getProblematicParcels"
        pending={pending}
        getData={getProblematicParcels}
        columns={columns}
        selectable
        selectedIds={selectedRowIds}
        onSelectionChange={handleSelectionChange}
        notFound={t("awaitingPage.not_await")}
        filters={[
          ...filters,
          { name: "Statuses", value: ["AwaitingDecision"] },
        ]}
        dataRef={dataRef}
      />
    </TableSelectionPanel>
  );
};

export default AwaitingTable;
