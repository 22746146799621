import Box from '@components/Box';
import CountrySelectContainer from '@containers/CountrySelectContainer';
import FormDocs from '@components/FormDocs';
import ProgressBar from '@components/ProgressBar';
import TextInputContainer from '@containers/TextInputContainer';
import TooltipIcon from '@assets/icons/tooltip.svg';
import zxcvbn from 'zxcvbn';
import { Button, ButtonSize, ButtonType } from '@components/Button';
import { Column, Row } from '@components/Grid';
import { IFormProps } from '../types';
import { Text, TextSize, TextType } from '@components/Text';
import { Tooltip } from '@components/Tooltip';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const OzonSignUpForm: React.FC<IFormProps> = (props: IFormProps): React.ReactElement => {
  const { control, handlerOnSubmit, isErrorOzonInfo, isLoading, watch } = props;

  const { t } = useTranslation();

  const tooltipText: string = `${t('signUp.email.passwordDescription')}<br/><br/>- ${t('signUp.email.passwordDescription2')}<br/><br/>- ${t('signUp.email.passwordDescription3')}<br/><br/>- ${t('signUp.email.passwordDescription4')}`;

  const password = watch('password');
  const passScore: number = useMemo(() => zxcvbn(password).score, [password]);

  return (
      <>
        <Row>
          <Column phone={12}>
            <Box pb={4}>
              <Text type={TextType.H1}>
                {t('ozonSignUpPage.contactDetails.title')}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box pb={5}>
              <Text size={TextSize.M}>
                {t('ozonSignUpPage.contactDetails.description')}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box pb={5}>
              <TextInputContainer
                  control={control}
                  name="name"
                  rules={{ required: t('defaultFormErrors.required') }}
                  title={t('ozonSignUpPage.contactDetails.name')}
              />
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box pb={5}>
              <TextInputContainer
                  control={control}
                  name="phone"
                  rules={{ required: t('defaultFormErrors.required') }}
                  title={t('ozonSignUpPage.contactDetails.phone')}
                  type="phone"
              />
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box pb={7}>
              <CountrySelectContainer
                  allowedCountries={['CN', 'TR']}
                  control={control}
                  name="countryCode"
              />
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box pb={2}>
              <TextInputContainer
                  control={control}
                  name="password"
                  rules={{ required: t('defaultFormErrors.required') }}
                  title={t('ozonSignUpPage.contactDetails.password')}
                  type="password"
              />
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box pb={7}>
              <ProgressBar
                  progress={25 * +passScore}
                  colors={[
                    { value: 25, main: '#EE6373' },
                    { value: 50, main: '#F2AF4C' },
                    { value: 75, main: '#3ECC92' },
                    { value: 100, main: '#4CA36A' }
                  ]}
                  label={(
                      <>
                        <Text size={TextSize.S}>
                          {t('ozonSignUpPage.contactDetails.passStrengthTitle')}
                          {': '}
                          <b>{t('ozonSignUpPage.contactDetails.passStrengthValue', { context: passScore })}</b>
                          {'  '}
                        </Text>
                        <Tooltip
                            action={
                              <img
                                  src={TooltipIcon}
                                  alt="Icon"
                              />
                            }
                            text={tooltipText}
                            top={33}
                        />
                      </>
                  )}
                  column
                  rounded
              />
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box pb={5}>
              <Button
                  disabled={isErrorOzonInfo}
                  onClick={handlerOnSubmit}
                  pending={isLoading}
                  size={ButtonSize.S}
                  title={t('ozonSignUpPage.contactDetails.next')}
                  type={ButtonType.BLOCK}
              />
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box>
              <FormDocs/>
            </Box>
          </Column>
        </Row>
      </>
  );
};

export default OzonSignUpForm;