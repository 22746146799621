import { useAuth } from "./contexts/AuthenticationContext";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { getAccountById } from "./api/apiClient";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import GuestLayout from "./layouts/GuestLayout";
import RegistrationPage from "./pages/RegistrationPage";
import AuthenticatedLayout from "./layouts/AuthenticatedLayout";
import AuthenticatedEmptyLayout from "./layouts/AuthenticatedEmptyLayout";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import ParcelsPage from "./pages/ParcelsPage";
import ReturnsPage from "./pages/ReturnsPage";
import UnclaimedPage from "./pages/UnclaimedPage";
import PickUpPage from "./pages/PickUpPage";
import NewsPage from "./pages/NewsPage";
import BunchPage from "./pages/BunchPage";
import SendEmailToResetPasswordPage from "./pages/SendEmailToResetPasswordPage/SendEmailToResetPassworPage.tsx";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage.tsx";
import SupportPage from "./pages/SupportPage";
import DropshippingPage from "./pages/DropshippingPage";
import Products from "./pages/ProductsPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage.tsx";
import FulfillmentPage from "./pages/FulfillmentPage";
import ProblematicPage from "./pages/ProblematicPage";
import ComplaintPage from "./pages/ComplaintPage";
import UserRegistrationPage from "./pages/UserRegistrationPage";
import { isAdmin } from "@utils/auth";
import ZendeskWidget from "./components/Zendesk/ZendeskWidget.tsx";
import { Portal } from "@components/Portal";
import { ToastContainer } from 'react-toastify';
import RouterProvider from "@contexts/RouterContext/RouterProvider";
import 'react-toastify/dist/ReactToastify.css';
import { getPickUpInfo } from '@api/apiClient/pickUp';
import * as Sentry from "@sentry/react";
import OzonSignUpPage from './pages/OzonSignUpPage';
import {VITE_APP_DEV} from "@utils/config.ts";

function App() {
  const auth = useAuth();
  const { t } = useTranslation();
  const favicon = "/favicon-uni.svg";

  return (
    <>
      <Helmet>
        <title>{t("title")}</title>
        <link rel="icon" type="image/x-icon" href={favicon} />
        <link rel="apple-touch-icon" href={favicon} sizes="180x180" />
        <link rel="mask-icon" href={favicon} color="#FFFFFF" />
      </Helmet>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
      {auth.isAuthenticated ? <ZendeskWidget /> : ""}
      <Portal>
        <ToastContainer
          position="top-left"
          closeOnClick
          style={{ zIndex: 1000002 }}
        />
      </Portal>
    </>
  );
}

const AppRoutes = () => {
  const location = useLocation();
  const adminUrl: string = 'admin-login-land';
  const region: string | null = localStorage.getItem('region');

  const auth = useAuth();
  const query = useQuery({
    queryKey: ["account"],
    queryFn: () => getAccountById(),
    retry: 1,
    enabled: auth.isAuthenticated,
  });

  const pickUp = useQuery({
    queryKey: ["pickUp"],
    queryFn: () => getPickUpInfo(),
    retry: 1,
    enabled: auth.isAuthenticated,
    staleTime: Infinity,
  });

  const isLogout = sessionStorage.getItem("isLogout");
  if (location.pathname === "/login") sessionStorage.removeItem("isLogout");
  const redirectTo = location?.state?.redirectTo
    ? `${location.state.redirectTo.pathname}${location.state.redirectTo.search}`
    : "";

  const isNewUser = !query.data?.isPhoneConfirmed;

  if (auth.isAuthenticated && !query.isFetched) {
    return null;
  }

  if (!isAdmin() && auth.isAuthenticated && isNewUser) {
    return (
      <AuthenticatedEmptyLayout>
        <UserRegistrationPage />
      </AuthenticatedEmptyLayout>
    );
  }

  return (
    <RouterProvider>
      <Routes>
        {!auth.isAuthenticated && (
          <Route path="/" element={<GuestLayout />}>
            {!auth.isLoading && (
              <Route
                index
                element={(
                  <Navigate replace to="/login" state={{ redirectTo: isLogout ? null : location }} />
                )}
              />
            )}
            <Route path="registration" element={<RegistrationPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path={adminUrl} element={<LoginPage isAdmin />} />
            <Route path="send-email-to-reset-password" element={<SendEmailToResetPasswordPage />}/>
            <Route path="signup" element={<SignUpPage />} />
            <Route path="signup/ozon" element={<OzonSignUpPage />} />
            <Route path="registration/ozon" element={<OzonSignUpPage />} />
            <Route path="open-registration" element={<SignUpPage />} />
            <Route path="reset-password" element={<ResetPasswordPage />} />
            {!auth.isLoading && (
                <>
                  {isAdmin()
                    ? <Route path="*" element={<Navigate replace to={`/${adminUrl}`} />} />
                    : (
                      <Route
                        path="*"
                        element={(
                          <Navigate replace to="/login" state={{ redirectTo: isLogout ? null : location }} />
                        )} />
                    )
                  }
                </>
            )}
          </Route>
        )}
        {auth.isAuthenticated && (
            <>
              {query.data?.isOzonConfirmed === false ? (
                  <Route path="/" element={<GuestLayout />} >
                    <Route path="*" element={<Navigate replace to={'signup/ozon'} />} />
                    <Route path="signup/ozon" element={<OzonSignUpPage email={query.data.email} refetch={query.refetch}/>} />
                    <Route path="registration/ozon" element={<OzonSignUpPage email={query.data.email} refetch={query.refetch}/>} />
                  </Route>
              ) : (
                  <Route path="/" element={<AuthenticatedLayout region={query?.data?.region || ""}/>} >
                    <Route index element={<Navigate replace to={redirectTo || "/unclaimed"}/>} />
                    <Route path="bunch" element={<BunchPage />} />
                    <Route path="fulfillment" element={<FulfillmentPage />} />
                    <Route path="fulfillment/:warehouseId" element={<FulfillmentPage />} />
                    <Route path="fulfillment/:warehouseId/request/:requestId" element={<FulfillmentPage />} />
                    <Route path="news" element={<NewsPage />} />
                    <Route path="parcels" element={<ParcelsPage />} />
                    <Route path="profile" element={<ProfilePage />} />
                    <Route path="returns" element={<ReturnsPage />} />
                    <Route path="unclaimed" element={<UnclaimedPage />} />
                    {(region === "CN") && <Route path="complaints" element={<ComplaintPage />} />}
                    {(region === "CN") && <Route path="complaints/:requestId" element={<ComplaintPage />} />}
                    {(region === "CN") && <Route path="labelling" element={<DropshippingPage />} />}
                    {(region === "CN") && <Route path="pick-up" element={<PickUpPage isNew={pickUp?.data?.isNew || false}/>} />}
                    {(region === "CN") && <Route path="problematic-parcels" element={<ProblematicPage />} />}
                    {(region || query.isFetched) && <Route path="*" element={<Navigate replace to={redirectTo || "/"} />} />}
                    {VITE_APP_DEV && <Route path="products" element={<Products />} />}
                    {VITE_APP_DEV && <Route path="support" element={<SupportPage />} />}
                  </Route>
              )}
            </>
        )}
      </Routes>
    </RouterProvider>
  ) 
}

export default Sentry.withProfiler(App);
