import React, { useState, useEffect, useCallback, useMemo } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import Box from "@components/Box/Box.tsx";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import { Grid, Column, Row } from "@components/Grid";
import { Loader } from "@components/Loader";
import Checkbox from "@components/Checkbox";
import MultiSelect, {
  MultiSelectOption,
  MultiSelectOptionProps,
  ValueComponentProps,
} from "@components/MultiSelect";
import chevronDown from "@assets/icons/chevron-down-svgrepo-com.svg";
import chevronUp from "@assets/icons/chevron-up-svgrepo-com.svg";
import Cross from "@assets/icons/cross-white.svg";
import styles from "./MultiSelectFilter.module.css";

interface OptionComponentType {
  (props: MultiSelectOptionProps<string>): React.ReactNode
}
interface ValueComponentType {
  (props: ValueComponentProps<string>): React.ReactNode
}

export type OptionType = MultiSelectOption<string>

const OptionContainer = (OptionComponent?: (props: MultiSelectOptionProps<string>) => React.ReactNode) => (
  { id, value, isSelected }: MultiSelectOptionProps<string>
) => (
  <div className={cn(styles.option, isSelected && styles.optionSelected)}>
    <div className={styles.checkbox}>
      <Checkbox
        id={id}
        checked={isSelected}
        onChange={() => {}}
      />
    </div>
    {OptionComponent ? (
      <OptionComponent
        id={id}
        value={value}
        isSelected={isSelected}
      />
    ) : (<span className={styles.optionSpan}>{value}</span>)}
  </div>
)

const ValueContainer = (
  ValueComponent?: (props: ValueComponentProps<string>) => React.ReactNode,
  placeholder?: React.ReactNode,
  asPanel?: boolean,
) => (
  { values, isOpen, options }: ValueComponentProps<string>
) => {
  return (
    <div className={cn(styles.input, asPanel && styles.inputPanel)}>
      {ValueComponent ? (
        <ValueComponent
          values={values}
          isOpen={isOpen}
          options={options}
        />
      ) : (
        <span className={styles.valueSpan}>
          {values.length > 1 ? `(${values.length}) ` : ""}
          {values.length
            ? values.map(v => v.value).join(", ")
            : (placeholder || "")
          }
        </span>
      )}
      <img
        src={isOpen ? chevronUp : chevronDown}
        className={styles.chevron}
        alt=""
      />
    </div>
  )
}

const MultiSelectFilter = ({
  onChange,
  initialValue,
  ValueComponent,
  OptionComponent,
  isFetching,
  data,
  placeholder,
  asPanel,
}: {
  onChange: (sellers: string[]) => void;
  initialValue: string[];
  ValueComponent?: ValueComponentType;
  OptionComponent?: OptionComponentType;
  isFetching: boolean;
  data: OptionType[];
  placeholder?: React.ReactNode;
  asPanel?: boolean;
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<OptionType[]>([]);
  const [saved, setSaved] = useState<OptionType[]>([]);

  const resetFilter = useCallback(() => {
    setSelected(saved);
  }, [saved]);

  useEffect(() => {
    if (initialValue?.length && !isFetching) {
      const newValue = (data ?? []).filter(v => initialValue.includes(v.id));
      setSelected(newValue);
      setSaved(newValue);
      onChange(newValue.map(v => v.id));
    } else if (!isFetching) {
      onChange([]);
    }
  }, [initialValue, isFetching])

  const Value = useMemo(() => ValueContainer(ValueComponent, placeholder, asPanel), [ValueComponent, placeholder, asPanel]);
  const Option = useMemo(() => OptionContainer(OptionComponent), [OptionComponent]);

  const MenuFooter = ({ onClose }: {
    onClose: () => void,
  }) => (
    <>
      {isFetching && !data?.length && (
        <Box pt={4} pb={4} pl={5} pr={5}>
          <Grid>
            <Row>
              <Column phone={12}>
                <Loader alignment="center" size="s" />
              </Column>
            </Row>
          </Grid>
        </Box>
      )}
      <Row>
        <Box pt={4} pl={5} pr={5}>
          <Button
            onClick={() => {
              onChange(selected.map(v => v.id));
              setSaved(selected);
              onClose();
            }}
            className={styles.saveBtn}
            size={ButtonSize.S}
            title={t("filters.apply")}
            isWide
          />
        </Box>
      </Row>
      <Row>
        <Box pt={2} pb={4} pl={5} pr={5}>
          <Button
            onClick={() => {
              setSelected(data);
            }}
            className={styles.selectAllBtn}
            size={ButtonSize.S}
            title={t("filters.selectAll")}
            disabled={selected.length === data.length}
            isWide
          />
          <Button
            icon={Cross}
            onClick={() => {
              setSelected([]);
            }}
            iconClassName={styles.resetBtnIcon}
            size={ButtonSize.S}
            type={ButtonType.BLACK}
            disabled={!selected.length}
            isNarrow
          />
        </Box>
      </Row>
    </>
  )

  return (
    <div className={styles.root}>
      <MultiSelect<string>
        menuAlign="left"
        options={data}
        value={selected}
        onChange={setSelected}
        onClose={resetFilter}
        ValueComponent={Value}
        OptionComponent={Option}
        MenuFooter={MenuFooter}
      />
    </div>
  );
}

export default MultiSelectFilter;
