import styles from './OrderListItem.module.scss';
import { formatSumWithCurrency } from '@utils/formatValues';
import { Text, TextColor, TextSize, TextWeight } from '@components/Text';
import { TOrderListItemProps } from '../../types';
import { useProfile } from '@contexts/ProfileContext';

const OrderListItem: React.FC<TOrderListItemProps> = (props: TOrderListItemProps): React.ReactElement => {
  const { item } = props;

  const { account } = useProfile();

  const variableCurrency: string = account?.region === 'TR' ? '$' : '¥';

  return (
      <li className={styles.listItem}>
        <span className={styles.listItemCol}>
          <Text
              color={TextColor.PRIMARY}
              size={TextSize.S}
              weight={TextWeight.BOLD}
          >
            {item.directStreamExternalId}
          </Text>
          <Text
              color={TextColor.DARK_MUTED}
              size={TextSize.XS}
          >
            {item.trackingNumber}
          </Text>
        </span>
        <span className={styles.listItemCol}>
          <a
              className={styles.link}
              href={item.url}
              rel="noreferrer"
              target="_blank"
          >
            {item.title}
          </a>
        </span>
        <span className={styles.listItemCol}>
          <Text
              color={TextColor.PRIMARY}
              size={TextSize.S}
          >
            {formatSumWithCurrency(item.priceRub, '₽')}
          </Text>
          <Text
              color={TextColor.DARK_MUTED}
              size={TextSize.XS}
          >
            {formatSumWithCurrency(item.priceCny, variableCurrency)}
          </Text>
        </span>
      </li>
  );
};

export default OrderListItem;