import { createContext, useContext } from "react";
import { AccountResponse } from '@api/Responses/AccountResponse';

interface ProfileContextType {
  account?: AccountResponse;
  get: () => void;
  isFetched: boolean;
  isFetching: boolean;
}

const ProfileContext = createContext<ProfileContextType | null>(null);

export const useProfile = (): ProfileContextType => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error("useProfile must be used within an ProfileProvider");
  }
  return context;
};

export default ProfileContext;
export { default as ProfileProvider } from "./ProfileProvider.tsx";
