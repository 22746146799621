import { useState, useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { getCurrentLanguage, Langs } from "@utils/lang";
import { useQuery } from "react-query";
import cn from "classnames";
import { formatDate, weightKg, weightGValue } from "@utils/formatValues";
import { Tooltip } from '@components/Tooltip';
import Avatar from "@components/Avatar";
import { TableColumn, TableColumnType } from "@components/Table/TableTypes.ts";
import { Text, TextColor, TextSize, TextType, TextWeight } from "@components/Text";
import { getPickUpParcels, getPickUpInfo, PickUpParcel } from "@api/apiClient/pickUp";
import { AddSellerPanel } from "@components/AddSeller";
import { Row, Column } from "@components/Grid";
import Box from "@components/Box";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import ParcelModalWindow from "@containers/ParcelModalWindow";
import { Modal } from "@components/Modal";
import TableSelectionPanel from "@components/Table/TableSelectionPanel";
import DataTable, { DataViewHandle } from '@containers/DataTable';
import { Filters, FilterTypes } from "@containers/Filters";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import PickUpFormModalWindow from '../modals/PickUpFormModalWindow';
import ConditionsModal from '../modals/ConditionsModal';
import BeforeReadyTabTable from '../components/BeforeReadyTabTable/BeforeReadyTabTable';
import DocumentLink from "@components/DocumentLink";
import infoIcon from '@assets/icons/info_blue_19.svg';
import WarningIcon from "@assets/icons/warning.svg";
import styles from "./readyTab.module.css";

interface LinkData {
  fileSize: string;
  url: string;
}

const RestrictionMessage = ({ data }: {
  data: {
    minWeight: number,
    minCount: number,
    weight: number,
    count: number,
    groupName: string,
  },
}) => {
  const { t } = useTranslation();
  let template = "orderBtnMessageWithWeight";
  if (!data.minWeight && !data.minCount) return null;
  if (!data.minWeight) template = "orderBtnMessage";
  if (!data.minCount) template = "orderBtnMessageOnlyWeight";

  return (
    <Text size={TextSize.M} color={TextColor.ERROR_DARK}>
      {t(
        `pickUp.tabReady.${template}`,
        {
          serviceName: data.groupName,
          weight: (data.minWeight - data.weight) / 1000,
          count: data.minCount - data.count,
        }
      )}
    </Text>
  )
}

const urlPrefix = "https://storage.yandexcloud.net/unitrade-public/public/";
const instruction: Record<Langs, LinkData | null> = {
  [Langs.cn]: {
    fileSize: "1,08 MB",
    url: `${urlPrefix}pickup-instr-10.pdf`,
  },
  [Langs.en]: null,
  [Langs.ru]: null,
  [Langs.tr]: null,
}

const ReadyTab = ({
  isFilterOpen,
  setIsFilterOpen,
  isNew,
  onUpdate,
  sellersNotFound,
}: {
  isFilterOpen: boolean,
  setIsFilterOpen: (v: boolean) => void,
  isNew: boolean,
  sellersNotFound: boolean,
  onUpdate: () => void,
}) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [selectedParcels, setSelectedParcels] = useState<Map<string, PickUpParcel>>(new Map());
  const [innerFilters, setInnerFilters] = useState<FilterModel[]>([]);
  const [detailId, setDetailId] = useState("");
  const [isOpenParcelWindow, setIsOpenParcelWindow] = useState(false);
  const [isOpenPickUpFormWindow, setIsOpenPickUpFormWindow] = useState<boolean>(false);
  const [isOpenPickUpAllWindow, setIsOpenPickUpAllWindow] = useState<boolean>(false);
  const [isConditionsOpen, setConditionsOpen] = useState(false);
  const dataRef = useRef<DataViewHandle>(null);

  const closeConditions = () => setConditionsOpen(false);

  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  const pickUpInfo = useQuery({
    queryKey: ["pickUp"],
    queryFn: () => getPickUpInfo(),
    retry: 1,
    staleTime: Infinity,
  });

  const closeParcelWindow = () => {
    setIsOpenParcelWindow(false);
  }

  const handleParcelClick = (parcelId: string) => {
    setIsOpenParcelWindow(true);
    setDetailId(parcelId);
  }

  const columns: TableColumn<PickUpParcel>[] = useMemo(() => [
    {
      header: t("pickUp.tabReady.trackNumber"),
      accessor: (row) => (
        <span
          className={styles.trackNumber}
          onClick={() => handleParcelClick(row.data.id)}
        >
          {row.data.trackNumber}
        </span>
      ),
      type: TableColumnType.PRIMARY,
      alignment: "left",
      width: "150px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("pickUp.tabReady.externalNumber"),
      accessor: (row) => (row.data.externalNumber ?? ""),
      type: TableColumnType.TERTIARY,
      alignment: "left",
      width: "150px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("pickUp.tabReady.service"),
      accessor: (row) => row.data.serviceName,
      type: TableColumnType.SECONDARY,
      alignment: "left",
      width: "auto",
    },
    {
      header: t("pickUp.tabReady.store"),
      accessor: (row) => (
        <Row noWrap align="center">
          <Avatar
            className={styles.avatar}
            name={row.data.sellerName}
            id={row.data.sellerId}
          />
          <span className={styles.sellerText}>
            {row.data.sellerName}
          </span>
        </Row>
      ),
      type: TableColumnType.TERTIARY,
      alignment: "left",
      width: "290px",
    },
    {
      header: t("pickUp.tabReady.weight"),
      accessor: (row) => weightKg(row.data.weight),
      type: TableColumnType.SECONDARY,
      alignment: "left",
      fixWidth: true,
      width: "70px",
    },
    {
      header: t("pickUp.tabReady.date"),
      accessor: (row) => formatDate(row.data.createdAt, "dd.MM.yy HH:mm"),
      type: TableColumnType.TERTIARY,
      alignment: "left",
      fixWidth: true,
      width: "110px",
    },
  ], [currentLanguage]);

  const handleSelectionChange = useCallback((
    selectedIds: string[],
    items: PickUpParcel[],
  ) => {
    setSelectedRowIds(selectedIds);

    const parcels: Map<string, PickUpParcel> = new Map();
    selectedParcels.forEach((parcel, key) => {
      const item = items.find((v) => v.id === key);
      if (!item) parcels.set(key, parcel) // selected items from other pages
    });

    selectedIds.forEach((id) => {
      const item = items.find((v) => (v.id === id));
      if (item) {
        parcels.set(id, item);
      } else {
        // selected items from other pages
        const selectedItem = selectedParcels.get(id);
        if (!selectedItem) {
          console.error(`product with id ${id} not found`);
        } else {
          parcels.set(id, selectedItem);
        }
      }
    })
    setSelectedParcels(parcels);
  }, [selectedParcels]);

  const refreshData = () => {
    if (dataRef.current?.refresh) {
      dataRef.current.refresh();
    }
    onUpdate();
    setSelectedRowIds([]);
    setSelectedParcels(new Map());
  }

  const onCloseModal = () => {
    refreshData();
    setIsOpenPickUpFormWindow(false);
    setIsOpenPickUpAllWindow(false);
  }

  const { defaultMinCount, defaultMinWeightInGrams } = pickUpInfo?.data?.serviceConstraintsInfo || {};
  const constraints = (pickUpInfo?.data?.serviceConstraintsInfo?.constraints || []);

  const totalByService = Object.values(Array.from(selectedParcels, ([_, value]) => value)
  .reduce((acc, v) => {
    acc[v.serviceId] = {
      weight: (acc[v.serviceId]?.weight ?? 0) + weightGValue(v.weight),
      count: (acc[v.serviceId]?.count ?? 0) + 1,
      serviceId: v.serviceId,
      serviceName: v.serviceName,
    };
    return acc;
  }, {} as Record<string, { weight: number, count: number, serviceId: string, serviceName: string }>))

  let totalByGroup = Object.values(totalByService.reduce((acc, v) => {
    const groupConstraints = constraints.find((c) => (c.services.find((s) => s.id === v.serviceId)));
    const groupId = groupConstraints?.serviceGroupId ?? "";

    acc[groupId] = {
      weight: (acc[groupId]?.weight ?? 0) + v.weight,
      count: (acc[groupId]?.count ?? 0) + v.count,
      groupId,
      groupName: groupConstraints?.serviceGroupName ?? "",
      useTotal: !groupConstraints,
      isAllowed: groupConstraints?.isAllowed ?? true,
      minCount: (groupConstraints ? groupConstraints.minCount : defaultMinCount) || 0,
      minWeight: (groupConstraints ? groupConstraints.minWeightInGrams : defaultMinWeightInGrams) || 0,
    };
    return acc;
  }, {} as Record<string, {
    weight: number,
    count: number,
    groupId: string,
    groupName: string,
    useTotal: boolean,
    isAllowed: boolean,
    minCount: number,
    minWeight: number,
  }>));

  const defaultServicesTotal = totalByGroup.reduce((acc, item) => ({
    weight: item.useTotal ? acc.weight + item.weight : acc.weight,
    count: item.useTotal ? acc.count + item.count : acc.count,
  }), { weight: 0, count: 0 });

  totalByGroup = totalByGroup.map((item) => ({
    ...item,
    weight: item.useTotal ? defaultServicesTotal.weight : item.weight,
    count: item.useTotal ? defaultServicesTotal.count : item.count,
  }));

  const belowLimit = totalByGroup.filter(v => !(
    (v.minCount && (v.count >= v.minCount))
    || (v.minWeight && (v.weight >= v.minWeight))
    || (!v.minWeight && !v.minCount)
  ));
  const notAllowedGroups = totalByGroup.filter(v => !v.isAllowed);

  const pickUpAvailable = !notAllowedGroups.length && (belowLimit.length < totalByGroup.length);

  const BeforeReadyTabTableComponent = useCallback(() => {
    const foundParcels: PickUpParcel[] = dataRef.current?.data;
    const trackNumberFilter = innerFilters.find((filter: FilterModel) => filter.name === "trackOrExternalNumbers");
    let warning = null;
    if (trackNumberFilter && trackNumberFilter.value.length && foundParcels) {
      const wantedParcels: string[] = trackNumberFilter.value[0].split(',');
      const notFoundParcels: string[] = wantedParcels
        .filter((track: string) => (
          track && !foundParcels.some((item: PickUpParcel) => (
            item.trackNumber === track
            || item.externalNumber === track
          ))
        ));
      if (foundParcels.length && notFoundParcels.length) {
        const trackNumbers = {
          notFound: notFoundParcels,
          search: wantedParcels
        };
        warning = (<BeforeReadyTabTable trackNumbers={trackNumbers} />);
      }
    }

    return (
      <>
        {warning}
        {(foundParcels?.length && pickUpInfo?.data?.allowAllParcels) ? (
          <Box pb={5}>
            <Button
              onClick={() => setIsOpenPickUpAllWindow(true)}
              size={ButtonSize.S}
              title={t("pickUp.tabReady.orderAllBtn")}
            />
          </Box>
        ) : null}
      </>
    );
  }, [dataRef.current?.data]);

  return (
    <div>
      {!sellersNotFound && (
          <Box pb={5}>
            <Column phone={12}>
              <Row>
                <Column phone={12}>
                  <Text
                      size={TextSize.M}
                      type={TextType.BLOCK}
                  >
                    {t("pickUp.tabReady.description")}
                  </Text>
                </Column>
              </Row>
            </Column>
          </Box>
      )}
      <Box pb={5}>
        <Column phone={12}>
          <Row gap={6}>
            <DocumentLink
              icon={infoIcon}
              text={t('awaitingPage.downloadInstruction')}
              {...(instruction[currentLanguage] || instruction[Langs.cn]) as LinkData}
            />
            <Button
              type={ButtonType.LINK}
              onClick={() => setConditionsOpen(true)}
              icon={WarningIcon}
              title={(
                <Text weight={TextWeight.MEDIUM} size={TextSize.L}>
                  {t("pickUp.tabReady.conditionsLink")}
                </Text>
              )}
            />
          </Row>
        </Column>
      </Box>
      <Box pb={5} className={cn(!isFilterOpen && styles.hidden)}>
        <Column phone={12}>
          <Row justify="right" gap={5}>
            <Filters
              id="pickUp-ready"
              openFilters={() => setIsFilterOpen(true)}
              items={[
                {
                  name: "trackOrExternalNumbers",
                  type: FilterTypes.MULTI_SEARCH,
                  placeholder: t("filters.searchByTrackNumber"),
                },
                {
                  name: "serviceIds",
                  type: FilterTypes.SERVICE_PICKUP,
                },
                {
                  name: "sellerIds",
                  type: FilterTypes.SELLER,
                },
                {
                  name: "dateRange",
                  type: FilterTypes.DATE_RANGE,
                  placeholder: t("pickUp.tabReady.date"),
                  fieldNames: ["startDate", "endDate"],
                },
              ]}
              onChange={(v, isReady) => {
                if (isReady) {
                  setIsInitialized(true);
                }
                setInnerFilters(v)
              }}
              values={innerFilters}
              asPanel
            />
          </Row>
        </Column>
      </Box>
      {sellersNotFound ? (
        <AddSellerPanel onSuccess={onUpdate} isFirstSeller />
      ) : (
        <TableSelectionPanel
          isShown={selectedRowIds.length > 0}
          items={[
            {
              label: t("pickUp.tabReady.selected"),
              value: (
                <Text
                  size={TextSize.L}
                  color={TextColor.ERROR_DARK}
                  weight={TextWeight.BOLD}
                >
                  {selectedRowIds.length}
                </Text>
              ),
            },
            {
              label: t("pickUp.tabReady.parcelsTotal"),
              value: (
                <Text
                  size={TextSize.L}
                  color={TextColor.ERROR_DARK}
                  weight={TextWeight.BOLD}
                >
                  {dataRef.current?.total}
                </Text>
              ),
            },
          ]}
          buttons={[
            ...((!pickUpAvailable && belowLimit.length >= 3) ? [(
              <Tooltip
                action={
                  <div className={styles.orderBtnMessage}>
                    <Text size={TextSize.M} color={TextColor.ERROR_DARK}>
                      {t("pickUp.tabReady.orderBtnMessageTitle")}
                    </Text>
                    <div>
                      {belowLimit.slice(0, 3).map((item, index) => (
                        <>
                          {index ? (
                            <Text size={TextSize.M} color={TextColor.ERROR_DARK}>
                              &nbsp;/&nbsp;
                            </Text>
                          ) : null}
                          <RestrictionMessage data={item} key={item.groupId} />
                        </>
                      ))}
                      <Text size={TextSize.M} color={TextColor.ERROR_DARK}>
                        ...
                      </Text>
                    </div>
                  </div>
                }
                text={(
                  <div className={styles.orderBtnMessage}>
                    <Text size={TextSize.M} color={TextColor.ERROR_DARK}>
                      {t("pickUp.tabReady.orderBtnMessageTitle")}
                    </Text>
                    {belowLimit.map((item) => (
                      <RestrictionMessage data={item} key={item.groupId} />
                    ))}
                  </div>
                )}
                bottom={80}
                right={-20}
                left={-20}
              />
            )] : []),
            ...((!pickUpAvailable && belowLimit.length <= 2) ? [(
              <div className={styles.orderBtnMessage}>
                <Text size={TextSize.M} color={TextColor.ERROR_DARK}>
                  {t("pickUp.tabReady.orderBtnMessageTitle")}
                </Text>
                <div>
                  {belowLimit.map((item, index) => (
                    <>
                      {index ? (
                        <Text size={TextSize.M} color={TextColor.ERROR_DARK}>
                          &nbsp;/&nbsp;
                        </Text>
                      ) : null}
                      <RestrictionMessage data={item} key={item.groupId} />
                    </>
                  ))}
                </div>
              </div>
            )] : []),
            ...(pickUpAvailable ? [(
              <div className={styles.orderBtnMessage}>
                <Text size={TextSize.M} color={TextColor.SUCCESS_DARK}>
                  {t("pickUp.tabReady.pickUpAvailable")}
                </Text>
              </div>
            )] : []),
            (<Button
              key="order"
              onClick={() => setIsOpenPickUpFormWindow(true)}
              size={ButtonSize.S}
              title={t("pickUp.tabReady.orderBtn")}
              disabled={!pickUpAvailable}
            />),
          ]}
        >
          <DataTable<PickUpParcel>
            Before={BeforeReadyTabTableComponent}
            queryKey="pickUpParcels"
            pending={!isInitialized || !pickUpInfo.isFetched}
            getData={getPickUpParcels}
            columns={columns}
            selectable
            selectedIds={selectedRowIds}
            onSelectionChange={handleSelectionChange}
            notFound={t("pickUp.tabReady.noParcels")}
            filters={innerFilters}
            defaultPageSize={20}
            dataRef={dataRef}
          />
        </TableSelectionPanel>
      )}

      <Modal
        isOpen={isOpenParcelWindow}
        onClose={closeParcelWindow}
        onClickOverlay={closeParcelWindow}
        width={900}
        bodyWithoutIndentations
      >
        <ParcelModalWindow
          parcelId={detailId}
        />
      </Modal>

      <Modal
        isOpen={isOpenPickUpFormWindow || isOpenPickUpAllWindow}
        onClickOverlay={onCloseModal}
        onClose={onCloseModal}
      >
        <PickUpFormModalWindow
          onClose={onCloseModal}
          handlerRefreshParcels={() => {}}
          isNew={isNew}
          parcelIds={isOpenPickUpFormWindow ? selectedRowIds : []}
        />
      </Modal>

      <Modal
        isOpen={isConditionsOpen}
        onClose={closeConditions}
        onClickOverlay={closeConditions}
        width={980}
      >
        <ConditionsModal />
      </Modal>
    </div>
  );
};

export default ReadyTab;
