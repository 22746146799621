import styles from './AutoResaleModalWindow.module.scss';
import Box from '@components/Box';
import cn from 'classnames';
import FormErrorMessage from '@components/FormErrorMessage';
import InfoIcon from '@assets/icons/info-grey-18.svg';
import parse from 'html-react-parser';
import Switcher from '@components/Switcher';
import TextInputContainer from '@containers/TextInputContainer';
import { Button, ButtonType } from '@components/Button';
import { Column, Grid, Row } from '@components/Grid';
import { sendAutoResellData } from '@api/apiClient';
import { IAutoResaleModalWindowProps, TAutoResellForm } from '../../types';
import { Text, TextSize, TextType, TextWeight } from '@components/Text';
import { Tooltip } from '@components/Tooltip';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

const AutoResaleModalWindow: React.FC<IAutoResaleModalWindowProps> = (props: IAutoResaleModalWindowProps): React.ReactElement => {
  const { autoResellData, handlerSetIsOpenAutoResaleWindow, handlerRefecthAutoResellData } = props;

  const [allowMultipleInPackage, setAllowMultipleInPackage] = useState<boolean>(false);
  const [priceLimitEnabled, setPriceLimitEnabled] = useState<boolean>(false);

  const { t } = useTranslation();

  const { error, isError, isLoading, mutate } = useMutation({
    mutationFn: sendAutoResellData,
    onSuccess() {
      handlerRefecthAutoResellData();
      handlerSetIsOpenAutoResaleWindow(false);
    }
  });

  const {
    clearErrors,
    control,
    getValues,
    handleSubmit,
    setError,
    setValue,
    watch
  } = useForm<TAutoResellForm>({
    defaultValues: {
      amountRU: ''
    },
    mode: 'onChange'
  });

  const submit = (form: TAutoResellForm): void => {
    mutate({
      allowMultipleInPackage: allowMultipleInPackage,
      enabled: priceLimitEnabled,
      priceLimit: +form.amountRU
    });
  };

  useEffect(() => {
    const {
      allowMultipleInPackage,
      enabled,
      priceLimit
    } = autoResellData;
    setValue('amountRU', priceLimit.toString());
    setAllowMultipleInPackage(allowMultipleInPackage);
    setPriceLimitEnabled(enabled);
  }, [autoResellData]);

  useEffect(() => {
    const amountRU = watch('amountRU');
    setValue('amountRU', amountRU.replace(/^0+/, '').replace(/[^0-9]+/g, ''));
  }, [watch('amountRU')]);

  const handlerSendAutoResellData = () => {
    if (priceLimitEnabled) {
      if (getValues('amountRU').length) {
        handleSubmit(submit)();
      } else {
        setError('amountRU', {
          type: 'manual',
          message: t('defaultFormErrors.required')
        });
      }
    } else {
      handleSubmit(submit)();
    }
  };

  return (
      <Grid>
        <Row>
          <Column phone={12}>
            <Box mb={5}>
              <Text type={TextType.H1}>
                {t('autoResaleModalWindow.title')}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box mb={5}>
              <Text size={TextSize.S}>
                {t('autoResaleModalWindow.description')}
              </Text>
            </Box>
          </Column>
        </Row>
        <div className={cn(styles.row, styles.rowOne)}>
          <Switcher
              isActive={priceLimitEnabled}
              onSwitch={() => {
                if (priceLimitEnabled) {
                  clearErrors('amountRU');
                  setAllowMultipleInPackage(false);
                  setPriceLimitEnabled(false);
                } else {
                  setPriceLimitEnabled(true);
                }
              }}
              text={t('autoResaleModalWindow.check1')}
          />
          <div className={styles.amount}>
            <div className={styles.TextInputContainer}>
              <TextInputContainer
                  control={control}
                  disabled={!priceLimitEnabled}
                  name="amountRU"
                  min={0}
                  title={t('autoResaleModalWindow.input')}
              />
            </div>
          </div>
          <Tooltip
              action={
                <img
                    className={styles.icon}
                    alt="Info"
                    src={InfoIcon}
                />
              }
              left={35}
              text={parse(t('autoResaleModalWindow.tooltipText1'))}
              top={-30}
          />
        </div>
        <div className={cn(styles.row, styles.rowTwo)}>
          <Switcher
              isActive={allowMultipleInPackage}
              onSwitch={() => priceLimitEnabled && setAllowMultipleInPackage(!allowMultipleInPackage)}
              text={t('autoResaleModalWindow.check2')}
          />
          <Tooltip
              action={
                <img
                    className={styles.icon}
                    alt="Info"
                    src={InfoIcon}
                />
              }
              left={35}
              text={parse(t('autoResaleModalWindow.tooltipText2'))}
              top={-15}
          />
        </div>
        <Row>
          <Column phone={12}>
            <Box>
              <div className={styles.info}>
                <Text
                    size={TextSize.S}
                    weight={TextWeight.BOLD}
                >
                  {t('autoResaleModalWindow.infoTitle')}
                </Text>
                <Text size={TextSize.S}>
                  {parse(t('autoResaleModalWindow.infoText'))}
                </Text>
              </div>
            </Box>
          </Column>
        </Row>
        <div className={styles.actions}>
          <div>
            <Button
                disabled={isLoading}
                onClick={() => handlerSetIsOpenAutoResaleWindow(false)}
                title={t('autoResaleModalWindow.cancel')}
                type={ButtonType.SKELETON}
            />
          </div>
          <div>
            <Button
                onClick={handlerSendAutoResellData}
                pending={isLoading}
                title={t('autoResaleModalWindow.save')}
                type={ButtonType.BLOCK}
            />
          </div>
        </div>
        {isError && <FormErrorMessage error={error}/>}
      </Grid>
  );
};

export default AutoResaleModalWindow;
