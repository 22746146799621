import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import Box from '@components/Box';
import { Column, Grid, Row } from '@components/Grid';
import { getPickUpInfo } from "@api/apiClient/pickUp";
import { Loader } from '@components/Loader';
import { Text, TextSize, TextType, TextWeight, TextColor } from '@components/Text';
import styles from "./ConditionsModal.module.scss";

interface Service {
  id: string;
  name: string;
}

const ConditionsModal: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();

  const pickUpInfo = useQuery({
    queryKey: ["pickUp"],
    queryFn: () => getPickUpInfo(),
    retry: 1,
    select: (data) => ({
      allowedGroups: data.serviceConstraintsInfo.constraints.filter(v => v.isAllowed),
      forbiddenGroups: data.serviceConstraintsInfo.constraints
        .filter((v) => !v.isAllowed)
        .reduce((acc, group) => ([...acc, ...group.services]), [] as Service[]),
    }),
    staleTime: Infinity,
  });

  if (pickUpInfo.isFetching) {
    return (
      <Grid>
        <Row>
          <Column phone={12}>
            <Box>
              <Loader size={'s'}/>
            </Box>
          </Column>
        </Row>
      </Grid>
    );
  }

  return (
      <Grid>
        <Row>
          <Column phone={12}>
            <Box mb={5} textAlign="center" flex="row">
              <Text type={TextType.H1}>
                {t('pickUp.conditions.title')}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box mb={7} textAlign="center">
              <Text size={TextSize.L}>
                {t('pickUp.conditions.description')}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row>
          {(pickUpInfo.data?.allowedGroups || []).map((item => (
            <Column phone={4}>
              <Box p={3} flex="column">
                <Box pb={2}>
                  <Text size={TextSize.M} weight={TextWeight.BOLD}>
                    {`${t('pickUp.conditions.group')} ${item.serviceGroupName}:`}
                  </Text>
                </Box>
                <Box pb={2}>
                  {(item.minCount && !item.minWeightInGrams) ? (
                    <span>
                      <Text size={TextSize.M}>
                        {t('pickUp.conditions.minCount')}
                      </Text>
                      {" "}
                      <Text size={TextSize.M} weight={TextWeight.BOLD}>
                        {item.minCount}
                      </Text>
                    </span>
                  ) : (
                    <span>
                      <Text size={TextSize.M}>
                        {t('pickUp.conditions.condition')}
                      </Text>
                      {" "}
                      <Text size={TextSize.M} weight={TextWeight.BOLD}>
                        {t(
                          `pickUp.conditions.${item.minCount ? "conditionValue" : "conditionValueWeightOnly"}`,
                          { count: item.minCount, weight: item.minWeightInGrams / 1000 },
                        )}
                      </Text>
                    </span>
                  )}
                </Box>
                <ul className={styles.list}>
                  {item.services.map((s) => (
                    <li>
                      <Text size={TextSize.M} key={s.id}>
                        {s.name}
                      </Text>
                    </li>
                  ))}
                </ul>
              </Box>
            </Column>
          )))}
        </Row>
        <Row>
          <Column phone={12}>
            <Box pt={5}>
              <Text size={TextSize.L} weight={TextWeight.BOLD}>
                {t('pickUp.conditions.forniddenGroups')}
              </Text>
            </Box>
            <Box pt={2}>
              <Text size={TextSize.L}>
                {t('pickUp.conditions.forbiddenGroupsDescription')}
              </Text>
            </Box>
            <Box flex="column">
              {(pickUpInfo.data?.forbiddenGroups || []).map((s: Service) => (
                <Box pt={2}>
                  <Text size={TextSize.L} key={s.id}>
                    {s.name}
                  </Text>
                </Box>
              ))}
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box mt={7} pl={12} pr={12} textAlign="center" flex="row">
              <Text size={TextSize.L}>
                <Text size={TextSize.L} color={TextColor.ERROR} weight={TextWeight.BOLD}>!</Text>
                {t('pickUp.conditions.footer')}
              </Text>
            </Box>
          </Column>
        </Row>
      </Grid>
  );
};

export default ConditionsModal;
