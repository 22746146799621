import * as client from '@api/keycloakClient';
import Box from '@components/Box';
import FormErrorMessage from '@components/FormErrorMessage';
import infoIcon from '@assets/icons/info_blue_19.svg';
import TextInputContainer from '@containers/TextInputContainer';
import { Button, ButtonSize, ButtonType } from '@components/Button';
import { clearToken, getStoredToken, isRefreshTokenValid, isTokenValid, storeToken } from '@utils/tokenUtils';
import { Column, Row } from '@components/Grid';
import { getCurrentLanguage, Langs } from '@utils/lang';
import { IApiKeyProps, TOzonAddApiKeyForm } from '../types';
import { ozonAddApiKey } from '@api/apiClient/signUp';
import { Text, TextColor, TextSize, TextType, TextWeight } from '@components/Text';
import { updateToken } from '@api/apiClient/sellerApiClient';
import { useAuth } from '@contexts/AuthenticationContext';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSellersCounter } from '@contexts/SellersCounterContext';

const links = {
  [Langs.cn]: 'https://docs.ozon.ru/api/seller/zh/#section/API',
  [Langs.ru]: 'https://docs.ozon.ru/api/seller/#section/Kak-poluchit-API-klyuch',
  [Langs.en]: '',
  [Langs.tr]: ''
};

const OzonAddApiKey: React.FC<IApiKeyProps> = (props: IApiKeyProps) => {
  const { email, refetch } = props;

  const auth = useAuth();
  const currentLanguage = getCurrentLanguage();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { update } = useSellersCounter();

  const checkToken = async () => {
    const token = getStoredToken();
    if (token && isTokenValid(token) && isRefreshTokenValid(token)) {
      const tokenResponse = await client.refreshToken(token.refresh_token);
      const now = new Date().getTime();
      const tokenData = {
        ...tokenResponse,
        received_at: now
      };
      storeToken(tokenData);
      updateToken(tokenData?.access_token);
    } else {
      clearToken();
      navigate('/login');
    }
  };

  useEffect(() => {
    checkToken();
    const timer = setInterval(checkToken, 60 * 1000);
    setValue('email', email);
    return () => clearInterval(timer);
  }, []);

  const { error, isLoading, mutate, isError } = useMutation({
    mutationFn: ozonAddApiKey,
    onSuccess() {
      auth.setIsAuthenticated_(true);
      refetch && refetch();
      update();
    }
  });

  const { control, handleSubmit, setValue } = useForm<TOzonAddApiKeyForm>({
    defaultValues: {
      email: '',
      ozonApiKey: ''
    }
  });

  const submit = (form: TOzonAddApiKeyForm) => {
    mutate({ ozonApiKey: form.ozonApiKey });
  };

  return (
      <>
        <Row>
          <Column phone={12}>
            <Box pb={4}>
              <Text type={TextType.H1}>
                {t('ozonSignUpPage.apiKey.title')}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box pb={5}>
              <Text size={TextSize.M}>
                {t('ozonSignUpPage.apiKey.description')}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box pb={5}>
              <TextInputContainer
                  control={control}
                  disabled
                  name="email"
                  rules={{ required: t('defaultFormErrors.emailFormat') }}
                  title={t('ozonSignUpPage.apiKey.storeEmail')}
                  type="email"
              />
            </Box>
          </Column>
        </Row>
        {links[currentLanguage] && (
            <Row>
              <Column phone={12}>
                <Box pb={5}>
                  <a
                      style={{ display: 'flex', alignItems: 'center' }}
                      href={links[currentLanguage]}
                      rel="noreferrer"
                      target="_blank"
                  >
                    <img
                        style={{ marginRight: '8px' }}
                        alt="Icon"
                        src={infoIcon}
                    />
                    <Text
                        color={TextColor.BLUE}
                        size={TextSize.L}
                        type={TextType.SPAN}
                        weight={TextWeight.MEDIUM}
                    >
                      {t('ozonSignUpPage.apiKey.infoLink')}
                    </Text>
                  </a>
                </Box>
              </Column>
            </Row>
        )}
        <Row>
          <Column phone={12}>
            <Box pb={7}>
              <TextInputContainer
                  control={control}
                  name="ozonApiKey"
                  rules={{ required: t('defaultFormErrors.required') }}
                  title={t('ozonSignUpPage.apiKey.enterApiKey')}
              />
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box>
              <Button
                  onClick={handleSubmit(submit)}
                  pending={isLoading}
                  size={ButtonSize.S}
                  title={t('ozonSignUpPage.apiKey.addBtn')}
                  type={ButtonType.BLOCK}
              />
            </Box>
          </Column>
        </Row>
        {isError && <FormErrorMessage error={error}/>}
      </>
  );
};

export default OzonAddApiKey;