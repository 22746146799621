import { useTranslation } from "react-i18next";
import { useForm, useFieldArray } from "react-hook-form";
import { Modal } from "@components/Modal";
import { Text, TextSize, TextType } from "@components/Text";
import { Button, ButtonType } from "@components/Button";
import TextInputContainer from "@containers/TextInputContainer.tsx";
import TextAreaContainer from "@containers/TextAreaContainer.tsx";
import FormErrorMessage from "@components/FormErrorMessage";
import Box from "@components/Box/Box.tsx";
import { addDropShippingMatching } from "@api/apiClient/dropshipping.ts";
import { useMutation } from 'react-query';
import styles from "./DropshippingModal.module.scss";

interface FormType {
  trackNumbers: { value: string }[];
  comment: string;
}

interface DropshippingItem {
  externalNumber: string;
}

interface DropshippingModalProps {
  onClose: () => void;
  isOpen: boolean;
  linkedItems: DropshippingItem[];
}

const DropshippingModal = ({
  onClose,
  isOpen,
  linkedItems,
}: DropshippingModalProps) => {
  const isOneToMany = linkedItems.length === 1;
  const { t } = useTranslation();

  const {
    error,
    isError,
    isLoading,
    mutate,
  } = useMutation({
    mutationFn: addDropShippingMatching,
    onSuccess: () => {
      onClose();
    },
  });

  const { handleSubmit, control } = useForm<FormType>({
    mode: "onChange",
    defaultValues: {
      comment: "",
      trackNumbers: [{ value: "" }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "trackNumbers",
  });

  const submit = (form: FormType) => {
    mutate({
      externalParcelCode: form.trackNumbers.map(item => item.value),
      parcelExternalNumber: linkedItems.map(item => item.externalNumber),
      message: form.comment,
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box pb={5}>
        <Text type={TextType.H1}>
          {t(`dropshipping.${isOneToMany ? "oneToMany" : "manyToOne"}.title`)}
        </Text>
      </Box>
      <Box pb={4}>
        <Text type={TextType.P} size={TextSize.M}>
          {t(`dropshipping.${isOneToMany ? "oneToMany" : "manyToOne"}.description`)}
        </Text>
      </Box>
      <Box pb={5}>
        <div className={styles.linkedItems}>
          {(linkedItems || []).map(item => (
            <div key={item.externalNumber}>{item.externalNumber}</div>
          ))}
        </div>
      </Box>
      <div className={styles.trackNumbers}>
        {fields.map((field, idx) => (
          <div key={field.id} className={styles.trackNumberWrapper}>
            <TextInputContainer
              key={field.id}
              rules={{
                required: t("defaultFormErrors.required"),
                pattern: {
                  value: /^(?!UNI)/,
                  message: t("dropshipping.trackNumberInputError"),
                }
              }}
              control={control}
              name={`trackNumbers.${idx}.value`}
              title={t("dropshipping.trackNumberInput")}
            />
            {(idx > 0) ? (<div
              className={styles.removeBtn}
              onClick={() => remove(idx)}
            />) : null}
          </div>
        ))}
      </div>
      {isOneToMany ? (
        <Box pb={5}>
          <Button
            onClick={() => append({ value: "" })}
            title={(<b>+ {t("dropshipping.oneToMany.addTrackNumber")}</b>)}
            type={ButtonType.LINK}
          />
        </Box>
      ) : null}
      <Box pb={10} pr={8}>
        <TextAreaContainer
          name="comment"
          control={control}
          title={t("dropshipping.commentInput")}
          rows={3}
        />
      </Box>
      <div className={styles.bottomPanelActions}>
        <Button
          onClick={handleSubmit(submit)}
          title={t("dropshipping.createLink")}
          pending={isLoading}
        />
      </div>
      {isError ? (
        <FormErrorMessage error={error} />
      ) : null}
    </Modal>
  );
};

export default DropshippingModal;