import { ReactElement, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import cn from "classnames";
import Box from "@components/Box";
import { Row, Column } from "@components/Grid";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import { TabsList, TabsContent } from "@components/Tabs/Tabs.tsx";
import Page from "@components/Page/Page.tsx";
import AwaitingTab from "./tabs/AwaitingTab.tsx";
import RescueTab from "./tabs/RescueTab.tsx";
import ReturnsTab from "./tabs/ReturnsTab.tsx";
import ResaleTab from "./tabs/ResaleTab.tsx";
import DisposalTab from "./tabs/DisposalTab.tsx";
import { useTranslation } from "react-i18next";
import { Filters, FilterTypes } from "@containers/Filters";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import useTab from "@hooks/useTab";
import FilterIcon from "@assets/icons/filter.svg";
import styles from "./UnclaimedPage.module.css";
import ExcelIcon from "@assets/icons/xlx-white-19.svg";
import {
  getReturnsXLSXFile,
  getReturnsXLSXFileRequest,
  useAwaitingCounter,
  useDisposalCounter,
  useHasReturns,
  useResaleCounter,
  useReturnsCounter,
  // Return,
} from '@api/apiClient';
import { TSavingParcel, useSavingsCounter } from "@api/apiClient/saving";
import moment from 'moment';
import { AxiosResponse } from 'axios';
import { Tooltip } from '../../components/Tooltip';
import { useSellersCounter } from '@contexts/SellersCounterContext';
import AutoResaleOffIcon from '@assets/icons/gear-with-sprocket-grey-21.svg';
import AutoResaleOnIcon from '@assets/icons/gear-with-sprocket-blue-21.svg';
import { useProfile } from '@contexts/ProfileContext';
import AutoResaleModalWindow from './modals/AutoResaleModalWindow/AutoResaleModalWindow';
import { Modal } from '@components/Modal';
import { getAutoResellData } from '@api/apiClient';

const UnclaimedPage = () => {
  const [filters, setFilters] = useState<FilterModel[]>([]);
  const [isAutoResaleEnabled, setIsAutoResaleEnabled] = useState<boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isOpenAutoResaleWindow, setIsOpenAutoResaleWindow] = useState<boolean>(false);
  const [savedAwaitingSelection, setSavedAwaitingSelection] = useState<string[]>([]);
  const [savedDisposalSelection, setSavedDisposalSelection] = useState<string[]>([]);
  const [savedResaleSelection, setSavedResaleSelection] = useState<string[]>([]);
  const [savedRescueSelection, setSavedRescueSelection] = useState<TSavingParcel[]>([]);
  const [savedReturnsSelection, setSavedReturnsSelection] = useState<string[]>([]);

  const queryClient = useQueryClient();
  const { account } = useProfile();
  const { sellersNotFound } = useSellersCounter();
  const { t } = useTranslation();

  const { hasNoReturns: noData } = useHasReturns(!sellersNotFound);

  const { data: awaitingCounter } = useAwaitingCounter(!sellersNotFound);
  const { data: disposalCounter } = useDisposalCounter(!sellersNotFound);
  const { data: resaleCounter } = useResaleCounter(!sellersNotFound);
  const { data: returnsCounter } = useReturnsCounter(!sellersNotFound);
  const { data: savingsCounter } = useSavingsCounter(!sellersNotFound);

  const {
    data: dataAutoResellData,
    refetch: refetchAutoResellData
  } = useQuery({
    queryKey: ['autoResellData'],
    queryFn: () => getAutoResellData(),
    retry: 1,
    enabled: account?.region === 'TR'
  });

  useEffect(() => {
    dataAutoResellData && setIsAutoResaleEnabled(dataAutoResellData.enabled);
  }, [dataAutoResellData]);

  const refreshData = () => {
    queryClient.invalidateQueries(
      { queryKey: ["returns-page"] }
    );
    queryClient.invalidateQueries(
      { queryKey: ["resales-page"] }
    );
    queryClient.invalidateQueries(
        {queryKey: ["getSavings"]}
    );
  }

  useEffect(() => {
    if (filters[0]?.value.length) {
      setSavedRescueSelection([]);
      setSavedAwaitingSelection([]);
      setSavedDisposalSelection([]);
      setSavedResaleSelection([]);
      setSavedReturnsSelection([]);
    }
  }, [filters]);

  const downloadingExcelFile = (type: string): void => {
    let parcelsIds: string[] = [];
    let viewType: string = '';

    if (activeTab === 'rescue') viewType = 'Rescue';
    if (activeTab === 'awaiting') viewType = 'Awaiting';
    if (activeTab === 'disposal') viewType = 'Disposal';
    if (activeTab === 'resale') viewType = 'Resale';
    if (activeTab === 'returns') viewType = 'Return';

    if (type === 'selectively') {
      if (activeTab === 'rescue') parcelsIds = savedRescueSelection.map(item => item.id);
      if (activeTab === 'awaiting') parcelsIds = savedAwaitingSelection;
      if (activeTab === 'disposal') parcelsIds = savedDisposalSelection;
      if (activeTab === 'resale') parcelsIds = savedResaleSelection;
      if (activeTab === 'returns') parcelsIds = savedReturnsSelection;
    }

    const data: getReturnsXLSXFileRequest = {
      FileType: 'Excel',
      LocalTimeUtcOffsetMinute: moment().utcOffset(),
      ParcelIds: parcelsIds,
      ReturnParcelViewType: viewType
    };

    getReturnsXLSXFile(data)
        .then((response: AxiosResponse) => {
          const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const fileURL = URL.createObjectURL(file);
          const element = document.createElement('a');
          element.setAttribute('href', fileURL);
          element.setAttribute('download', `${viewType} ${moment().format('DD/MM/YYYY HH:mm:ss')}`);
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        });
  };

  const downloadingTopBtn: ReactElement =
      <Button
          icon={ExcelIcon}
          onClick={() => downloadingExcelFile('all')}
          size={ButtonSize.S}
          type={ButtonType.ROUNDED}
          title={t('unclaimedPage.titleActionTop')}
      />;

  const downloadingBottomBtn: ReactElement =
      <Button
          icon={ExcelIcon}
          onClick={() => downloadingExcelFile('selectively')}
          size={ButtonSize.S}
          title={t('unclaimedPage.titleActionBottom')}
      />;

  const tabs = [
    {
      id: "rescue",
      title: t("unclaimedPage.tabRescue"),
      content: <RescueTab
          filters={filters}
          pending={!isInitialized}
          handlerSetSavedSelection={setSavedRescueSelection}
          savedRescueSelection={savedRescueSelection}
          refreshData={refreshData}
          sellersNotFound={sellersNotFound}
      />,
      count: savingsCounter
    },
    {
      id: "awaiting",
      title: t("unclaimedPage.tabAwaiting"),
      content: <AwaitingTab
          filters={filters}
          pending={!isInitialized}
          downloadingBtn={downloadingBottomBtn}
          handlerSetSavedAwaitingSelection={setSavedAwaitingSelection}
          savedAwaitingSelection={savedAwaitingSelection}
          refreshData={refreshData}
          sellersNotFound={sellersNotFound}
          noData={noData}
      />,
      count: awaitingCounter
    },
    {
      id: "returns",
      title: t("unclaimedPage.tabReturns"),
      content: <ReturnsTab
          filters={filters}
          pending={!isInitialized}
          downloadingBtn={downloadingBottomBtn}
          handlerSetSavedReturnsSelection={setSavedReturnsSelection}
          savedReturnsSelection={savedReturnsSelection}
          refreshData={refreshData}
          sellersNotFound={sellersNotFound}
          noData={noData}
      />,
      count: returnsCounter,
      disable: noData || sellersNotFound,
    },
    {
      id: "resale",
      title: t("unclaimedPage.tabResales"),
      content: <ResaleTab
          filters={filters}
          pending={!isInitialized}
          downloadingBtn={downloadingBottomBtn}
          handlerSetSavedResaleSelection={setSavedResaleSelection}
          savedResaleSelection={savedResaleSelection}
          refreshData={refreshData}
          sellersNotFound={sellersNotFound}
          noData={noData}
      />,
      count: resaleCounter,
      disable: noData || sellersNotFound,
    },
    {
      id: "disposal",
      title: t("unclaimedPage.tabDisposal"),
      content: <DisposalTab
          filters={filters}
          pending={!isInitialized}
          downloadingBtn={downloadingBottomBtn}
          handlerSetSavedDisposalSelection={setSavedDisposalSelection}
          savedDisposalSelection={savedDisposalSelection}
          refreshData={refreshData}
          sellersNotFound={sellersNotFound}
          noData={noData}
      />,
      count: disposalCounter,
      disable: noData || sellersNotFound,
    },
  ];
  const { activeTab, setActiveTab } = useTab(tabs);

  useEffect(() => {
    setFilters([]);
  }, [activeTab]);

  const actionWrapper: ReactElement =
      <Tooltip
          action={downloadingTopBtn}
          right={55}
          text={t("unclaimedPage.actionTooltip")}
          top={51}
      />

  return (
    <Page
        actions={(activeTab === 'rescue') ? undefined : actionWrapper}
        title={t('unclaimedPage.title')}
    >
      <div className={styles.tabsRow}>
        <TabsList
          tabs={tabs}
          onChange={setActiveTab}
          value={activeTab || tabs[0].id}
        />
        <div className={styles.filters}>
          {account?.region === 'TR' && activeTab === 'awaiting' && (
              <Button
                  disabled={!dataAutoResellData}
                  className={cn(styles.autoResaleBtn, isAutoResaleEnabled ? styles.autoResaleBtnOn : styles.autoResaleBtnOff)}
                  icon={isAutoResaleEnabled ? AutoResaleOnIcon : AutoResaleOffIcon}
                  onClick={() => setIsOpenAutoResaleWindow(true)}
                  size={ButtonSize.S}
                  type={ButtonType.ROUNDED}
                  title={t('unclaimedPage.autoResaleBtn')}
              />
          )}
          <Button
            className={cn(isFilterOpen ? styles.filterBtnActive : styles.filterBtnInactive)}
            icon={FilterIcon}
            onClick={() => setIsFilterOpen(!isFilterOpen)}
            size={ButtonSize.S}
            type={ButtonType.ROUNDED}
            title={t("filters.filters")}
          />
        </div>
      </div>
      <Box pb={5} className={cn(!isFilterOpen && styles.hidden)}>
        <Column phone={12}>
          <Row justify="right" gap={5}>
            <Filters
              key={activeTab}
              id={`unclaimed-${activeTab}`}
              openFilters={() => setIsFilterOpen(true)}
              items={sellersNotFound ? [] : [
                {
                  name: activeTab === "resale" ? "oldOrNewResaleNumbers" : "TrackOrExternalNumbers",
                  type: FilterTypes.MULTI_SEARCH,
                  placeholder: t("parcelsPage.trackNumberSearch"),
                },
                {
                  name: "serviceIds",
                  type: FilterTypes.SERVICE_RETURNS,
                },
                {
                  name: "sellerIds",
                  type: FilterTypes.SELLER,
                },
              ]}
              onChange={(v, isReady) => {
                if (isReady) {
                  setIsInitialized(true);
                }
                setFilters(v);
              }}
              values={filters}
              asPanel
            />
          </Row>
        </Column>
      </Box>

      <TabsContent
        tab={tabs.find((tab) => (tab.id === activeTab))}
      />
      <Modal
          isOpen={isOpenAutoResaleWindow}
          onClickOverlay={() => setIsOpenAutoResaleWindow(false)}
          onClose={() => setIsOpenAutoResaleWindow(false)}
          width={650}
      >
        <AutoResaleModalWindow
            autoResellData={dataAutoResellData!}
            handlerSetIsOpenAutoResaleWindow={setIsOpenAutoResaleWindow}
            handlerRefecthAutoResellData={refetchAutoResellData}
        />
      </Modal>
    </Page>
  );
};

export default UnclaimedPage;
