import Box from '@components/Box';
import ButtonWithTimer from '@components/ButtonWithTimer';
import DigitInput from '@components/DigitInput';
import FormErrorMessage from '@components/FormErrorMessage';
import { Button, ButtonSize, ButtonType } from '@components/Button';
import { ButtonWithTimerType } from '@components/ButtonWithTimer/ButtonWithTimer';
import { Column, Grid, Row } from '@components/Grid';
import { IConfirmProps } from '../types';
import { ozonVerifyCode } from '@api/apiClient/signUp';
import { Text, TextSize, TextType } from '@components/Text';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

const OzonConfirmPhone: React.FC<IConfirmProps> = (props: IConfirmProps) => {
  const { goBack, handlerOnSuccess, ozonKey, phone, resendForm } = props;

  const [code, setCode] = useState('');

  const { t } = useTranslation();

  const { error, isLoading, mutate, isError } = useMutation({
    mutationFn: ozonVerifyCode,
    onSuccess: handlerOnSuccess
  });

  useEffect(() => {
    if (code.trim().length === 4) mutate({ key: ozonKey, code: code });
  }, [code]);

  return (
      <Grid>
        <Row>
          <Column phone={12}>
            <Box pb={3}>
              <Text type={TextType.H1}>
                {t('ozonSignUpPage.confirm.title')}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box pb={7}>
              <span>
                <Text
                    dangerouslySetInnerHTML={{ __html: t('ozonSignUpPage.confirm.description', { phone: phone }) }}
                    size={TextSize.M}
                    type={TextType.INLINE}
                />
                {' '}
                <Button
                    onClick={goBack}
                    size={ButtonSize.S}
                    title={t('ozonSignUpPage.confirm.changeLink')}
                    type={ButtonType.LINK}
                />
              </span>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box pb={5}>
              <DigitInput
                  disabled={isLoading}
                  length={4}
                  name="code"
                  onChange={setCode}
                  value={code}
              />
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <ButtonWithTimer
                label={t('ozonSignUpPage.confirm.resend')}
                onClick={() => {
                  resendForm();
                  setCode('');
                }}
                pending={isLoading}
                timeout={30}
                type={ButtonWithTimerType.LINK}
            />
          </Column>
        </Row>
        {isError && <FormErrorMessage error={error}/>}
      </Grid>
  );
};

export default OzonConfirmPhone;