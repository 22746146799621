import styles from './CardParcelModalWindow.module.scss';
import cn from 'classnames';
import CopyTextToClipboard from '@components/CopyTextToClipboard';
import InfoIcon from '@assets/icons/info-grey-18.svg';
import { Button, ButtonType } from '@components/Button';
import { ErrorResponse } from '@api/Responses/ErrorResponse';
import { formatSumWithCurrency } from '@utils/formatValues';
import { getParcel, getParcelPhoto, TParcelResponse } from '@api/apiClient/returns';
import { ICardParcelModalWindowProps, TPhotoData, TPhotosData } from '../../types';
import { Loader } from '@components/Loader';
import { Text, TextColor, TextSize, TextWeight } from '@components/Text';
import { Tooltip } from '@components/Tooltip';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useProfile } from '@contexts/ProfileContext';
import { useTranslation } from 'react-i18next';

const CardParcelModalWindow: React.FC<ICardParcelModalWindowProps> = (props: ICardParcelModalWindowProps): React.ReactElement => {
  const {
    callFrom,
    handlerSetDisposalProcessingCalledFrom,
    handlerSetIsOpenDisposalProcessingWindow,
    handlerSetIsOpenParcelWindow,
    handlerSetIsOpenReturnProcessingWindow,
    handlerSetReturnProcessingCalledFrom,
    parcel
  } = props;

  const [checkingPhotos, setCheckingPhotos] = useState<string[]>([]);
  const [isLoadingParcelPhoto, setIsLoadingParcelPhoto] = useState<boolean>(true);
  const [photo, setPhoto] = useState<string | undefined>(undefined);
  const [photoVisible, setPhotoVisible] = useState<boolean>(false);
  const [returnPhotos, setReturnPhotos] = useState<string[]>([]);

  const { account } = useProfile();
  const { t } = useTranslation();

  const variableCurrency: string = account?.region === 'TR' ? '$' : '¥';

  const {
    data: dataGetParcel,
    isFetching: isFetchingGetParcel
  } = useQuery<TParcelResponse, ErrorResponse>({
    queryKey: ['parcel', parcel.id],
    queryFn: () => getParcel(parcel.id),
    retry: 1
  });

  const { mutateAsync: mutateAsyncGetParcelPhoto } = useMutation({
    mutationFn: getParcelPhoto
  });

  const setPhotos = (checkingPhotos: TPhotosData, returnPhotos: TPhotosData): void => {
    (async () => {
      try {
        const checkingImages = await Promise.all(
            checkingPhotos.map(async (item: TPhotoData) => {
              const blob = await mutateAsyncGetParcelPhoto(item.storageKey);
              return URL.createObjectURL(blob.data);
            })
        );
        const returnImages = await Promise.all(
            returnPhotos.map(async (item: TPhotoData) => {
              const blob = await mutateAsyncGetParcelPhoto(item.storageKey);
              return URL.createObjectURL(blob.data);
            })
        );
        setCheckingPhotos(checkingImages);
        setReturnPhotos(returnImages);
        setIsLoadingParcelPhoto(false);
      } catch (error) {
        console.error(error);
      }
    })();
  };

  useEffect(() => {
    if (dataGetParcel?.item) {
      setPhotos(dataGetParcel?.item.checkingPhotos, dataGetParcel?.item.returnPhotos);
    }
  }, [dataGetParcel]);

  useEffect(() => {
    setPhotoVisible(!!photo);
  }, [photo]);

  const tooltipText: string = `<b>${t('cardParcelModalWindow.dispose')}</b><br/><br/>${t('cardParcelModalWindow.disposeText')}<br/><br/><b>${t('cardParcelModalWindow.return')}</b><br/><br/>${t('cardParcelModalWindow.returnText')}`;

  return (
      <div className={styles.filling}>
        {isFetchingGetParcel ? (
            <>
              <div className={styles.header}/>
              <div className={styles.body}>
                <Loader size="s"/>
              </div>
            </>
        ) : (
            <>
              {photo ? (
                  <div className={styles.body}>
                    <span
                        className={styles.wrapper}
                        onClick={() => setPhoto(undefined)}
                    >
                      <img
                          className={cn(styles.picture,
                              photoVisible && styles.pictureVisible
                          )}
                          alt="Photo"
                          src={photo}
                      />
                    </span>
                  </div>
              ) : (
                  <>
                    <div className={styles.header}>
                      <div>
                        <CopyTextToClipboard
                            text={dataGetParcel!.item.directStreamExternalId || ''}
                            visibleOnHover
                        >
                          <Text
                              color={TextColor.PRIMARY}
                              size={TextSize.L}
                              weight={TextWeight.BOLD}
                          >
                            {dataGetParcel!.item.directStreamExternalId}
                          </Text>
                        </CopyTextToClipboard>
                        <CopyTextToClipboard
                            text={dataGetParcel!.item.trackingNumber || ''}
                            visibleOnHover
                        >
                          <Text
                              color={TextColor.DARK_MUTED}
                              size={TextSize.S}
                          >
                            {dataGetParcel!.item.trackingNumber}
                          </Text>
                        </CopyTextToClipboard>
                      </div>
                      {callFrom === 'awaitingTab' && (
                          <div className={styles.actions}>
                            <Tooltip
                                action={
                                  <img
                                      className={styles.icon}
                                      alt="Info"
                                      src={InfoIcon}
                                  />
                                }
                                left={9}
                                text={tooltipText}
                                top={37}
                            />
                            <div className={styles.buttons}>
                              <Button
                                  onClick={() => {
                                    handlerSetIsOpenParcelWindow(false);
                                    handlerSetIsOpenDisposalProcessingWindow!(true);
                                    handlerSetDisposalProcessingCalledFrom!('card');
                                  }}
                                  title={t('cardParcelModalWindow.dispose')}
                                  type={ButtonType.RED_SKELETON}
                              />
                              <Button
                                  onClick={() => {
                                    handlerSetIsOpenParcelWindow(false);
                                    handlerSetIsOpenReturnProcessingWindow!(true);
                                    handlerSetReturnProcessingCalledFrom!('card');
                                  }}
                                  title={t('cardParcelModalWindow.returnFor') + ` ${formatSumWithCurrency(dataGetParcel!.item.reexportPrice.amount, variableCurrency)}`}
                              />
                            </div>
                          </div>
                      )}
                    </div>
                    <div className={styles.body}>
                      <div className={styles.info}>
                        <div className={styles.col}>
                          <Text
                              color={TextColor.DARK_MUTED}
                              ellipsis
                              size={TextSize.XS}
                          >
                            {t('cardParcelModalWindow.article')}
                          </Text>
                          <Text
                              color={TextColor.PRIMARY}
                              size={TextSize.S}
                              weight={TextWeight.BOLD}
                          >
                            {dataGetParcel!.item.vendorCode}
                          </Text>
                        </div>
                        <div className={styles.col}>
                          <Text
                              color={TextColor.DARK_MUTED}
                              size={TextSize.XS}
                          >
                            {t('cardParcelModalWindow.name')}
                          </Text>
                          <a
                              href={dataGetParcel!.item.url}
                              rel="noreferrer"
                              target="_blank"
                          >
                            {dataGetParcel!.item.title}
                          </a>
                        </div>
                        <div className={styles.col}>
                          <Text
                              color={TextColor.DARK_MUTED}
                              size={TextSize.XS}
                          >
                            {t('cardParcelModalWindow.length')}
                          </Text>
                          <Text
                              color={TextColor.PRIMARY}
                              ellipsis
                              size={TextSize.S}
                          >
                            {new Intl.NumberFormat().format(dataGetParcel!.item.dimensions.length.value)} {dataGetParcel!.item.dimensions.length.unit}
                          </Text>
                        </div>
                        <div className={styles.col}>
                          <Text
                              color={TextColor.DARK_MUTED}
                              size={TextSize.XS}
                          >
                            {t('cardParcelModalWindow.width')}
                          </Text>
                          <Text
                              color={TextColor.PRIMARY}
                              ellipsis
                              size={TextSize.S}
                          >
                            {new Intl.NumberFormat().format(dataGetParcel!.item.dimensions.width.value)} {dataGetParcel!.item.dimensions.width.unit}
                          </Text>
                        </div>
                        <div className={styles.col}>
                          <Text
                              color={TextColor.DARK_MUTED}
                              size={TextSize.XS}
                          >
                            {t('cardParcelModalWindow.height')}
                          </Text>
                          <Text
                              color={TextColor.PRIMARY}
                              ellipsis
                              size={TextSize.S}
                          >
                            {new Intl.NumberFormat().format(dataGetParcel!.item.dimensions.height.value)} {dataGetParcel!.item.dimensions.height.unit}
                          </Text>
                        </div>
                        <div className={styles.col}>
                          <Text
                              color={TextColor.DARK_MUTED}
                              size={TextSize.XS}
                          >
                            {t('cardParcelModalWindow.weight')}
                          </Text>
                          <Text
                              color={TextColor.PRIMARY}
                              ellipsis
                              size={TextSize.S}
                          >
                            {new Intl.NumberFormat().format(dataGetParcel!.item.weight.value)} {dataGetParcel!.item.weight.unit}
                          </Text>
                        </div>
                        <div className={styles.col}>
                          <Text
                              color={TextColor.DARK_MUTED}
                              size={TextSize.XS}
                          >
                            {t('cardParcelModalWindow.price')}
                          </Text>
                          <Text
                              color={TextColor.PRIMARY}
                              ellipsis
                              size={TextSize.S}
                          >
                            {formatSumWithCurrency(dataGetParcel!.item.priceCny, variableCurrency)}
                          </Text>
                        </div>
                      </div>
                      {callFrom !== 'processingTab' && (
                          <div className={styles.block}>
                            <Text
                                color={TextColor.PRIMARY}
                                size={TextSize.S}
                                weight={TextWeight.BOLD}
                            >
                              {t('cardParcelModalWindow.conditionGoods')}
                            </Text>
                            <div className={styles.status}>
                              <span className={styles.type}>
                                <span
                                    className={cn(styles.mark,
                                        dataGetParcel!.item.productCondition === 'Bad' && styles.red,
                                        dataGetParcel!.item.productCondition === 'Good' && styles.green
                                    )}
                                />
                                <Text
                                    color={TextColor.PRIMARY}
                                    size={TextSize.S}
                                    weight={TextWeight.MEDIUM}
                                >
                                  {dataGetParcel!.item.productCondition === 'Bad' && `${t(`cardParcelModalWindow.states.${dataGetParcel!.item.productCondition}`)} (${t(`cardParcelModalWindow.states.defectReason_${dataGetParcel!.item.defectReason}`)})`}
                                  {dataGetParcel!.item.productCondition === 'Good' && t(`cardParcelModalWindow.states.${dataGetParcel!.item.productExtendedCondition}`)}
                                </Text>
                              </span>
                              <Text
                                  color={TextColor.PRIMARY}
                                  size={TextSize.S}
                              >
                                {dataGetParcel!.item.returnReason}
                              </Text>
                            </div>
                          </div>
                      )}
                      {isLoadingParcelPhoto ? (
                          <Loader size="s"/>
                      ) : (
                          <>
                            {returnPhotos.length > 0 && (
                                <div className={styles.block}>
                                  <Text
                                      color={TextColor.PRIMARY}
                                      size={TextSize.S}
                                      weight={TextWeight.BOLD}
                                  >
                                    {t('cardParcelModalWindow.photoBuyer')}
                                  </Text>
                                  <div className={styles.photos}>
                                    {returnPhotos.map((url: string, index: number) => (
                                        <span
                                            className={styles.photo}
                                            key={index}
                                            onClick={() => setPhoto(url)}
                                        >
                                          <img
                                              className={styles.image}
                                              alt="Photo"
                                              src={url}
                                          />
                                        </span>
                                    ))}
                                  </div>
                                </div>
                            )}
                            {callFrom !== 'processingTab' && checkingPhotos.length > 0 && (
                                <div className={styles.block}>
                                  <Text
                                      color={TextColor.PRIMARY}
                                      size={TextSize.S}
                                      weight={TextWeight.BOLD}
                                  >
                                    {t('cardParcelModalWindow.photoVerification')}
                                  </Text>
                                  <div className={styles.photos}>
                                    {checkingPhotos.map((url: string, index: number) => (
                                        <span
                                            className={styles.photo}
                                            key={index}
                                            onClick={() => setPhoto(url)}
                                        >
                                          <img
                                              className={styles.image}
                                              alt="Photo"
                                              src={url}
                                          />
                                        </span>
                                    ))}
                                  </div>
                                </div>
                            )}
                          </>
                      )}
                    </div>
                  </>
              )}
            </>
        )}
      </div>
  );
};

export default CardParcelModalWindow;