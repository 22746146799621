import { useMemo } from 'react';
import { useTranslation } from "react-i18next";
import {
  TSavingParcel,
  TSavingParcelItem,
  getSavings,
} from "@api/apiClient/saving";
import DataTable from "@containers/DataTable";
import TableSelectionPanel from "@components/Table/TableSelectionPanel";
import {
  TableColumn,
  TableColumnType,
  TableRowType,
} from "@components/Table/TableTypes";
import ProgressBar from "@components/ProgressBar";
import { getProgressTo } from "@utils/progress";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import { Text, TextColor, TextSize, TextWeight, TextType } from "@components/Text";
import CopyTextToClipboard from "@components/CopyTextToClipboard";
import Avatar from "@components/Avatar";
import { getCurrentLanguage } from "@utils/lang";
import { formatSumFromObject } from "@utils/formatValues";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import { useProfile } from "@contexts/ProfileContext";
import styles from "./RescueTable.module.css";

interface AwaitingTableProps {
  selectedSum: number;
  onSelectionChange: (ids: string[], data: TSavingParcel[]) => void;
  onClickSave: (data: TSavingParcel[]) => void;
  onParcelClick: (parcelId: string) => void;
  pending?: boolean,
  filters: FilterModel[],
  selectedRowsData: TSavingParcel[],
}

const RescueTable = ({
  onParcelClick,
  onClickSave,
  onSelectionChange,
  selectedRowsData,
  selectedSum,
  pending,
  filters,
}: AwaitingTableProps) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const currentLanguage = getCurrentLanguage();

  const savedRescueSelectionIds = useMemo(
    () => selectedRowsData.map(item => item.id),
    [selectedRowsData]
  );

  const columns: TableColumn<TSavingParcel>[] = useMemo(() => [
    {
      header: t("rescueTable.parcel_number"),
      accessor: (row: TableRowType<TSavingParcel>) => (
        <>
          <CopyTextToClipboard
            text={row.data?.trackNumber}
            visibleOnHover
          >
            <span
              onClick={(e) => {
                e.stopPropagation();
                onParcelClick(row.data?.id);
              }}
            >
              <Text
                color={TextColor.PRIMARY}
                onHoverColor={TextColor.BLUE}
                size={TextSize.S}
                weight={TextWeight.BOLD}
              >
                {row?.data?.trackNumber}
              </Text>
            </span>
          </CopyTextToClipboard>
          <CopyTextToClipboard
            text={row.data?.externalId ?? ''}
            visibleOnHover
          >
            <span
              onClick={(e) => {
                e.stopPropagation();
                onParcelClick(row.data?.id);
              }}
            >
              <Text
                color={TextColor.DARK_MUTED}
                size={TextSize.XS}
              >
                {row?.data?.externalId}
              </Text>
            </span>
          </CopyTextToClipboard>
        </>
      ),
      alignment: "left",
      width: "150px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("rescueTable.attachment"),
      accessor: (row: TableRowType<TSavingParcel>) => {
        const firstItem = row.data.items?.[0];
        return firstItem ? (
          <a href={firstItem.url ?? ""} target="_blank" rel="noreferrer">
            {firstItem.name ?? ""}
          </a>
        ) : null;
      },
      alignment: "left",
      width: "280px",
    },
    {
      header: t("rescueTable.quantity"),
      accessor: (row: TableRowType<TSavingParcel>) => row.data.items.length ? row.data.items.reduce((accumulator: number, item: TSavingParcelItem) => accumulator + (item.quantity ?? 0), 0) : '',
      alignment: "left",
      width: "100px",
    },
    {
      header: t("rescueTable.service"),
      accessor: (row: TableRowType<TSavingParcel>) => row.data.serviceName,
      alignment: "left",
      width: "200px",
    },
    {
      header: t("rescueTable.value"),
      accessor: (row: TableRowType<TSavingParcel>) =>
        formatSumFromObject(row.data?.estimatedPrice),
      alignment: "right",
      width: "120px",
      fixWidth: true,
    },
    {
      header: t("rescueTable.seller"),
      accessor: (row: TableRowType<TSavingParcel>) => (
        <Avatar
          className={styles.avatar}
          name={row.data.sellerName}
          id={row.data.sellerId}
        />
      ),
      type: TableColumnType.TERTIARY,
      alignment: "center",
      width: "120px",
    },
    {
      header: t("rescueTable.until_disposal"),
      accessor: (row: TableRowType<TSavingParcel>) => {
        const restDays = 100 - getProgressTo(row.data.estimatedUtilizationDate, 1);
        if (row.data.savingType === "SavingAvailable") {
          return (
            <ProgressBar
              progress={restDays}
              label={(restDays > 0) ? `1 ${t("rescueTable.days")}` : `0 ${t("rescueTable.days")}`}
              colors={[
                { value: 0, main: '#ee6373', label: '#ee6373' },
                { value: 0.01, main: '#5ec983', label: '#5ec983' },
              ]}
              column
            />
          )
        }
        return null;
      },
      alignment: "left",
      width: "110px",
      fixWidth: true,
    },
    {
      header: "",
      accessor: (row: TableRowType<TSavingParcel>) => (
        <>
          {row.data.savingType === "SavingAvailable" ? (
            <Button
              onClick={() => onClickSave([row.data])}
              size={ButtonSize.XS}
              title={t("rescueTable.saveButton")}
            />
          ) : (
            <Text
              color={TextColor.PRIMARY}
              size={TextSize.S}
              type={TextType.BLOCK}
            >
              {t(`rescueTable.status.${row.data.savingType}`)}
            </Text>
          )}
        </>
      ),
      alignment: "left",
      noOverflow: true,
      width: "200px",
    },
  ], [currentLanguage]);

  const rescueAmount = +selectedRowsData.reduce((acc: number, item: TSavingParcel) => (acc + item.rescueAmount), 0).toFixed(2);
  const rescueCurrency = profile.account?.region === "TR" ? "USD" : "CNY";
  const rescueAmountString = formatSumFromObject({
    amount: rescueAmount,
    currency: rescueCurrency,
  });

  return (
    <TableSelectionPanel
      isShown={selectedRowsData.length > 0}
      items={[
        {
          label: t("rescueTable.selected"),
          value: selectedRowsData.length,
        },
        {
          label: t("rescueTable.selectedSum"),
          value: `${Intl.NumberFormat().format(selectedSum)} ₽`,
        },
        {
          label: t("rescueTable.selectedSavingPrice"),
          value: rescueAmountString,
        },
      ]}
      buttons={[
        (<Button
          key="savingBtn"
          onClick={() => onClickSave(selectedRowsData)}
          size={ButtonSize.S}
          type={ButtonType.PRIMARY}
          title={t("rescueTable.saveSelectedButton", {
            savePrice: rescueAmountString,
          })}
        />),
      ]}
    >
      <DataTable<TSavingParcel>
        queryKey="getSavings"
        pending={pending}
        getData={getSavings}
        columns={columns}
        selectable
        isRowSelectable={(row: TSavingParcel) => (row.savingType === "SavingAvailable")}
        selectedIds={savedRescueSelectionIds}
        onSelectionChange={onSelectionChange}
        notFound={t("rescueTable.not_savings")}
        filters={filters}
      />
    </TableSelectionPanel>
  );
};

export default RescueTable;