import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { formatDate } from "@utils/formatValues";
import { useTranslation } from "react-i18next";
import {
  getDropShippingOzon,
  AuthorizedAPIs,
  DropShippingOzonParcel,
} from "@api/apiClient/dropshipping";
import Table from "@components/Table/Table";
import { TableColumn, TableColumnType, TableRowType } from "@components/Table/TableTypes";
import { AddSellerPanel } from "@components/AddSeller";
import Box from "@components/Box";
import { Row, Column, Grid } from "@components/Grid";
import DocumentLink from "@components/DocumentLink";
import { Loader } from "@components/Loader";
import { Button, ButtonSize } from "@components/Button";
import TableSelectionPanel from "@components/Table/TableSelectionPanel.tsx";
import { Text, TextColor, TextType } from "@components/Text";
import LinkIcon from "@assets/icons/link.svg";
import infoIcon from '@assets/icons/info_blue_19.svg';
import DropshippingModal from "../modals/DropshippingModal";
import { getCurrentLanguage, Langs } from "@utils/lang";
import styles from "./NewTab.module.scss";
import AuthForm from '../components/AuthForm';

const urlPrefix = "https://unitrade.fra1.digitaloceanspaces.com/public/";

const instruction = {
  [Langs.ru]: {
    fileSize: "261 KB",
    url: `${urlPrefix}docx/inst_dropshipping_ru.docx`,
  },
  [Langs.cn]: {
    fileSize: "532 KB",
    url: `https://storage.yandexcloud.net/unitrade-public/public/180425.pdf`,
  },
  [Langs.tr]: null,
  [Langs.en]: null,
};

const TopPanel = () => {
  const currentLanguage = getCurrentLanguage();
  const { t } = useTranslation();

  return (
    <Grid>
      <Row>
        <Box textAlign="left" pb={7}>
          <Row gap={6}>
            {instruction[currentLanguage] ? (
              <DocumentLink
                icon={infoIcon}
                text={t('dropshipping.instructionLink')}
                {...(instruction[currentLanguage] || { url: "", fileSize: "" })}
              />
            ) : null}
          </Row>
        </Box>
      </Row>
    </Grid>
  )
}

export interface INewTabProps {
  handlerOnApiAuth: () => void;
  authorizedSellers: string[];
  seller?: AuthorizedAPIs | null;
  sellersNotFound: boolean;
  onRefetch: () => void;
}

const NewTab = (props: INewTabProps) => {
  const { handlerOnApiAuth, seller, sellersNotFound, onRefetch } = props;
  const { t } = useTranslation();
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState(new Set<string>());
  const [linkedItems, setLinkedItems] = useState<DropShippingOzonParcel[]>([]);
  const [isModalOpened, setModalOpened] = useState(false);

  const query = useQuery({
    queryKey: ["dropShippingOzon", seller?.id ?? ""],
    queryFn: () => getDropShippingOzon(seller?.id ?? ""),
    retry: 1,
    enabled: isAuthorized,
  });

  const handleSelectionChange = (selectedRowIds: string[]) => {
    const newRowIds = new Set(selectedRowIds);
    setSelectedRowIds(newRowIds);
  };

  useEffect(() => {
    if (seller) {
      setIsAuthorized(Boolean(seller.isAuthorizedApi));
    } else {
      setIsAuthorized(false);
    }
  }, [seller]);

  const columns: TableColumn<DropShippingOzonParcel>[] = [
    {
      header: t("dropshipping.newTab.marketplaceNumber"),
      accessor: (row: TableRowType<DropShippingOzonParcel>) => row.data.externalNumber,
      alignment: "left",
      fixWidth: true,
      width: "160px",
      type: TableColumnType.PRIMARY,
    },
    {
      header: "",
      accessor: (row) => {
        const imgUrl = row.data.items?.[0]?.imageUrl;
        if (!imgUrl) return null;
        return (
         <img
           className={styles.producImage}
           src={imgUrl}
           alt=""
         />
        )
      },
      alignment: "left",
      width: "50px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("dropshipping.newTab.attachment"),
      accessor: (row: TableRowType<DropShippingOzonParcel>) => {
        const firstItem = row.data.items?.[0];
        return firstItem ? (
          <a href={firstItem.url ?? ""} target="_blank" rel="noreferrer">
            {firstItem.name ?? ""}
          </a>
        ) : null;
      },
      alignment: "left",
      width: "300px",
    },
    {
      header: t("dropshipping.newTab.quantity"),
      accessor: (row: TableRowType<DropShippingOzonParcel>) => row.data.items[0]?.quantity ?? "",
      alignment: "left",
      fixWidth: true,
      width: "80px",
      type: TableColumnType.TERTIARY,
    },
    {
      header: t("dropshipping.newTab.orderDate"),
      accessor: (row: TableRowType<DropShippingOzonParcel>) => formatDate(row.data.createdAt, "dd.MMM.yy HH:mm"),
      alignment: "left",
      fixWidth: true,
      width: "125px",
      type: TableColumnType.TERTIARY,
    },
    {
      header: "",
      accessor: (row: TableRowType<DropShippingOzonParcel>) => {
        if (row.isSelected) return null
        return (
          <Button
            icon={LinkIcon}
            onClick={() => {
              setLinkedItems([row.data])
              setModalOpened(true)
            }}
            size={ButtonSize.XS}
            title={t("dropshipping.newTab.createLink")}
          />
        )
      },
      alignment: "left",
      fixWidth: true,
      width: "125px",
    },
  ];

  if (sellersNotFound) {
    return (
      <>
        <TopPanel />
        <AddSellerPanel
          isFirstSeller
          isOzonSeller
          onSuccess={() => onRefetch()}
        />
      </>
    );
  }

  if (seller && !isAuthorized) {
    return (
      <>
        <TopPanel />
        <Box pb={5}>{t("dropshipping.newTab.description")}</Box>
        <Grid>
          <Row>
            <Column phone={12}>
              <Text color={TextColor.DISABLED} type={TextType.H2}>
                {t("dropshipping.newTab.noAuth")}
              </Text>
            </Column>
          </Row>
        </Grid>
        <AuthForm
          seller={seller}
          onAuth={handlerOnApiAuth}
        />
      </>
    );
  }

  if (query.isFetching || (seller === undefined)) {
    return (
      <>
        <TopPanel />
        <Box pb={5}>{t("dropshipping.newTab.description")}</Box>
        <Grid>
          <Row>
            <Column phone={12}>
              <Loader size="s" />
            </Column>
          </Row>
        </Grid>
      </>
    );
  }

  if (query.isFetched && !query.data?.length) {
    return (
      <>
        <TopPanel />
        <Box pb={5}>{t("dropshipping.newTab.description")}</Box>
        <Grid>
          <Row>
            <Column phone={12}>
              <Text color={TextColor.DISABLED} type={TextType.H2}>
                {t("dropshipping.noParcels")}
              </Text>
            </Column>
          </Row>
        </Grid>
      </>
    );
  }

  const selectedRows = (query.data || []).filter(item => selectedRowIds.has(item.id))
  const amount = selectedRows
    .reduce((acc, item) => (
      acc + (item.items || [])
        .reduce((res, { quantity }) => res + quantity, 0)
    ), 0);

  return (
    <>
      <TopPanel />
      <Box pb={5}>{t("dropshipping.newTab.description")}</Box>

      <TableSelectionPanel
        isShown={Boolean(selectedRowIds.size)}
        items={[
          { label: t("dropshipping.newTab.selected"), value: selectedRowIds.size },
          { label: t("dropshipping.newTab.quantity"), value: amount },
        ]}
        buttons={[
          <Button
            key="linkToOrder"
            icon={LinkIcon}
            onClick={() => {
              setLinkedItems(selectedRows)
              setModalOpened(true)
            }}
            size={ButtonSize.S}
            title={t("dropshipping.newTab.linkToOrder")}
          />
        ]}
      >
        <Table
          selectable={true}
          onSelectionChange={handleSelectionChange}
          data={query.data || []}
          columns={columns}
        />
      </TableSelectionPanel>

      {isModalOpened ? (
        <DropshippingModal
          onClose={() => setModalOpened(false)}
          isOpen={isModalOpened}
          linkedItems={linkedItems}
        />
      ) : null}
    </>
  );
};

export default NewTab;
