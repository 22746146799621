import { ReactNode, useEffect, useState } from "react";
import { queryClient } from "../../main";
import AuthenticationContext from "./index.ts";
import * as client from "@api/keycloakClient.ts";
import {updateToken, clearAccountIdHeader, updateAccountIdHeader} from "@api/apiClient/sellerApiClient";
import {
  clearToken,
  getStoredToken,
  isTokenValid,
  isRefreshTokenValid,
  storeToken,
} from "@utils/tokenUtils.ts";
import { Simulate } from "react-dom/test-utils";
import error = Simulate.error;
import * as api from "@api/apiClient";
import useDocumentVisibility from "@hooks/useDocumentVisibility";
import {ZendeskUtils} from "../../utils/zendeskUtils.ts";
import {VITE_APP_DEV} from "../../utils/config.ts";
import {impersonate} from "@api/apiClient/impersonation.ts";

interface AuthenticatedProviderProps {
  children: ReactNode;
}

const AuthenticatedProvider = (props: AuthenticatedProviderProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated_] = useState<boolean>(false);
  const setIsAuthenticated = (v: boolean) => {
    setIsAuthenticated_(v);
    setIsLoading(false);
    if (!v) {
      localStorage.removeItem('dropShippingSellerId');
      localStorage.removeItem('region');
    }
  }
  const isDocumentVisible = useDocumentVisibility();

  useEffect(() => {
    if (isAuthenticated) {
      refreshToken();
      updateVisit();
      const timer1 = setInterval(refreshToken, 60 * 1000);
      const timer2 = setInterval(updateVisit, 180 * 1000);
      return () => {
        clearInterval(timer1);
        clearInterval(timer2);
      };
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (window.location.pathname !== '/signup/ozon' && isDocumentVisible) refreshToken();
  }, [isDocumentVisible]);

  const login = async ({ username, password, emailSeller }: {
    username: string,
    password: string,
    emailSeller?: string,
  }) => {
    const tokenResponse = emailSeller
        ? await impersonate(username, password, emailSeller)
        : await client.getToken(username, password);

    if (tokenResponse.account_id) {
      updateAccountIdHeader(tokenResponse.account_id);
      localStorage.setItem('didAdminComeIn', 'true');
    } else {
      localStorage.removeItem('didAdminComeIn');
    }

    const now = new Date().getTime();
    const tokenData = {
      ...tokenResponse,
      received_at: now,
    };

    storeToken(tokenData);
    updateToken(tokenData?.access_token);

    if (VITE_APP_DEV) {
      await ZendeskUtils.loadInfo();
    }

    setIsAuthenticated(true);
  };

  const logout = () => {
    clearToken();
    updateToken();
    clearAccountIdHeader();
    setIsAuthenticated(false);
    ZendeskUtils.logout();
    queryClient.clear();
    sessionStorage.setItem("isLogout", "true");
  };

  const updateVisit = async () => {
    const token = getStoredToken();
    if (
      !token ||
      !isTokenValid(token)
      || !isDocumentVisible
      || token.account_id
    ) {
      return;
    }
    try {
      await api.updateVisit();
    } catch (e) {
      console.error("Visit update failed", e);
    }
  };

  const refreshToken = async () => {
    const token = getStoredToken();
    try {
      if (!token) {
        setIsAuthenticated(false);
        return;
      }
      if (isTokenValid(token)) {
        setIsAuthenticated(true);
        if(token.account_id) {
          updateAccountIdHeader(token.account_id);
        }
        return;
      }
      if (!isRefreshTokenValid(token)) {
        setIsAuthenticated(false);
        return;
      }
      const tokenResponse = await client.refreshToken(token.refresh_token);
      const now = new Date().getTime();
      const tokenData = {
        ...tokenResponse,
        received_at: now,
        account_id: token?.account_id,
      };
      storeToken(tokenData);
      updateToken(tokenData?.access_token);
      setIsAuthenticated(true);
      if (token.account_id) {
        updateAccountIdHeader(token.account_id);
      }
    } catch (e) {
      setIsAuthenticated(false);
      console.error("Token refresh failed", error);
    }
  };

  return (
    <AuthenticationContext.Provider
      value={{ isAuthenticated, isLoading, login, logout, setIsAuthenticated_ }}
    >
      <>{props.children}</>
    </AuthenticationContext.Provider>
  );
};

export default AuthenticatedProvider;
