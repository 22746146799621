import { Text, TextSize } from '@components/Text';
import { getCurrentLanguage, Langs } from '@utils/lang';
import { useTranslation } from 'react-i18next';

const urlPrefix = '/docs/offerContract/'; // "https://storage.yandexcloud.net/unitrade-public/public/";
const links: Record<Langs, string> = {
  [Langs.cn]: `${urlPrefix}cn/合同（3 项服务).docx`,
  [Langs.en]: `${urlPrefix}en/Agreement_uni_fintech offer (for 3 services).en.docx`,
  [Langs.ru]: `${urlPrefix}ru/Договор_оферта_ЮНИ_ФИНТЕХ (на 3 услуги) рус_версия.docx`,
  [Langs.tr]: `${urlPrefix}tr/Sözleşme teklifi UNI_ FINTECH (3 hizmet için).tr.docx`
};
const links4: Record<Langs, string> = {
  [Langs.cn]: `${urlPrefix}cn/应用程序 №4 表格 - 商品类别确定.CN.xlsx`,
  [Langs.en]: `${urlPrefix}en/Appendix No. 4 Table - Definitions of the category товаров.en.xlsx`,
  [Langs.ru]: `${urlPrefix}ru/Приложение №4 Таблица - Определение категории товаров.xlsx`,
  [Langs.tr]: `${urlPrefix}tr/Ek No. 4 Tablo - Ürün kategorilerinin tanımı.tr.xlsx`
};
const links5: Record<Langs, string> = {
  [Langs.cn]: `${urlPrefix}cn/应用程序 №5 表格 - 实现商品价格确定顺序.CN.xlsx`,
  [Langs.en]: `${urlPrefix}en/Appendix No. 5 Table - Procedure for determining the price of goods sold.en.xlsx`,
  [Langs.ru]: `${urlPrefix}ru/Приложение №5 Таблица - Порядок определения цены реализуемых товаров.xlsx`,
  [Langs.tr]: `${urlPrefix}tr/Ek No. 5 Tablo - Satılan Malın Fiyatı Belirleme Prosedürü.tr`
};
const links6: Record<Langs, string> = {
  [Langs.cn]: `${urlPrefix}cn/应用程序 №6 - 运输代理服务费率.CN.xlsx`,
  [Langs.en]: `${urlPrefix}en/Appendix No. 6 - Tariffs for freight forwarding services.en.xlsx`,
  [Langs.ru]: `${urlPrefix}ru/Приложение №6 - Тарифы на транспортно-экспедиционные услуги.xlsx`,
  [Langs.tr]: `${urlPrefix}tr/Ek No. 6 - Taşıma ve nakliye hizmetlerine ilişkin tarifeler.xlsx`
};

const FormDocs: React.FC = (): React.ReactElement => {

  const currentLanguage = getCurrentLanguage();
  const { t } = useTranslation();

  return (
      <Text
          size={TextSize.S}
          dangerouslySetInnerHTML={{
            __html: t('signUp.email.footer', {
              link: links[currentLanguage],
              link4: links4[currentLanguage],
              link5: links5[currentLanguage],
              link6: links6[currentLanguage]
            })
          }}
      />
  );
};

export default FormDocs;