import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useMutation } from 'react-query';
import { linkSendCode } from "@api/apiClient";
import { Grid, Column, Row } from "@components/Grid";
import { Text, TextSize, TextType, TextColor } from "@components/Text";
import TextInputContainer from "@containers/TextInputContainer";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import Box from "@components/Box";
import FormErrorMessage from "@components/FormErrorMessage";

export interface AddShopFormType {
  email: string;
}

interface AddShopFormProps {
  isFirstSeller?: boolean;
  onSuccess: (data: {
    email: string,
    ticketId: string,
    isCompanyFound: boolean,
  }) => void;
}

const AddShopForm = ({
  onSuccess,
  isFirstSeller,
}: AddShopFormProps) => {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm<AddShopFormType>({
    defaultValues: {
      email: "",
    },
  });

  const {
    isLoading,
    mutate,
    error,
  } = useMutation({
    mutationFn: linkSendCode,
  });

  const onSubmit = (form: AddShopFormType) => {
    mutate({
      email: form.email,
    }, {
      onSuccess: ({ ticketId, foundCompanies }) => {
        onSuccess({
          email: form.email,
          ticketId,
          isCompanyFound: Boolean(foundCompanies?.length),
        });
      },
    });
  };

  return (
    <>
      <Grid>
        <Row>
          <Column phone={12}>
            <Box pb={3}>
              <Text type={TextType.H1}>
                {t(isFirstSeller ? "addShop.titleFirst" : "addShop.title")}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box pb={5}>
              <Text color={TextColor.PRIMARY} size={TextSize.M}>
                {isFirstSeller ? t("addShop.descriptionFirst") : ""}
              </Text>
            </Box>
          </Column>
        </Row>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Column phone={12}>
              <Box pb={3}>
                <TextInputContainer
                  control={control}
                  name="email"
                  title={t("addShop.addShopEmail")}
                  rules={{ required: t("defaultFormErrors.emailFormat") }}
                  type="email"
                />
              </Box>
            </Column>
          </Row>
          <Row>
            <Column phone={12}>
              <Box pb={8}>
                <Text color={TextColor.DISABLED} size={TextSize.S}>
                  {t("addShop.emailDescription")}
                </Text>
              </Box>
            </Column>
          </Row>
          {error ? (
            <Row>
              <Column phone={12}>
                <Box pb={2}>
                  <FormErrorMessage error={error} />
                </Box>
              </Column>
            </Row>
          ) : null}
          <Row>
            <Column phone={12}>
              <Box pb={5}>
                <Button
                  title={t("addShop.addBtn")}
                  type={ButtonType.BLOCK}
                  size={ButtonSize.S}
                  onClick={handleSubmit(onSubmit)}
                  pending={isLoading}
                />
              </Box>
            </Column>
          </Row>
        </form>
      </Grid>
    </>
  );
};

export default AddShopForm;
