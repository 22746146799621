import { useState, useRef, useCallback } from 'react';
import { useMutation } from 'react-query';
// import { useTranslation } from "react-i18next";
import { ProblematicParcel, returnParcel } from "@api/apiClient/problematicParcels";
import { DeliveryInfo } from "@api/apiClient/types";
import { DataViewHandle } from "@containers/DataTable";
// import { Text, TextSize, TextType, TextWeight } from "@components/Text";
// import { Button, ButtonType } from "@components/Button";
import { Modal } from "@components/Modal";
import ParcelModalWindow from "@containers/ParcelModalWindow";
import AwaitingTable from "../components/AwaitingTable";
import DisposalModalWindow from "../modals/DisposalModalWindow";
import ReturnModalWindow from "@components/modals/ReturnModalWindow";
// import WarningIcon from "@assets/icons/warning.svg";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import { useProfile } from '@contexts/ProfileContext';

const AwaitingTab = ({
  filters,
  pending,
  handlerSetSavedAwaitingSelection,
  savedAwaitingSelection,
  refreshData,
}: {
  filters: FilterModel[],
  pending: boolean,
  handlerSetSavedAwaitingSelection: (value: (((prevState: string[]) => string[]) | string[])) => void,
  savedAwaitingSelection: string[],
  refreshData: () => void,
}) => {
  const [detailId, setDetailId] = useState("");
  const [isOpenParcelWindow, setIsOpenParcelWindow] = useState(false);

  const closeParcelWindow = () => {
    setIsOpenParcelWindow(false);
  }

  const handleParcelClick = (parcelId: string) => {
    setIsOpenParcelWindow(true)
    setDetailId(parcelId)
  }
  // const { t } = useTranslation();
  const [isSentReturnsOpen, setIsSentReturnsOpen] = useState(false);
  const [isRecyclingOpen, setIsRecyclingOpen] = useState(false);
  const [selectedSum, setSelectedSum] = useState<number>(0);
  const [selectedDelivery, setSelectedDelivery] = useState<number>(0);
  const [activeItem, setActiveItem] = useState<ProblematicParcel | null>(null);
  const [countryCode, setCountryCode] = useState<string>("");

  const { account } = useProfile();

  const handleSelectionChange = useCallback((items: ProblematicParcel[]) => {
    const newRowIds = items.map(v => v.id);
    const sum = +items.reduce((acc, item) => (acc + item.declaredValue.amount), 0).toFixed(2);
    const delivery = +items.reduce((acc: number, item: ProblematicParcel) => (acc + (account?.region === 'TR' ? 12 : item.isExtraSmall ? 10 : 20)), 0).toFixed(2);

    handlerSetSavedAwaitingSelection(newRowIds);
    setSelectedSum(sum);
    setSelectedDelivery(delivery);
    setCountryCode(items[0]?.countryCode || "");
  }, []);

  const dataRef = useRef<DataViewHandle>(null);
  const activeIds = activeItem ? [activeItem?.id] : savedAwaitingSelection;

  const mutation = useMutation({
    mutationFn: (data: {
      parcelIds: string[],
      deliveryInfo: DeliveryInfo,
      comment: string,
    }) => returnParcel({
      deliveryInfo: {
        ...data.deliveryInfo,
        comment: data.comment,
      },
      parcelIds: data.parcelIds,
      comment: data.comment,
    }),
    onSuccess: () => {
      handlerSetSavedAwaitingSelection([]);
      if (typeof dataRef.current?.refresh === "function") {
        dataRef.current.refresh();
      }
      setIsSentReturnsOpen(false);
      refreshData();
    },
  });

  return (
    <div>
      <AwaitingTable
        key="AwaitingTable"
        onParcelClick={handleParcelClick}
        onClickDeliver={(item) => {
          setActiveItem(item ?? null);
          setIsSentReturnsOpen(true);
          if (item) setCountryCode(item?.countryCode || "");
        }}
        onClickDisposal={(item) => {
          setActiveItem(item ?? null);
          setIsRecyclingOpen(true);
        }}
        onSelectionChange={handleSelectionChange}
        selectedRowIds={savedAwaitingSelection}
        selectedSum={selectedSum}
        pending={pending}
        filters={filters}
        dataRef={dataRef}
        selectedDelivery={selectedDelivery}
      />

      {/* Утилизация */}
      <Modal
        isOpen={isRecyclingOpen}
        onClose={() => {
          setIsRecyclingOpen(false);
        }}
        onClickOverlay={() => {
          setIsRecyclingOpen(false);
        }}
      >
        <DisposalModalWindow
          parcelIds={activeIds}
          success={() => {
            handlerSetSavedAwaitingSelection([]);
            if (typeof dataRef.current?.refresh === "function") {
              dataRef.current.refresh();
            }
            setIsRecyclingOpen(false);
            refreshData();
          }}
          close={() => {
            setIsRecyclingOpen(false);
          }}
        />
      </Modal>

      {/* Возвраты */}
      <Modal
        isOpen={isSentReturnsOpen}
        onClose={() => {
          setIsSentReturnsOpen(false);
        }}
        onClickOverlay={() => {
          setIsSentReturnsOpen(false);
        }}
      >
        <ReturnModalWindow
          onClose={() => {
            setIsSentReturnsOpen(false);
          }}
          parcelIds={activeIds}
          mutation={mutation}
          DeliverySum={activeItem ? 10 : selectedDelivery}
          selectedDepartureCount={1}
          Sum={activeItem ? activeItem.declaredValue.amount : selectedSum}
          countryCode={countryCode}
          isProblematicParcel
        />
      </Modal>

      {isOpenParcelWindow ? (
        <Modal
          isOpen={isOpenParcelWindow}
          onClose={closeParcelWindow}
          onClickOverlay={closeParcelWindow}
          width={900}
          bodyWithoutIndentations
        >
          <ParcelModalWindow
            parcelId={detailId}
          />
        </Modal>
      ) : null}
    </div>
  );
};

export default AwaitingTab;
