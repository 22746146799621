import React from 'react'
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./i18n";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthenticatedProvider } from "./contexts/AuthenticationContext";
import "./index.css";
import ProfileProvider from "./contexts/ProfileContext/ProfileProvider.tsx";
import * as Sentry from "@sentry/react";
import {VITE_APP_DEV} from "@utils/config.ts";
import SellersCounterProvider from './contexts/SellersCounterContext/SellersCounterProvider';
import KnowledgeBaseProvider from '@contexts/KnowledgeBaseContext/KnowledgeBaseProvider';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      staleTime: 5 * 1000,
    },
  },
});

Sentry.init({
  dsn: "https://1b348ca02d5557bf7690d1d8c2b0d7ac@o4506959875473408.ingest.us.sentry.io/4508415612157952",
  integrations: [Sentry.browserTracingIntegration()],
  environment: VITE_APP_DEV ? "development" : "production",
  tracesSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <AuthenticatedProvider>
          <ProfileProvider>
            <SellersCounterProvider>
              <KnowledgeBaseProvider>
                <App/>
              </KnowledgeBaseProvider>
            </SellersCounterProvider>
          </ProfileProvider>
        </AuthenticatedProvider>
      </QueryClientProvider>
    </React.StrictMode>
);
