export enum FilterTypes {
  SELLER = "seller",
  DATE_RANGE = "dateRange",
  SEARCH = "search",
  MULTI_SEARCH = "multiSearch",
  MULTI_SEARCH_OR_NAME = "multiSearchOrName",
  SERVICE_PICKUP="service-pickup",
  SERVICE_PARCELS="service-parcels",
  SERVICE_RETURNS="service-returns",
  SERVICE_PROBLEMATIC="service-problematic",
  MULTI_SELECT = "multiSelect",
  STATUS = "status",
  STATE = "state"
}
