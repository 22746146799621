import { useState, ReactElement } from 'react';
import { useTranslation } from "react-i18next";
import {
  TableColumn,
  TableColumnType,
  TableRowType,
} from "@components/Table/TableTypes";
import { getResalesPage, ResaleDto, ResaleItemDto } from "@api/apiClient";
import { AddSellerPanel } from "@components/AddSeller";
import NoItems from "@components/NoItems";
import Avatar from "@components/Avatar";
import { Row } from "@components/Grid";
import DataTable from "@containers/DataTable";
import CancelModalWindow from "../modals/CancelModalWindow.tsx";
import DisposalQueueModalWindow from "../modals/DisposalQueueModalWindow.tsx";
import { Modal } from "@components/Modal";
import Box from "@components/Box";
import { Button, ButtonSize } from "@components/Button";
import Popup from "@components/Popup";
import CopyTextToClipboard from "@components/CopyTextToClipboard/CopyTextToClipboard.tsx";
import { formatDate } from "@utils/formatValues";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import styles from "./returnsPageTabs.module.css";
import moment from "moment";
import TableSelectionPanel from '@components/Table/TableSelectionPanel';

const ResaleTab = ({
  filters,
  pending,
  downloadingBtn,
  handlerSetSavedResaleSelection,
  savedResaleSelection,
  refreshData,
  sellersNotFound,
  noData,
}: {
  filters: FilterModel[],
  pending: boolean,
  downloadingBtn: ReactElement,
  handlerSetSavedResaleSelection: (value: (((prevState: string[]) => string[]) | string[])) => void,
  savedResaleSelection: string[],
  refreshData: () => void,
  sellersNotFound: boolean,
  noData: boolean,
}) => {
  const [isOpenCancelWindow, setIsOpenCancelWindow] = useState(false);
  const [isOpenDisposalQueueWindow, setIsOpenDisposalQueueWindow] = useState(false);
  const [cancelId, setIsCancelId] = useState("");
  const { t } = useTranslation();

  const columns: TableColumn<ResaleDto>[] = [
    {
      header: t("resaleTable.oldTrackNumber"),
      accessor: (row: TableRowType<ResaleDto>) => (
        <CopyTextToClipboard text={row.data?.oldTrackNumber}>
          <b>{row.data?.oldTrackNumber}</b>
        </CopyTextToClipboard>
      ),
      alignment: "left",
      width: "150px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("resaleTable.newTrackNumber"),
      accessor: (row: TableRowType<ResaleDto>) => (
        <CopyTextToClipboard text={row.data?.newTrackNumber}>
          <b>{row.data?.newTrackNumber}</b>
        </CopyTextToClipboard>
      ),
      alignment: "left",
      width: "150px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("resaleTable.itemName"),
      accessor: (row: TableRowType<ResaleDto>) => row.data.items?.[0]?.name ?? "",
      alignment: "left",
      width: "250px",
    },
    {
      header: t("resaleTable.quantity"),
      accessor: (row: TableRowType<ResaleDto>) => row.data.items.length ? row.data.items.reduce((accumulator: number, item: ResaleItemDto) => accumulator + item.quantity, 0) : '',
      alignment: "left",
      width: "100px",
    },
    {
      header: t("resaleTable.seller"),
      accessor: (row: TableRowType<ResaleDto>) => (
        <Row noWrap align="center">
          <Avatar
            className={styles.avatar}
            name={row.data.sellerName}
            id={row.data.sellerId}
          />
          <span className={styles.sellerText}>
            {row.data.sellerName}
          </span>
        </Row>
      ),
      type: TableColumnType.TERTIARY,
      alignment: "left",
      width: "220px",
    },
    {
      header: t("resaleTable.setupAt"),
      accessor: (row: TableRowType<ResaleDto>) => (
          <div>
            {formatDate(row.data.setupAt, "dd.MM.yy HH:mm")}
          </div>
      ),
      alignment: "left",
      width: "130px",
    },
    {
      header: "",
      accessor: (row: TableRowType<ResaleDto>) => (
        <Box beforeSpace pr={10}>
          {row.data.isCancelable ? (
            <Button
              onClick={() => {
                const condition: boolean = row.data.estimatedUtilizationDate !== null;
                setIsCancelId(row.data.id);
                (condition && moment(row.data.estimatedUtilizationDate).diff(moment(), 'hours') < 24)
                  ? setIsOpenDisposalQueueWindow(true)
                  : setIsOpenCancelWindow(true);
              }}
              size={ButtonSize.XS}
              title={t("disposalTable.resetButton")}
            />
          ) : (
            <Popup text={t("disposalTable.resetButtonNoty")}>
              <Button
                disabled
                onClick={() => {}}
                size={ButtonSize.XS}
                title={t("disposalTable.resetButton")}
              />
            </Popup>
          )}
        </Box>
      ),
      alignment: "left",
      noOverflow: true,
      width: "200px",
    },
  ];

  if (sellersNotFound) {
    return (
      <AddSellerPanel isFirstSeller />
    );
  }

  if (noData) {
    <NoItems
      title={t("noItems.returns.title")}
      description={t("noItems.returns.description")}
    />
  }

  return (
    <TableSelectionPanel
        isShown={savedResaleSelection.length > 0}
        items={[
          {
            label: t("awaitingTableSelectionPanel.selected"),
            value: savedResaleSelection.length,
          }
        ]}
        buttons={[downloadingBtn]}
    >
      <DataTable<ResaleDto>
        queryKey="resales-page"
        pending={pending}
        getData={getResalesPage}
        columns={columns}
        selectable
        selectedIds={savedResaleSelection}
        onSelectionChange={handlerSetSavedResaleSelection}
        notFound={t("resaleTab.notResale")}
        filters={filters}
      />

      <Modal
        isOpen={isOpenCancelWindow}
        onClose={() => {
          setIsOpenCancelWindow(false);
        }}
        onClickOverlay={() => {
          setIsOpenCancelWindow(false);
        }}
      >
        <CancelModalWindow
          parcelId={cancelId}
          onSuccess={() => {
            handlerSetSavedResaleSelection([]);
            refreshData();
            setIsCancelId("");
            setIsOpenCancelWindow(false);
          }}
          onClose={() => {
            setIsOpenCancelWindow(false);
            setIsCancelId("");
          }}
        />
      </Modal>

      <Modal
          isOpen={isOpenDisposalQueueWindow}
          onClose={() => {
            setIsOpenDisposalQueueWindow(false);
          }}
          onClickOverlay={() => {
            setIsOpenDisposalQueueWindow(false);
          }}
      >
        <DisposalQueueModalWindow
            parcelId={cancelId}
            onSuccess={() => {
              handlerSetSavedResaleSelection([]);
              refreshData();
              setIsCancelId("");
              setIsOpenDisposalQueueWindow(false);
            }}
            onClose={() => {
              setIsOpenDisposalQueueWindow(false);
              setIsCancelId("");
            }}
            tabType="resale"
        />
      </Modal>
    </TableSelectionPanel>
  );
};

export default ResaleTab;
