import { createContext, useContext } from 'react';

interface IKnowledgeBaseContext {
  isKnowledgeBaseVisible: boolean;
  knowledgeBaseUrl: string;
  setKnowledgeBaseParams: (url: string) => void;
}

const KnowledgeBaseContext = createContext<IKnowledgeBaseContext | null>(null);

export const useKnowledgeBase = (): IKnowledgeBaseContext => {
  const context = useContext(KnowledgeBaseContext);
  if (!context) {
    throw new Error('useKnowledgeBase must be used within an KnowledgeBase');
  }
  return context;
};

export default KnowledgeBaseContext;
export { default as KnowledgeBase } from './KnowledgeBaseProvider.tsx';