import KnowledgeBaseContext from './index.ts';
import { ReactNode, useState } from 'react';

interface IKnowledgeBaseProviderProps {
  children: ReactNode;
}

const KnowledgeBaseProvider = (props: IKnowledgeBaseProviderProps) => {
  const [isKnowledgeBaseVisible, setIsKnowledgeBaseVisible] = useState<boolean>(false);
  const [knowledgeBaseUrl, setKnowledgeBaseLink] = useState<string>('');

  const setKnowledgeBaseParams = (url: string) => {
    setIsKnowledgeBaseVisible(!!url.length);
    setKnowledgeBaseLink(url);
  };

  return (
      <KnowledgeBaseContext.Provider
          value={{
            isKnowledgeBaseVisible,
            knowledgeBaseUrl,
            setKnowledgeBaseParams
          }}
      >
        <>{props.children}</>
      </KnowledgeBaseContext.Provider>
  );
};

export default KnowledgeBaseProvider;