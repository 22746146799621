import styles from './ReturnsPage.module.css';
import AwaitingTab from './tabs/AwaitingTab.tsx';
import cn from 'classnames';
import DisposalTab from './tabs/DisposalTab.tsx';
import FilterIcon from '@assets/icons/filter.svg';
import Page from '@components/Page';
import ProcessingTab from './tabs/ProcessingTab.tsx';
import ReturnsTab from './tabs/ReturnsTab.tsx';
import useTab from '@hooks/useTab';
import { Button, ButtonSize, ButtonType } from '@components/Button';
import { TabsList, TabsContent } from '@components/Tabs/Tabs';
import {
  useAwaitingCounter,
  useDisposalCounter,
  useProcessingCounter,
  useReturnsCounter
} from '@api/apiClient/returns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSellersCounter } from '@contexts/SellersCounterContext';

const ReturnsPage: React.FC = (): React.ReactElement => {
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const { t } = useTranslation();
  const { sellersNotFound } = useSellersCounter();

  const { data: awaitingTotal, refetch: awaitingRefetch } = useAwaitingCounter(!sellersNotFound);
  const { data: disposalTotal, refetch: disposalRefetch } = useDisposalCounter(!sellersNotFound);
  const { data: processingTotal, refetch: processingRefetch } = useProcessingCounter(!sellersNotFound);
  const { data: returnsTotal, refetch: returnsRefetch } = useReturnsCounter(!sellersNotFound);

  const tabs = [
    {
      id: 'AwaitingTab',
      title: t('returnsPage.awaitingTab.title'),
      content: (
          <AwaitingTab
              isFilterOpen={isFilterOpen}
              setIsFilterOpen={setIsFilterOpen}
              onUpdateAwaiting={awaitingRefetch}
              onUpdateDisposal={disposalRefetch}
              onUpdateReturns={returnsRefetch}
              sellersNotFound={sellersNotFound}
          />
      ),
      count: awaitingTotal
    },
    {
      id: 'ProcessingTab',
      title: t('returnsPage.processingTab.title'),
      content: (
          <ProcessingTab
              isFilterOpen={isFilterOpen}
              setIsFilterOpen={setIsFilterOpen}
              onUpdate={processingRefetch}
              sellersNotFound={sellersNotFound}
          />
      ),
      count: processingTotal,
      disable: sellersNotFound
    },
    {
      id: 'ReturnsTab',
      title: t('returnsPage.returnsTab.title'),
      content: (
          <ReturnsTab
              isFilterOpen={isFilterOpen}
              setIsFilterOpen={setIsFilterOpen}
              onUpdate={returnsRefetch}
              sellersNotFound={sellersNotFound}
          />
      ),
      count: returnsTotal,
      disable: sellersNotFound
    },
    {
      id: 'DisposalTab',
      title: t('returnsPage.disposalTab.title'),
      content: (
          <DisposalTab
              isFilterOpen={isFilterOpen}
              setIsFilterOpen={setIsFilterOpen}
              onUpdate={disposalRefetch}
              sellersNotFound={sellersNotFound}
          />
      ),
      count: disposalTotal,
      disable: sellersNotFound
    }
  ];

  const { activeTab, setActiveTab } = useTab(tabs);

  return (
      <Page title={t('returnsPage.title')}>
        <div className={styles.tabsRow}>
          <TabsList
              onChange={setActiveTab}
              orderArrangement={[1,0,2,3]}
              tabs={tabs}
              value={activeTab}
          />
          {!sellersNotFound && (
              <div className={styles.filters}>
                <Button
                    className={cn(isFilterOpen ? styles.filterBtnActive : styles.filterBtnInactive)}
                    icon={FilterIcon}
                    onClick={() => setIsFilterOpen(!isFilterOpen)}
                    size={ButtonSize.S}
                    type={ButtonType.ROUNDED}
                    title={t('filters.filters')}
                />
              </div>
          )}
        </div>
        <TabsContent
            tab={tabs.find((tab) => (tab.id === activeTab))}
        />
      </Page>
  );
};

export default ReturnsPage;
