import cn from "classnames";
import { Text, TextSize, TextWeight } from "@components/Text";
import styles from './InfoPanel.module.css';

export enum InfoPanelType {
  INFO = "info",
  WARNING = "warning",
}

interface InfoPanelProps {
  title?: string;
  description?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  type?: InfoPanelType
}

const InfoPanel: React.FC<InfoPanelProps> = ({
  title,
  description,
  className,
  type,
  children,
}) => {
  return (
    <div
      className={cn(
        className,
        styles.root,
        styles[`type-${type}`]
      )}
    >
      {title ? (
        <Text size={TextSize.M} weight={TextWeight.BOLD}>
          {title}
        </Text>
      ) : null}
      {description ? (
        <Text size={TextSize.M} weight={TextWeight.MEDIUM}>
          {description}
        </Text>
      ) : null}
      {children}
    </div>
  );
};

export default InfoPanel;
