import styles from './Banner.module.scss';
import CloseIcon from '@assets/icons/close-grey-12.svg';
import cn from 'classnames';
import { Button, ButtonType } from '@components/Button';
import { getCurrentLanguage } from '@utils/lang';
import { QueryObserverResult, RefetchOptions, useMutation } from 'react-query';
import { sendNewsRead, sendPushesRead, TPushes, useNewsCounter } from '@api/apiClient/news';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface IBannerProps {
  bottom?: number;
  handlerRefetchGetPushes?: (options?: RefetchOptions) => Promise<QueryObserverResult>;
  handlerSetIsPushesVisible?: (value: (((prevState: boolean) => boolean) | boolean)) => void;
  isPositionFixed?: boolean;
  left?: number;
  pushes: TPushes;
  right?: number;
  top?: number;
  withAnimation?: boolean;
}

const Banner: React.FC<IBannerProps> = (props: IBannerProps): React.ReactElement => {
  const {
    bottom,
    handlerRefetchGetPushes,
    handlerSetIsPushesVisible,
    isPositionFixed,
    left,
    pushes,
    right,
    top,
    withAnimation
  } = props;

  const [isBannerVisible, setIsBannerVisible] = useState<boolean>(false);

  const currentLanguage = getCurrentLanguage();
  const navigate = useNavigate();
  const { refetch: refetchNewsCounter } = useNewsCounter(currentLanguage);
  const { t } = useTranslation();

  const { mutate: mutateSendNewsRead } = useMutation({
    mutationFn: sendNewsRead,
    onSuccess: () => refetchNewsCounter()
  });

  const { mutate: mutateSendPushesRead } = useMutation({
    mutationFn: sendPushesRead,
    onSuccess: () => {
      handlerRefetchGetPushes && setTimeout(() => handlerRefetchGetPushes(), 180000);
    }
  });

  useEffect(() => {
    withAnimation && setTimeout(() => setIsBannerVisible(true), 500);
  }, []);

  const handlerOnCloseBanner = () => {
    withAnimation && setIsBannerVisible(false);
    handlerSetIsPushesVisible && setTimeout(() => handlerSetIsPushesVisible(false), 500);
  };

  const followLink = () => {
    const link = pushes[0].link;
    if (pushes[0].isExternal) {
      window.open(link);
    } else {
      mutateSendNewsRead(link);
      navigate(`/news?newsId=${link}`);
    }
    mutateSendPushesRead({ cancelled: false, link: link });
    handlerOnCloseBanner();
  };

  const doNotShowAgain = () => {
    mutateSendPushesRead({ cancelled: true, link: pushes[0].link });
    handlerOnCloseBanner();
  };

  return (
      <div
          className={cn(styles.banner,
              isPositionFixed ? styles.bannerFixed : styles.bannerRelative,
              withAnimation && styles.bannerWithAnimation,
              isBannerVisible && styles.bannerWithAnimationVisible
          )}
          style={{
            top: `${top}px`,
            right: `${right}px`,
            bottom: `${bottom}px`,
            left: `${left}px`
          }}
      >
        {handlerSetIsPushesVisible && (
            <button
                className={cn(styles.bannerClose, pushes[0].imgUrl ? styles.bannerCloseWhite : styles.bannerCloseGrey)}
                onClick={handlerOnCloseBanner}
            >
              <img
                  alt="Close"
                  src={CloseIcon}
              />
            </button>
        )}
        {pushes[0].imgUrl && (
            <div className={styles.bannerImg}>
              <img
                  className={styles.bannerImgSelf}
                  alt={pushes[0].title}
                  src={pushes[0].imgUrl}
              />
            </div>
        )}
        <div className={styles.bannerInner}>
          <span className={styles.bannerTitle}>{pushes[0].title}</span>
          <span className={styles.bannerText}>{pushes[0].description}</span>
          <Button
              onClick={followLink}
              title={t('banner.btnAction')}
              type={ButtonType.BLOCK}
          />
          <Button
              isWide
              onClick={doNotShowAgain}
              title={t('banner.btnCancel')}
              type={ButtonType.SKELETON}
          />
        </div>
      </div>
  );
};

export default Banner;