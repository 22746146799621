import styles from './BeforeAwaitingTable.module.scss';
import cn from 'classnames';
import { BeforeProps } from '@containers/DataTable';
import { TAwaitingParcel } from '@api/apiClient/returns';
import { Text, TextSize } from '@components/Text';
import { Trans, useTranslation } from 'react-i18next';

const BeforeAwaitingTable = ({ items, isLoading }: BeforeProps<TAwaitingParcel>) => {
  const { t } = useTranslation();

  const recentReturnsCount = items.filter((item: TAwaitingParcel) => {
    const deadlineDate = new Date(item.deadline);
    return Date.now() + 7 * 24 * 60 * 60 * 1000 >= deadlineDate.getTime();
  }).length || 0;

  if (!isLoading) {
    if (recentReturnsCount > 0) {
      return (
          <div className={cn(styles.inner, styles.innerRed)}>
            <Text size={TextSize.M}>
              <b className={cn(styles.text, styles.textRed)}>
                {t('returnsPage.awaitingTab.notyReturnsTitle')}
              </b>
            </Text>
            <Text size={TextSize.M}>
              <span className={cn(styles.text, styles.textRed)}>
                <b>
                  {recentReturnsCount}
                </b>{' '}-{' '}
                <Trans i18nKey="returnsPage.awaitingTab.notyReturnsText"/>
              </span>
            </Text>
          </div>
      );
    }
    if (items.length) {
      return (
          <div className={cn(styles.inner, styles.innerBlue)}>
            <Text size={TextSize.M}>
              <b className={cn(styles.text, styles.textBlue)}>
                {t('returnsPage.awaitingTab.notyAwaitTitle')}
              </b>
            </Text>
            <Text size={TextSize.M}>
              <span className={cn(styles.text, styles.textBlue)}>
                <b>
                  {items.length}
                </b>{' '}-{' '}
                <Trans i18nKey="returnsPage.awaitingTab.notyAwaitText"/>
              </span>
            </Text>
          </div>
      );
    }
  }
  return null;
};

export default BeforeAwaitingTable;
