import Box from '@components/Box';
import getFullAddress from '@utils/getFullAddress';
import SelectDropdown from '@components/SelectDropdown';
import Switcher from '@components/Switcher';
import TextAreaContainer from '@containers/TextAreaContainer';
import TextInputContainer from '@containers/TextInputContainer';
import { AddressTemplate, TLabelSelectedItem } from '@api/apiClient';
import { Grid, Row, Column } from '@components/Grid';
import { IReturnFormProps, TFullListAddresses } from '../types';
import { Text, TextType } from '@components/Text';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ReturnForm: React.FC<IReturnFormProps> = (props: IReturnFormProps): React.ReactElement => {
  const {
    addressTemplates,
    clearErrors,
    control,
    defaultAddress,
    openForm,
    rememberAddress,
    setRememberAddress,
    setValue,
    watch
  } = props;

  const [currentAddress, setCurrentAddress] = useState<AddressTemplate>(defaultAddress);
  const [fullListAddresses, setFullListAddresses] = useState<TFullListAddresses>([]);

  const { t } = useTranslation();

  useEffect(() => {
    let filteredAddressTemplates: AddressTemplate[] = addressTemplates.filter((item: AddressTemplate) =>
        item.address.rawLine !== defaultAddress.address.rawLine);
    filteredAddressTemplates = filteredAddressTemplates.reverse();
    setFullListAddresses([defaultAddress, ...filteredAddressTemplates]);
  }, [defaultAddress]);

  const labelSelectedItem: TLabelSelectedItem = {
    address: control._formValues.address,
    id: control._formValues.id
  };

  watch([
    'address.city',
    'address.entrance',
    'address.flat',
    'address.floor',
    'address.street',
    'address.zipCode'
  ]);

  const city = watch('addressEnglish.city');
  const street = watch('addressEnglish.street');

  useEffect(() => {
    city && setValue('addressEnglish.city', city.replace(/[^A-Za-z0-9\s.,#\-/]/g, ''));
  }, [city]);

  useEffect(() => {
    street && setValue('addressEnglish.street', street.replace(/[^A-Za-z0-9\s.,#\-/]/g, ''));
  }, [street]);

  const handlerGetFullAddress = (item: TLabelSelectedItem, from?: string): string => {
    let fullAddress: string = '';
    const address = { ...item.address };
    if (item.id === '') {
      if (from === 'renderItem') {
        fullAddress = `+ ${t('returnForm.newAddress')}`;
      } else {
        if (address.city === '' && address.entrance === '' && address.flat === '' && address.floor === '' && address.street === '' && address.zipCode === '') {
          fullAddress = `+ ${t('returnForm.newAddress')}`;
        } else {
          fullAddress = getFullAddress(address);
        }
      }
    } else {
      fullAddress = getFullAddress(address);
    }
    return fullAddress;
  };

  const handlerSetCurrentAddress = (id: string): void => {
    const addressApplication: AddressTemplate = fullListAddresses.find((address: AddressTemplate) => address.id === id)!;
    setCurrentAddress(addressApplication);
    setValue('address', addressApplication.address);
    setValue('addressEnglish', addressApplication.addressEnglish);
    setValue('fullName', addressApplication.fullName);
    setValue('id', addressApplication.id);
    setValue('phone', addressApplication.phone);
    clearErrors();
    setRememberAddress(id === '' || openForm);
  };

  return (
      <Grid>
        <Row>
          <Column phone={12}>
            <Box mb={5}>
              <SelectDropdown
                  defaultSelectedItem={defaultAddress}
                  items={fullListAddresses}
                  keyExtractor={(item: TLabelSelectedItem) => item.id}
                  label={t('returnForm.addressTitle')}
                  labelSelectedItem={() => handlerGetFullAddress(labelSelectedItem)}
                  onItemSelect={(id: string) => handlerSetCurrentAddress(id)}
                  renderItem={(props: { item: TLabelSelectedItem }) =>
                      <div>{handlerGetFullAddress(props.item, 'renderItem')}</div>}
              />
            </Box>
          </Column>
        </Row>
        {(currentAddress.id === '' || openForm) && (
            <>
              <Row>
                <Column phone={12}>
                  <Box mb={5}>
                    <TextInputContainer
                        control={control}
                        name="address.city"
                        title={t('returnForm.city')}
                    />
                  </Box>
                </Column>
              </Row>
              <Row>
                <Column phone={9}>
                  <Box
                      mb={5}
                      pr={4}
                  >
                    <TextInputContainer
                        control={control}
                        name="address.street"
                        title={t('returnForm.streetHouse')}
                    />
                  </Box>
                </Column>
                <Column phone={3}>
                  <Box mb={5}>
                    <TextInputContainer
                        control={control}
                        name="address.zipCode"
                        title={t('returnForm.zipCode')}
                    />
                  </Box>
                </Column>
              </Row>
              <Row>
                <Column phone={4}>
                  <Box
                      mb={8}
                      pr={4}
                  >
                    <TextInputContainer
                        control={control}
                        name="address.flat"
                        title={t('returnForm.flatNumber')}
                    />
                  </Box>
                </Column>
                <Column phone={3}>
                  <Box
                      mb={8}
                      pr={4}
                  >
                    <TextInputContainer
                        control={control}
                        name="address.entrance"
                        title={t('returnForm.entranceNumber')}
                    />
                  </Box>
                </Column>
                <Column phone={2}>
                  <Box
                      mb={8}
                      pr={4}
                  >
                    <TextInputContainer
                        control={control}
                        name="address.floor"
                        title={t('returnForm.floor')}
                    />
                  </Box>
                </Column>
                <Column phone={3}>
                  <Box mb={8}>
                    <TextInputContainer
                        control={control}
                        name="address.intercom"
                        title={t('returnForm.intercom')}
                    />
                  </Box>
                </Column>
              </Row>
              <Row>
                <Column phone={12}>
                  <Box mb={5}>
                    <Text type={TextType.H3}>
                      {t('returnForm.fullAddressInEnglish')}
                    </Text>
                  </Box>
                </Column>
              </Row>
              <Row>
                <Column phone={12}>
                  <Box mb={5}>
                    <TextInputContainer
                        control={control}
                        name="addressEnglish.city"
                        title={t('returnForm.city')}
                    />
                  </Box>
                </Column>
              </Row>
              <Row>
                <Column phone={6}>
                  <Box
                      mb={5}
                      pr={4}
                  >
                    <TextInputContainer
                        control={control}
                        name="addressEnglish.street"
                        title={t('returnForm.street')}
                    />
                  </Box>
                </Column>
                <Column phone={3}>
                  <Box
                      mb={5}
                      pr={4}
                  >
                    <TextInputContainer
                        control={control}
                        name="addressEnglish.house"
                        title={t('returnForm.house')}
                    />
                  </Box>
                </Column>
                <Column phone={3}>
                  <Box mb={5}>
                    <TextInputContainer
                        control={control}
                        name="addressEnglish.flat"
                        title={t('returnForm.flatNumber')}
                    />
                  </Box>
                </Column>
              </Row>
              <Row>
                <Column phone={12}>
                  <Box mb={5}>
                    <Text type={TextType.H3}>
                      {t('returnForm.contactPerson')}
                    </Text>
                  </Box>
                </Column>
              </Row>
              <Row>
                <Column phone={12}>
                  <Box mb={5}>
                    <TextInputContainer
                        control={control}
                        name="fullName"
                        title={t('returnForm.name')}
                    />
                  </Box>
                </Column>
              </Row>
              <Row>
                <Column phone={6}>
                  <Box mb={5}>
                    <TextInputContainer
                        control={control}
                        name="phone"
                        title={t('returnForm.phone')}
                        type="phone"
                    />
                  </Box>
                </Column>
              </Row>
              <Row>
                <Column phone={4}>
                  <Box mb={8}>
                    <Switcher
                        isActive={rememberAddress}
                        onSwitch={() => setRememberAddress(!rememberAddress)}
                        text={t('returnForm.rememberAddress')}
                    />
                  </Box>
                </Column>
              </Row>
            </>
        )}
        <Row>
          <Column phone={12}>
            <Box>
              <TextAreaContainer
                  control={control}
                  name="comment"
                  rows={1}
                  title={t('returnForm.comment')}
              />
            </Box>
          </Column>
        </Row>
      </Grid>
  );
};

export default ReturnForm;
