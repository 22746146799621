import styles from './KnowledgeBase.module.scss';
import cn from 'classnames';
import { Loader } from '@components/Loader';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKnowledgeBase } from '@contexts/KnowledgeBaseContext';

const KnowledgeBase: React.FC = (): React.ReactElement => {
  const [iframeHeight, setIframeHeight] = useState<number>(0);
  const [iframeWidth, setIframeWidth] = useState<number>(0);
  const [isBlockVisible, setIsBlockVisible] = useState<boolean>(false);
  const [isIframeVisible, setIsIframeVisible] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  const { knowledgeBaseUrl, setKnowledgeBaseParams } = useKnowledgeBase();
  const { t } = useTranslation();

  useEffect(() => {
    setIsBlockVisible(true);
  }, []);

  useEffect(() => {
    if (isBlockVisible) {
      if (ref.current) {
        setIframeHeight(ref.current!.offsetHeight);
        setIframeWidth(ref.current!.offsetWidth);
        setTimeout(() => {
          setIsIframeVisible(true);
        }, 500);
      }
    }
  }, [isBlockVisible]);

  const handlerOnClose = () => {
    setIsBlockVisible(false);
    setTimeout(() => {
      setKnowledgeBaseParams('');
    }, 500);
  };

  return (
      <div
          className={styles.shadow}
          onClick={handlerOnClose}
      >
        <div
            className={cn(styles.block, isBlockVisible ? styles.blockVisible : styles.blockHidden)}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
            ref={ref}
        >
          {isBlockVisible && (
              <button
                  className={styles.blockClose}
                  onClick={handlerOnClose}
              />
          )}
          {isIframeVisible ? (
              <iframe
                  className={styles.blockIframe}
                  height={iframeHeight}
                  id="knowledgeBase"
                  src={knowledgeBaseUrl}
                  title={t('authenticatedLayout.iframeTitle')}
                  width={iframeWidth}
              >
              </iframe>
          ) : (
              <Loader size="s"/>
          )}
        </div>
      </div>
  );
};

export default KnowledgeBase;