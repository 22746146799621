import { useState, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from "react-i18next";
import { AddSellerPanel } from "@components/AddSeller";
import { Text, TextSize, TextType } from "@components/Text";
import { Modal } from "@components/Modal";
import { Row, Grid } from "@components/Grid";
import Box from "@components/Box";
import RescueTable from "../components/RescueTable";
import DocumentLink from "@components/DocumentLink";
import ParcelModalWindow from "@containers/ParcelModalWindow";
import SavingModalWindow from "../modals/SavingModalWindow";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import { getCountryTariffs, TSavingParcel } from "@api/apiClient/saving";
import { formatSumFromObject } from "@utils/formatValues";
import { getCurrentLanguage, Langs } from "@utils/lang";
import { useProfile } from "@contexts/ProfileContext";
import { useAwaitingCounter } from '@api/apiClient';
import infoIcon from '@assets/icons/info_blue_19.svg';

const instruction = {
  [Langs.ru]: null,
  [Langs.cn]: null,
  [Langs.tr]: {
    fileSize: "1,39 MB",
    url: `/docs/Extra_Small_iptalleri_imhadan_kurtarmak_Talimatı.docx`,
  },
  [Langs.en]: null,
};

const RescueTab = ({
  filters,
  pending,
  handlerSetSavedSelection,
  savedRescueSelection,
  refreshData,
  sellersNotFound,
}: {
  filters: FilterModel[],
  pending: boolean,
  handlerSetSavedSelection: (value: (((prevState: TSavingParcel[]) => TSavingParcel[]) | TSavingParcel[])) => void,
  savedRescueSelection: TSavingParcel[],
  refreshData: () => void,
  sellersNotFound: boolean,
}) => {
  const [detailId, setDetailId] = useState("");
  const [isOpenParcelWindow, setIsOpenParcelWindow] = useState(false);
  const profile = useProfile();

  const closeParcelWindow = () => {
    setIsOpenParcelWindow(false);
  }

  const handleParcelClick = (parcelId: string) => {
    setIsOpenParcelWindow(true)
    setDetailId(parcelId)
  }

  const counter = useAwaitingCounter(!sellersNotFound);
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  const [isSavingOpen, setIsSavingOpen] = useState(false);
  const [selectedSum, setSelectedSum] = useState<number>(0);
  const [savingItems, setSavingItems] = useState<TSavingParcel[] | null>(null);
  const [selectedParcels, setSelectedParcels] = useState<Map<string, TSavingParcel>>(new Map());

  const handleSelectionChange = useCallback((
    selectedIds: string[],
    items: TSavingParcel[],
  ) => {
    const parcels: Map<string, TSavingParcel> = new Map();
    selectedParcels.forEach((parcel, key) => {
      const item = items.find((v) => v.id === key);
      if (!item) parcels.set(key, parcel) // selected items from other pages
    });

    selectedIds.forEach((id) => {
      const item = items.find((v) => (v.id === id));
      if (item) {
        parcels.set(id, item);
      } else {
        // selected items from other pages
        const selectedItem = selectedParcels.get(id);
        if (!selectedItem) {
          console.error(`product with id ${id} not found`);
        } else {
          parcels.set(id, selectedItem);
        }
      }
    })
    setSelectedParcels(parcels);

    const selectedArray = Array.from(parcels, ([_, value]) => value);
    const sum = +selectedArray.reduce((acc, item) => (acc + item.estimatedPrice.amount), 0).toFixed(2);
    handlerSetSavedSelection(selectedArray);

    setSelectedSum(sum);
  }, [selectedParcels]);

  const { data } = useQuery({
    queryKey: ["getCountryTariffs"],
    queryFn: () => getCountryTariffs(),
    retry: 1,
  });

  const savingCostData: { amount: number, currency: string } = useMemo(() => {
    if (!data) return { amount: 0, currency: "" };
    const tarif = data.countryTariffs.find((item) => (
      (item.operationType === "Saving")
      && (item.country === profile.account?.region)
    ));
    if (!tarif) return { amount: 0, currency: "" };
    return tarif.cost;
  }, [data, profile.account?.region]);

  return (
    <div>
      <Grid>
        <Row>
          <Box textAlign="left" pb={7}>
            <Row gap={6}>
              {instruction[currentLanguage] ? (
                <DocumentLink
                  icon={infoIcon}
                  text={t('rescuePage.instructionLink')}
                  {...(instruction[currentLanguage] || { url: "", fileSize: "" })}
                />
              ) : null}
            </Row>
          </Box>
        </Row>
      </Grid>
      {sellersNotFound ? (
        <AddSellerPanel
          isFirstSeller
          onSuccess={() => {
            refreshData();
            handlerSetSavedSelection([]);
            setSelectedParcels(new Map());
            counter.refetch();
          }}
        />
      ) : (
          <>
            <div style={{ marginBottom: "20px" }} >
              <Text size={TextSize.M} type={TextType.BLOCK}>
                {t("rescuePage.rescueDescription", {
                  savingCost: (currentLanguage === Langs.cn)
                    ? (savingCostData?.amount ?? "")
                    : formatSumFromObject(savingCostData),
                })}
              </Text>
            </div>
            <RescueTable
                key="RescueTable"
                onParcelClick={handleParcelClick}
                onClickSave={(data) => {
                  setIsSavingOpen(true);
                  setSavingItems(data);
                }}
                onSelectionChange={handleSelectionChange}
                selectedRowsData={savedRescueSelection}
                selectedSum={selectedSum}
                pending={pending}
                filters={filters}
            />
          </>
      )}

      <Modal
        isOpen={isSavingOpen}
        onClose={() => {
          setIsSavingOpen(false);
        }}
        onClickOverlay={() => {
          setIsSavingOpen(false);
        }}
      >
        <SavingModalWindow
          parcels={savingItems || []}
          success={() => {
            handlerSetSavedSelection([]);
            refreshData();
            setSelectedParcels(new Map());
            setSavingItems(null);
            // setIsSavingOpen(false);
            counter.refetch();
          }}
          close={() => {
            setIsSavingOpen(false);
          }}
        />
      </Modal>

      {isOpenParcelWindow ? (
        <Modal
          isOpen={isOpenParcelWindow}
          onClose={closeParcelWindow}
          onClickOverlay={closeParcelWindow}
          width={900}
          bodyWithoutIndentations
        >
          <ParcelModalWindow
            parcelId={detailId}
          />
        </Modal>
      ) : null}
    </div>
  );
};

export default RescueTab;
