import styles from './ReturnOrder.module.scss';
import InfoIcon from '@assets/icons/info-grey-18.svg';
import OrderListItem from '../OrderListItem/OrderListItem';
import WalletIcon from '@assets/icons/wallet.svg';
import WalletPinkIcon from '@assets/icons/wallet-pink-16.svg';
import { formatSumWithCurrency } from '@utils/formatValues';
import { IReturnOrderProps } from '../../types';
import { TAwaitingParcel } from '@api/apiClient/returns';
import { Text, TextColor, TextSize, TextWeight } from '@components/Text';
import { Tooltip } from '@components/Tooltip';
import { useProfile } from '@contexts/ProfileContext';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ReturnOrder: React.FC<IReturnOrderProps> = (props: IReturnOrderProps): React.ReactElement => {
  const {
    address,
    delivery,
    fullName,
    handlerSetCurrentTypeModalWindow,
    parcels,
    phone
  } = props;

  const [numberOfVisibleItems, setNumberOfVisibleItems] = useState<number>(8);

  const { account } = useProfile();
  const { t } = useTranslation();

  const variableCurrency: string = account?.region === 'TR' ? '$' : '¥';

  return (
      <div className={styles.order}>
        <div className={styles.info}>
          <div className={styles.point}>
            <Text
                color={TextColor.PRIMARY}
                size={TextSize.L}
                weight={TextWeight.BOLD}
            >
              {t('returnOrder.point1')}
            </Text>
            <div className={styles.pointList}>
              <div className={styles.pointListItem}>
                <Text
                    color={TextColor.PRIMARY}
                    size={TextSize.XS}
                    weight={TextWeight.MEDIUM}
                >
                  {t('returnOrder.pointListItem1')}
                </Text>
                <div className={styles.pointListItemValue}>
                  <Text
                      color={TextColor.PRIMARY}
                      size={TextSize.L}
                      weight={TextWeight.BOLD}
                  >
                    {formatSumWithCurrency(delivery, variableCurrency)}
                  </Text>
                  <Tooltip
                      action={
                        <img
                            className={styles.pointListItemValueIcon}
                            alt="Wallet"
                            src={InfoIcon}
                        />
                      }
                      left={16}
                      text={t('returnOrder.pointListItemTooltip')}
                      top={24}
                  />
                </div>
              </div>
              <div className={styles.pointListItem}>
                <Text
                    color={TextColor.PRIMARY}
                    size={TextSize.XS}
                    weight={TextWeight.MEDIUM}
                >
                  {t('returnOrder.pointListItem2')}
                </Text>
                <div className={styles.pointListItemValue}>
                  <img
                      className={styles.pointListItemValueIcon}
                      alt="Wallet"
                      src={account!.balance < delivery ? WalletPinkIcon : WalletIcon}
                  />
                  <Text
                      color={account!.balance < delivery ? TextColor.WARM_PINK : TextColor.PRIMARY}
                      size={TextSize.L}
                      weight={TextWeight.BOLD}
                  >
                    {formatSumWithCurrency(account!.balance, variableCurrency)}
                  </Text>
                </div>
              </div>
              {account!.balance < delivery && (
                  <div className={styles.pointListItem}>
                    <Text
                        color={TextColor.PRIMARY}
                        size={TextSize.XS}
                        weight={TextWeight.MEDIUM}
                    >
                      {t('returnOrder.pointListItem3')}
                    </Text>
                    <div className={styles.pointListItemValue}>
                      <Text
                          color={TextColor.PRIMARY}
                          size={TextSize.L}
                          weight={TextWeight.BOLD}
                      >
                        {formatSumWithCurrency(delivery - account!.balance, variableCurrency)}
                      </Text>
                    </div>
                  </div>
              )}
            </div>
          </div>
          <div className={styles.point}>
            <div className={styles.pointTitle}>
              <Text
                  color={TextColor.PRIMARY}
                  size={TextSize.L}
                  weight={TextWeight.BOLD}
              >
                {t('returnOrder.point2')}
              </Text>
              <button
                  className={styles.back}
                  onClick={() => handlerSetCurrentTypeModalWindow('form')}
              >
                {t('returnOrder.change')}
              </button>
            </div>
            <div className={styles.pointDescription}>
              <Text
                  color={TextColor.PRIMARY}
                  size={TextSize.S}
              >
                {address}
              </Text>
              <Text
                  color={TextColor.DARK_MUTED}
                  size={TextSize.S}
              >
                {fullName}: {phone}
              </Text>
            </div>
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.tab}>
            <Text
                color={TextColor.BLUE}
                size={TextSize.M}
                weight={TextWeight.MEDIUM}
            >
              {t('returnOrder.infoTab')}
            </Text>
            <div className={styles.tabCounter}>
              <Text
                  color={TextColor.WHITE}
                  size={TextSize.S}
                  weight={TextWeight.MEDIUM}
              >
                {parcels.size}
              </Text>
            </div>
          </div>
          <ul className={styles.infoList}>
            {Array.from(parcels.values()).map((item: TAwaitingParcel, index: number) => {
              if (parcels.size === numberOfVisibleItems || index < numberOfVisibleItems) {
                return (
                    <OrderListItem
                        item={item}
                        key={index}
                    />
                );
              }
            })}
          </ul>
          {parcels.size > numberOfVisibleItems && (
              <button
                  className={styles.infoAction}
                  onClick={() => setNumberOfVisibleItems(parcels.size)}
              >
                {`+ ${t('returnOrder.more')} ${(parcels.size - numberOfVisibleItems)}`}
              </button>
          )}
        </div>
      </div>
  );
};

export default ReturnOrder;