import { differenceInCalendarDays, addDays } from 'date-fns';

export const getProgress = (startDate: Date, intervalDays: number): number => {
  const start = new Date(startDate);
  const end = new Date(start.getTime() + intervalDays * 24 * 60 * 60 * 1000);

  const now = new Date();
  if (now > end) {
    return 0;
  }
  const elapsed = now.getTime() - start.getTime();
  const total = end.getTime() - start.getTime();
  return (elapsed / total) * 100;
};

export const getProgressTo = (endDate: Date, intervalDays: number): number => {
  const end = new Date(endDate);
  const start = addDays(end, -1 * intervalDays);

  const now = new Date();
  if (now < start) {
    return 0;
  }
  if (now > end) {
    return 100;
  }
  const elapsed = now.getTime() - start.getTime();
  const total = end.getTime() - start.getTime();
  return (elapsed / total) * 100;
};

export const getProgressToInDays = (endDate: Date, intervalDays: number): number => {
  const end = new Date(endDate);
  const start = addDays(end, -1 * intervalDays);

  const now = new Date();
  if (now < start) {
    return 0;
  }
  if (now > end) {
    return 100;
  }
  const elapsed = differenceInCalendarDays(now, start);
  const total = differenceInCalendarDays(end, start);
  return (elapsed / total) * 100;
};

export const getProgressFromTo = (startDate: Date, endDate: Date, rtl?: boolean): number => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const now = new Date();

  if (now > end) {
    return rtl ? 0 : 100;
  }
  if (now < start) {
    return rtl ? 100 : 0;
  }
  const elapsed = rtl ? (end.getTime() - now.getTime()) : (now.getTime() - start.getTime());
  const total = end.getTime() - start.getTime();
  return (elapsed / total) * 100;
};

export const getDaysBetweenDates = (startDate: Date | null, endDate?: Date): number => {
  const start = startDate ? new Date(startDate) : new Date();
  const end = endDate ? new Date(endDate) : new Date();

  return differenceInCalendarDays(end, start) ?? 0;
};

export const getRestDays = (startDate: Date, intervalDays: number): number => {
  const start = startDate ? new Date(startDate) : new Date();
  const end = addDays(start, intervalDays);

  return differenceInCalendarDays(end, new Date()) ?? 0;
};
