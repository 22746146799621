import { useState, useMemo, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import cn from "classnames";
// import { useQuery } from "react-query";
import { useProfile } from "@contexts/ProfileContext";
import { useNavigate } from 'react-router-dom';
import Page from "@components/Page";
import Avatar from "@components/Avatar";
import { Row, Column } from "@components/Grid";
import Box from "@components/Box";
import { getComplaintRequests, ComplaintRequest } from "@api/apiClient/complaints";
import CreateRequestModal from "./components/CreateRequestModal";
import { TableColumn, TableColumnType } from "@components/Table/TableTypes";
import DataTable, { DataViewHandle } from "@containers/DataTable";
import { Filters, FilterTypes } from "@containers/Filters";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import { formatDate } from "@utils/formatValues";
import IconUni from "@assets/icons/uni.svg";
import FilterIcon from "@assets/icons/filter.svg";
import styles from "./ComplaintList.module.css";

const ComplaintList = () => {
  const dataRef = useRef<DataViewHandle>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [isOpenCreatModal, setIsOpenCreatModal] = useState(false);
  const [filters, setFilters] = useState<FilterModel[]>([]);
  const currentLanguage = i18n.language;

  const handleClick = useCallback((row: ComplaintRequest) => {
    navigate(`/complaints/${row.id}`);
  }, []);

  const profile = useProfile();
  const profileName = profile?.account?.name ?? "";

  const columns: TableColumn<ComplaintRequest>[] = useMemo(() => [
    {
      header: t("complaintPage.number"),
      accessor: (row) => (row.data.id),
      type: TableColumnType.TERTIARY,
      alignment: "left",
      width: "80px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("complaintPage.status"),
      accessor: (row) => (
        <div className={cn(
          styles.status,
          styles[`status${row.data.status}`],
        )}>
          {t(`complaintPage.statuses.${row.data.status}`)}
        </div>
      ),
      type: TableColumnType.SECONDARY,
      alignment: "left",
      width: "100px",
      fixWidth: true,
    },
    {
      header: t("complaintPage.type"),
      accessor: (row) => (
        t(`complaintPage.types.${row.data.reason}`)
      ),
      type: TableColumnType.SECONDARY,
      alignment: "left",
      width: "120px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("complaintPage.lastMessage"),
      accessor: (row) => {
        if (!row.data.lastComment?.text && !row.data.lastComment?.attachments?.length) {
          return "";
        }

        const { lastComment, unreadCommentsCount: unreadCount } = row.data;
        return (
          <Row noWrap align="center">
            {lastComment?.managerLogin ? (
              <img className={styles.avatar} src={IconUni} alt="" />
            ) : (
              <Avatar
                className={styles.avatar}
                name={profileName}
                id={profileName}
              />
            )}
            <span className={cn(
              styles.lastMessageText,
              unreadCount && styles.lastMessageTextActive,
            )}>
              {(
                !lastComment?.text
                && lastComment?.attachments?.length
              ) ? t(lastComment?.managerLogin
                ? "complaintPage.attachmentsReceived"
                : "complaintPage.attachmentsSent"
              ) : lastComment?.text}
            </span>

            {unreadCount ? (
              <div className={styles.messagesCounter}>
                {unreadCount}
              </div>
            ) : null}
          </Row>
        )
      },
      type: TableColumnType.SECONDARY,
      alignment: "left",
      fixWidth: true,
      width: "250px",
    },
    {
      header: t("complaintPage.trackNumbers"),
      accessor: (row) => (
        row.data.trackingNumbers.join(", ")
      ),
      type: TableColumnType.SECONDARY,
      alignment: "left",
      width: "250px",
      // fixWidth: true,
    },
    {
      header: t("complaintPage.updateDate"),
      accessor: (row) => (
        row.data.lastUpdated
          ? formatDate(row.data.lastUpdated, "dd.MMM HH:mm")
          : ""
      ),
      type: TableColumnType.TERTIARY,
      alignment: "right",
      width: "120px",
      // fixWidth: true,
    },
  ], [currentLanguage, profileName]);

  const onCloseModal = () => {
    if (dataRef.current?.refresh) {
      dataRef.current.refresh();
    }
    setIsOpenCreatModal(false);
  }

  return (
    <Page
      title={t("complaintPage.title")}
      actions={(
        <Button
          onClick={() => setIsOpenCreatModal(true)}
          title={`+ ${t("complaintPage.createRequest")}`}
        />
      )}
    >
      <div className={styles.filters}>
        <Button
          className={cn(isFilterOpen ? styles.filterBtnActive : styles.filterBtnInactive)}
          icon={FilterIcon}
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          size={ButtonSize.S}
          type={ButtonType.ROUNDED}
          title={t("filters.filters")}
        />
      </div>

      <Box pb={5} className={cn(!isFilterOpen && styles.hidden)}>
        <Column phone={12}>
          <Row justify="right" gap={5}>
            <Filters
              id="parcels-new"
              openFilters={() => setIsFilterOpen(true)}
              items={[
                {
                  name: "TrackNumbers",
                  type: FilterTypes.MULTI_SEARCH,
                  placeholder: t("parcelsPage.trackNumberSearch"),
                },
              ]}
              onChange={(v, isReady) => {
                if (isReady) {
                  // setIsInitialized(true);
                }
                setFilters(v)
              }}
              values={filters}
              asPanel
            />
          </Row>
        </Column>
      </Box>

      <DataTable<ComplaintRequest>
        queryKey="getComplaintRequests"
        getData={getComplaintRequests}
        // pending={!isInitialized}
        columns={columns}
        notFound={t("complaintPage.noRequest")}
        filters={filters}
        defaultPageSize={10}
        dataRef={dataRef}
        rowOnClick={handleClick}
      />

      {isOpenCreatModal ? (
        <CreateRequestModal onClose={onCloseModal} />
      ) : null}
    </Page>
  )
};

export default ComplaintList;
