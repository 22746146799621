import { createContext, useContext } from "react";

interface RouterContextType {
  prevPath: string;
  currentPath: string;
  currentSearch: string,
  prevSearch: string,
}

const RouterContext = createContext<RouterContextType | null>(null);

export const useRouter = (): RouterContextType => {
  const context = useContext(RouterContext);
  if (!context) {
    throw new Error("useRouter must be used within an RouterProvider");
  }
  return context;
};

export default RouterContext;
export { default as RouterProvider } from "./RouterProvider";
