import styles from './DisposalProcessingModalWindow.module.scss';
import Box from '@components/Box';
import DisposalOrder from '../../components/DisposalOrder/DisposalOrder';
import FormErrorMessage from '@components/FormErrorMessage';
import { Button, ButtonType } from '@components/Button';
import { Column, Grid, Row } from '@components/Grid';
import { IDisposalProcessingModalWindowProps } from '../../types';
import { sendDisposalOrder, TAwaitingParcel } from '@api/apiClient/returns';
import { Text, TextType } from '@components/Text';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

const DisposalProcessingModalWindow: React.FC<IDisposalProcessingModalWindowProps> = (props: IDisposalProcessingModalWindowProps): React.ReactElement => {
  const {
    disposalProcessingCalledFrom,
    handlerOnCloseDisposalProcessing,
    handlerRefreshData,
    parcel,
    selectedParcels,
    selectedRowIds,
    selectedSumCny,
    selectedSumRub
  } = props;

  const { t } = useTranslation();

  const condition: boolean = disposalProcessingCalledFrom === 'card';
  const parcelsMap: Map<string, TAwaitingParcel> = new Map();
  condition && parcel && parcelsMap.set(parcel!.id, parcel!);

  const parcels: Map<string, TAwaitingParcel> = condition ? parcelsMap : selectedParcels;
  const disposalIds: string[] = condition ? [parcel!.id] : selectedRowIds;

  const {
    error: errorSendDisposalOrder,
    isError: isErrorSendDisposalOrder,
    isLoading: isLoadingSendDisposalOrder,
    mutate: mutateSendDisposalOrder
  } = useMutation({
    mutationFn: sendDisposalOrder,
    onSuccess() {
      handlerOnCloseDisposalProcessing();
      handlerRefreshData();
    }
  });

  return (
      <Grid>
        <Row>
          <Column phone={12}>
            <Box mb={5}>
              <Text type={TextType.H1}>
                {t(parcel ? 'disposalProcessingModalWindow.title1' : 'disposalProcessingModalWindow.title2')}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <DisposalOrder
                parcels={parcels}
                selectedSumCny={selectedSumCny}
                selectedSumRub={selectedSumRub}
            />
          </Column>
        </Row>
        <div className={styles.actions}>
          <div>
            <Button
                disabled={isLoadingSendDisposalOrder}
                onClick={handlerOnCloseDisposalProcessing}
                title={t('disposalProcessingModalWindow.cancel')}
                type={ButtonType.SKELETON}
            />
          </div>
          <div>
            <Button
                onClick={() => mutateSendDisposalOrder({ customerReturnIds: disposalIds })}
                pending={isLoadingSendDisposalOrder}
                title={t('disposalProcessingModalWindow.dispose')}
                type={ButtonType.BLOCK}
            />
          </div>
        </div>
        {isErrorSendDisposalOrder && <FormErrorMessage error={errorSendDisposalOrder}/>}
      </Grid>
  );
};

export default DisposalProcessingModalWindow;
