import { useState, useCallback, ReactElement } from 'react';
import { useMutation } from 'react-query';
import { Trans, useTranslation } from "react-i18next";
import { getCurrentLanguage, Langs } from "@utils/lang";
import { Return, setReturnsType, useAwaitingCounter } from '@api/apiClient';
import { DeliveryInfo } from "@api/apiClient/types";
import { useProfile } from "../../../contexts/ProfileContext";
import boxIcon from '@assets/icons/box_blue_19.svg';
import infoIcon from '@assets/icons/info_blue_19.svg';
import { AddSellerPanel } from "@components/AddSeller";
import NoItems from "@components/NoItems";
import Box from "@components/Box/Box";
import { Text, TextSize, TextType, TextWeight } from "@components/Text";
import { Grid, Row } from "@components/Grid";
import { Button, ButtonType } from "@components/Button";
import { Modal } from "@components/Modal";
import HardConfirmPhoneModalWindow from "@components/modals/HardConfirmPhoneModalWindow";
import DocumentLink from "@components/DocumentLink";
import ParcelModalWindow from "@containers/ParcelModalWindow";
import AwaitingTable from "../components/AwaitingTable";
import DisposalModalWindow from "../modals/DisposalModalWindow";
import ReturnModalWindow from "@components/modals/ReturnModalWindow";
import ResaleModalWindow from "../modals/ResaleModalWindow";
import FpbInstruction from "../modals/FpbInstruction"
import WarningIcon from "@assets/icons/warning.svg";
import { FilterModel } from "@hooks/useFiltersSearchParams";

const urlPrefix = "https://unitrade.fra1.digitaloceanspaces.com/public/";

interface LinkData {
  fileSize: string;
  url: string;
}

const prohibited: Record<Langs, LinkData> = {
  [Langs.en]: {
    fileSize: "28,6 KB",
    url: `${urlPrefix}sellers/List-of-goods-prohibited-for-return-to-China.xlsx`,
  },
  [Langs.cn]: {
    fileSize: "28,4 KB",
    url: `${urlPrefix}sellers/list-ch.xlsx`,
  },
  [Langs.ru]: {
    fileSize: "40,9 KB",
    url: `${urlPrefix}sellers/list-restrict.docx`,
  },
  [Langs.tr]: {
    fileSize: "63,5 KB",
    url: "https://storage.yandexcloud.net/unitrade-public/public/zapret.odt",
  },
};

const instruction: Record<Langs, LinkData | null> = {
  [Langs.tr]: {
    fileSize: "2,69 MB",
    url: `${urlPrefix}pdf/seller-instruction_TR.pdf`,
  },
  [Langs.cn]: {
    fileSize: "2,5 MB",
    url: `${urlPrefix}pdf/seller-instruction_CN2.pdf`,
  },
  [Langs.en]: null,
  [Langs.ru]: null,
};

const AwaitingTab = ({
  filters,
  pending,
  downloadingBtn,
  handlerSetSavedAwaitingSelection,
  savedAwaitingSelection,
  refreshData,
  sellersNotFound,
  noData,
}: {
  filters: FilterModel[],
  pending: boolean,
  downloadingBtn: ReactElement,
  handlerSetSavedAwaitingSelection: (value: (((prevState: string[]) => string[]) | string[])) => void,
  savedAwaitingSelection: string[],
  refreshData: () => void,
  sellersNotFound: boolean,
  noData: boolean,
}) => {
  const [detailId, setDetailId] = useState("");
  const [isOpenParcelWindow, setIsOpenParcelWindow] = useState(false);
  const [isFpbInstructionOpen, setFpbInstructionOpen] = useState(false);

  const closeParcelWindow = () => {
    setIsOpenParcelWindow(false);
  }

  const handleParcelClick = (parcelId: string) => {
    setIsOpenParcelWindow(true)
    setDetailId(parcelId)
  }

  const closeFpbInstruction = () => setFpbInstructionOpen(false);
  const profile = useProfile();
  const counter = useAwaitingCounter(!sellersNotFound);
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [selectedExtraSmallCount, setSelectedExtraSmallCount] = useState<number>(0);
  const [isSentReturnsOpen, setIsSentReturnsOpen] = useState(false);
  const [isResalesOpen, setIsResalesOpen] = useState(false);
  const [isHardConfirmOpen, setIsHardConfirmOpen] = useState(false);
  const [isRecyclingOpen, setIsRecyclingOpen] = useState(false);
  const [selectedSum, setSelectedSum] = useState<number>(0);
  const [resale, setResale] = useState<Return | null>(null);
  const [selectedDelivery, setSelectedDelivery] = useState<number>(0);
  const [selectedDepartureCount, setSelectedDepartureCount] = useState<number>(0);
  const [selectedParcels, setSelectedParcels] = useState<Map<string, Return>>(new Map());

  const handleSelectionChange = useCallback((
    selectedIds: string[],
    items: Return[],
  ) => {
    handlerSetSavedAwaitingSelection(selectedIds);

    const parcels: Map<string, Return> = new Map();
    selectedParcels.forEach((parcel, key) => {
      const item = items.find((v) => v.id === key);
      if (!item) parcels.set(key, parcel) // selected items from other pages
    });

    selectedIds.forEach((id) => {
      const item = items.find((v) => (v.id === id));
      if (item) {
        parcels.set(id, item);
      } else {
        // selected items from other pages
        const selectedItem = selectedParcels.get(id);
        if (!selectedItem) {
          console.error(`product with id ${id} not found`);
        } else {
          parcels.set(id, selectedItem);
        }
      }
    })
    setSelectedParcels(parcels);

    const selectedArray = Array.from(parcels, ([_, value]) => value);
    const sum = +selectedArray.reduce((acc, item) => (acc + item.declaredValue.amount), 0).toFixed(2);
    const delivery = +selectedArray.reduce((acc, item) => (acc + (item.isDeparture ? 95 : 10)), 0).toFixed(2);

    setSelectedSum(sum);
    setSelectedDelivery(delivery);
    if (profile.account?.region !== "TR") {
      setSelectedExtraSmallCount(selectedArray.filter((item: Return) => item.isExtraSmall).length);
    }
    setSelectedDepartureCount(selectedArray.filter((item: Return) => item.isDeparture).length);
  }, [selectedParcels]);

  const mutation = useMutation({
    mutationFn: (data: {
      parcelIds: string[],
      deliveryInfo: DeliveryInfo,
      comment: string,
      selectedAddressId: string,
    }) => setReturnsType({
      selectedAddressId: data.selectedAddressId,
      returnType: 3,
      comment: data.comment,
      deliveryInfo: data.deliveryInfo,
      parcelIds: data.parcelIds,
    }),
    onSuccess: () => {
      handlerSetSavedAwaitingSelection([]);
      refreshData();
      setSelectedParcels(new Map());
      setIsSentReturnsOpen(false);
      counter.refetch();
    },
  });

  return (
    <div>
      <Grid>
        <Row>
          <Box textAlign="left" pb={currentLanguage !== Langs.cn ? 7 : 0}>
            <Row gap={6}>
              <DocumentLink
                icon={infoIcon}
                text={t('awaitingPage.downloadInstruction')}
                {...(instruction[currentLanguage] || instruction[Langs.cn]) as LinkData}
              />
              <DocumentLink
                icon={boxIcon}
                text={t('awaitingPage.itemsProhibitedReturn')}
                {...(prohibited[currentLanguage])}
              />
              {(profile?.account?.region &&
                (profile?.account?.region !== "TR")) ? (
                <Button
                  type={ButtonType.LINK}
                  onClick={() => setFpbInstructionOpen(true)}
                  icon={WarningIcon}
                  title={(
                    <Text weight={TextWeight.MEDIUM} size={TextSize.L}>
                      {t("fbpInstruction.link")}
                    </Text>
                  )}
                />
              ) : null}
            </Row>
          </Box>
        </Row>
        {currentLanguage === Langs.cn && (
            <Row>
              <Text size={TextSize.M} type={TextType.P}>
                {t('awaitingPage.instructionClue')}
              </Text>
            </Row>
        )}
      </Grid>

      {sellersNotFound ? (
        <AddSellerPanel
          isFirstSeller
          onSuccess={() => {
            refreshData();
            handlerSetSavedAwaitingSelection([]);
            setSelectedParcels(new Map());
            counter.refetch();
          }}
        />
      ) : (
        noData ? (
          <NoItems
            title={t("noItems.returns.title")}
            description={t("noItems.returns.description")}
          />
        ) : (
            <>
              <div
                  style={{
                    marginBottom: "20px",
                  }}
              >
                <Text size={TextSize.M} type={TextType.BLOCK}>
                  {t("awaitingPage.await_description1")}
                </Text>
                <Text size={TextSize.M} type={TextType.BLOCK}>
                  <Trans i18nKey="awaitingPage.await_description2" />
                </Text>
                <Text size={TextSize.M} type={TextType.P}>
                  {t(currentLanguage === Langs.tr
                      ? "awaitingPage.await_description_turk_3"
                      : "awaitingPage.await_description_chine_3"
                  )}
                </Text>
                <Text size={TextSize.M} type={TextType.BLOCK}>
                  {t("awaitingPage.await_description4")}
                </Text>
              </div>
              <AwaitingTable
                  key="AwaitingTable"
                  onParcelClick={handleParcelClick}
                  onClickDeliver={() => {
                    setIsSentReturnsOpen(true);
                  }}
                  onClickDisposal={() => {
                    setIsRecyclingOpen(true);
                  }}
                  onClickResale={(resale) => {
                    setIsResalesOpen(true);
                    setResale(resale);
                  }}
                  onClickPhoneConfirm={() => {
                    setIsHardConfirmOpen(true);
                  }}
                  onSelectionChange={handleSelectionChange}
                  selectedExtraSmallCount={selectedExtraSmallCount}
                  selectedRowIds={savedAwaitingSelection}
                  selectedSum={selectedSum}
                  pending={pending}
                  filters={filters}
                  downloadingBtn={downloadingBtn}
                  selectedDelivery={selectedDelivery}
                  selectedDepartureCount={selectedDepartureCount}
              />
            </>
      ))}

      {/* Утилизация */}
      <Modal
        isOpen={isRecyclingOpen}
        onClose={() => {
          setIsRecyclingOpen(false);
        }}
        onClickOverlay={() => {
          setIsRecyclingOpen(false);
        }}
      >
        <DisposalModalWindow
          parcelIds={savedAwaitingSelection}
          success={() => {
            handlerSetSavedAwaitingSelection([]);
            refreshData();
            setSelectedParcels(new Map());
            setIsRecyclingOpen(false);
            counter.refetch();
          }}
          close={() => {
            setIsRecyclingOpen(false);
          }}
        />
      </Modal>

      {/* Возвраты */}
      <Modal
        isOpen={isSentReturnsOpen}
        onClose={() => {
          setIsSentReturnsOpen(false);
        }}
        onClickOverlay={() => {
          setIsSentReturnsOpen(false);
        }}
      >
        <ReturnModalWindow
          onClose={() => {
            setIsRecyclingOpen(false);
          }}
          parcelIds={savedAwaitingSelection}
          mutation={mutation}
          DeliverySum={selectedDelivery}
          selectedDepartureCount={selectedDepartureCount}
          Sum={selectedSum}
        />
      </Modal>

      {/* Препродажи */}
      <Modal
        isOpen={isResalesOpen}
        onClose={() => {
          setIsResalesOpen(false);
        }}
        onClickOverlay={() => {
          setIsResalesOpen(false);
        }}
      >
        <ResaleModalWindow
          resale={resale}
          onSuccess={() => {
            handlerSetSavedAwaitingSelection([]);
            refreshData();
            setSelectedParcels(new Map());
            setIsResalesOpen(false);
            counter.refetch();
          }}
        />
      </Modal>

      {/* Подтверждение телефона */}
      <Modal
        isOpen={isHardConfirmOpen}
        onClose={() => {
          setIsHardConfirmOpen(false);
        }}
        onClickOverlay={() => {
          setIsHardConfirmOpen(false);
        }}
      >
        <HardConfirmPhoneModalWindow
          onSuccess={() => {
            profile.get();
            setIsHardConfirmOpen(false);
          }}
          text={t("hardConfirmPhoneModalWindows.textResale")}
          isEditable
        />
      </Modal>

      <Modal
        isOpen={isFpbInstructionOpen}
        onClose={closeFpbInstruction}
        onClickOverlay={closeFpbInstruction}
        width={567}
      >
        <FpbInstruction onClose={closeFpbInstruction} />
      </Modal>

      {isOpenParcelWindow ? (
        <Modal
          isOpen={isOpenParcelWindow}
          onClose={closeParcelWindow}
          onClickOverlay={closeParcelWindow}
          width={900}
          bodyWithoutIndentations
        >
          <ParcelModalWindow
            parcelId={detailId}
          />
        </Modal>
      ) : null}
    </div>
  );
};

export default AwaitingTab;
