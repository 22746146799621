import "./TextInput.css";
import { Ref, useState } from "react";
import classNames from "classnames";
import YClose from "../../assets/icons/ay_close.png";
import YOpen from "../../assets/icons/ay_open.png";
import "react-phone-number-input/style.css";
import CountrySelect from "./CountrySelect";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import ru from "react-phone-number-input/locale/ru";
import en from "react-phone-number-input/locale/en";
import tr from "react-phone-number-input/locale/tr";
import cn from "./cn.json";
import { useProfile } from '@contexts/ProfileContext';

interface TextInputProps {
  value?: string;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  hasError?: boolean;
  errorMessage?: string;
  name?: string;
  ref?: Ref<HTMLInputElement>;
  title: string;
  type?: "text" | "password" | "phone" | "email" | "number";
  disabled?: boolean;
  maxLength?: number;
  min?: number;
}

export const preparePhoneString = (s: string) => (
  s[0] === "+" ? s : (s && `+${s}`)
);

const TextInput = (props: TextInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const isFilled = props.value && props.value.length > 0;
  const isPhone = props.type === "phone"

  const { i18n } = useTranslation()
  const { account } = useProfile();
  const lang = i18n.language.toLocaleUpperCase();
  const locale = {
    EN: en,
    RU: ru,
    TR: tr,
    CN: cn,
  }[lang] || en

  const handleFocus = () => {
    setIsFocused(true);
    if (props.onFocus) {
      props.onFocus();
    }
  };
  const handleBlur = () => {
    setIsFocused(false);
    if (props.onBlur) {
      props.onBlur();
    }
  };

  return (
    <div className="text-input-container">
      <div
        className={classNames("text-input", {
          "text-input--active": isFocused || isFilled || isPhone,
          "text-input--error": props.hasError,
          "text-input--focus": isFocused,
          "text-input--disabled": props.disabled,
        })}
      >
        <label className="text-input__label">
          <span className="text-input__title">{props.title}</span>
          <div className="text-input__wrapper">
            {isPhone ? (
              <PhoneInput
                className="text-input__input"
                international
                countryOptionsOrder={["RU", "CN", "TR", "|", "..."]}
                labels={locale}
                addInternationalOption={false}
                limitMaxLength
                value={preparePhoneString(props.value || "")}
                countrySelectComponent={CountrySelect}
                disabled={props.disabled}
                onChange={(value) => {
                  if (props.onChange) {
                    props.onChange(value || "");
                  }
                }}
              />
            ) : (
              <input
                ref={props.ref}
                value={props.value}
                className="text-input__input"
                type={isShowPassword ? "text" : props.type || "text"}
                onFocus={handleFocus}
                onBlur={handleBlur}
                name={props.name}
                onChange={(e) => {
                  if (props.onChange) {
                    props.onChange(e.target.value);
                  }
                }}
                disabled={props.disabled}
                maxLength={props.maxLength}
                min={props.min}
              />
            )}
            {props.name === 'amountRU' && (
                <span className="currency-icon">
                  ₽
                </span>
            )}
            {props.name === 'amount' && (
                <span className="currency-icon">
                  {account?.region === 'CN' && '¥'}
                  {account?.region === 'TR' && '$'}
                </span>
            )}
            {props.type == "password" &&
              (isShowPassword ? (
                <img
                  onClick={() => {
                    setIsShowPassword(false);
                  }}
                  alt="password-icon"
                  className="password-icon"
                  src={YOpen}
                />
              ) : (
                <img
                  onClick={() => {
                    setIsShowPassword(true);
                  }}
                  alt="password-icon"
                  className="password-icon"
                  src={YClose}
                />
              ))}
          </div>
        </label>
      </div>
      {props.hasError && (
        <div className="text-input-error">{props.errorMessage}</div>
      )}
    </div>
  );
};

export default TextInput;
