import { useState } from "react";
import { useQuery } from "react-query";
import cn from "classnames";
import { getParcels, useHasParcels, } from "@api/apiClient";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import { TabsList, TabsContent } from "@components/Tabs/Tabs.tsx";
import Page from "@components/Page/Page.tsx";
import { AddSellerPanel } from "@components/AddSeller";
import NoItems from "@components/NoItems";
import NewTab from "./tabs/NewTab.tsx";
import { useTranslation } from "react-i18next";
import FilterIcon from "@assets/icons/filter.svg";
import { Modal } from "@components/Modal";
import ParcelModalWindow from "@containers/ParcelModalWindow";
import styles from "./parcelsPage.module.css"
import { useSellersCounter } from '@contexts/SellersCounterContext';

const ParcelsPage = () => {
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("NewTab");
  const [isOpenParcelWindow, setIsOpenParcelWindow] = useState<boolean>(false);
  const [detailId, setDetailId] = useState<string>("");

  const { isFetching, sellersNotFound } = useSellersCounter();
  const { hasNoParcels: noData } = useHasParcels(!sellersNotFound);

  const closeParcelWindow = () => {
    setIsOpenParcelWindow(false);
  }

  const onOpenParcel = (id: string) => {
    setIsOpenParcelWindow(true);
    setDetailId(id);
  }

  const { data: parcelsTotal } = useQuery({
    queryKey: [
      "parcels-page",
      { page: 1, pageSize: 1 },
      [],
    ],
    queryFn: () => getParcels({
      page: { page: 1, pageSize: 1 },
      filters: [],
    }),
    retry: 1,
    select: ({ total }) => total ?? 0,
    enabled: !sellersNotFound
  });

  const tabs = [
    {
      id: "NewTab",
      title: t("parcelsPage.tabAll"),
      content: (
        <NewTab
          pending={isFetching}
          onOpenParcel={onOpenParcel}
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
          sellersNotFound={sellersNotFound}
        />
      ),
      count: parcelsTotal
    },
    // {
    //   id: "InDeliveryTab",
    //   title: t("parcelsPage.tabInDelivery"),
    //   content: <NewTab sellers={sellersFilter} pending={isFetching} />,
    // },
    // {
    //   id: "DelivevredTab",
    //   title: t("parcelsPage.tabDelivevred"),
    //   content: <NewTab sellers={sellersFilter} pending={isFetching} />,
    // },
    // {
    //   id: "CancelledTab",
    //   title: t("parcelsPage.tabCancelled"),
    //   content: <NewTab sellers={sellersFilter} pending={isFetching} />,
    // },
  ];

  return (
    <Page title={t("parcelsPage.title")}>
      <div className={styles.tabsRow}>
        <TabsList
          tabs={tabs}
          onChange={setActiveTab}
          value={activeTab}
        />

        <div className={styles.filters}>
          <Button
            className={cn(isFilterOpen ? styles.filterBtnActive : styles.filterBtnInactive)}
            icon={FilterIcon}
            onClick={() => setIsFilterOpen(!isFilterOpen)}
            size={ButtonSize.S}
            type={ButtonType.ROUNDED}
            title={t("filters.filters")}
          />
        </div>
      </div>

      {sellersNotFound ? (
        <AddSellerPanel isFirstSeller />
      ) : (
        noData ? (
          <NoItems
            title={t("noItems.parcels.title")}
            description={t("noItems.parcels.description")}
          />
        ) : (
          <TabsContent
            tab={tabs.find((tab) => (tab.id === activeTab))}
          />
        )
      )}

      {isOpenParcelWindow ? (
        <Modal
          isOpen={isOpenParcelWindow}
          onClose={closeParcelWindow}
          onClickOverlay={closeParcelWindow}
          width={900}
          bodyWithoutIndentations
        >
          <ParcelModalWindow parcelId={detailId} />
        </Modal>
      ) : null}
    </Page>
  );
};

export default ParcelsPage;
