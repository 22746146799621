import styles from './AuthenticatedLayout.module.css';
import Banner from '@components/Banner';
import BunchIcon from '@assets/icons/link-blue.svg';
import cn from 'classnames';
import ComplaintsIcon from '@assets/icons/support-icon.svg';
import DropshippingIcon from '@assets/icons/icon-otp.svg';
import FulfilmentIcon from '@assets/icons/fulfilment.svg';
import KnowledgeBase from './components/KnowledgeBase/KnowledgeBase';
import KnowledgeBaseIcon from '@assets/icons/question-blue-38.svg';
import LanguageSwitcherContainer from '../../containers/LanguageSwitcherContainer/LanguageSwitcherContainer.tsx';
import Navbar from './components/Navbar/Navbar.tsx';
import NewsIcon from '@assets/icons/fire2.svg';
import ParcelsIcon from '@assets/icons/parcel.svg';
import PickUpIcon from '@assets/icons/truck-blue.svg';
import ReturnsIcon from '@assets/icons/returns-icon.svg';
import UnclaimedIcon from '@assets/icons/unclaimed-blue-30.svg';
import UserWidget from './components/UserWidget/UserWidget.tsx';
import WalletWidget from './components/WalletWidget/WalletWidget.tsx';
import WarningIcon from '@assets/icons/warning-blue.svg';
import { getCurrentLanguage, Langs } from '@utils/lang';
import { getPushes, useNewsCounter } from '@api/apiClient/news';
import { Outlet } from 'react-router-dom';
import { Logo } from '@components/Logo';
import { Portal } from '@components/Portal';
import { Tooltip } from '@components/Tooltip';
import { useAwaitingCounter as useReturnsAwaitingCounter } from '@api/apiClient/returns';
import { useAwaitingCounter as useUnclaimedAwaitingCounter } from '@api/apiClient';
import { useComplaintMessagesCounter } from '@api/apiClient/complaints';
import { useEffect, useState } from 'react';
import { useKnowledgeBase } from '@contexts/KnowledgeBaseContext';
import { useProblematicPageCounter } from '@api/apiClient/problematicParcels';
import { useQuery } from 'react-query';
import { useSellersCounter } from '@contexts/SellersCounterContext';
import { useTranslation } from 'react-i18next';

type TAuthenticatedLayout = {
  region: string;
};

const AuthenticatedLayout = (props: TAuthenticatedLayout) => {
  const { region } = props;

  const [isPushesVisible, setIsPushesVisible] = useState<boolean>(false);

  const currentLanguage = getCurrentLanguage();
  const { isKnowledgeBaseVisible, setKnowledgeBaseParams } = useKnowledgeBase();
  const { sellersNotFound } = useSellersCounter();
  const { t } = useTranslation();

  const { data: awaitingProblematicTotal } = useProblematicPageCounter(!sellersNotFound, 'AwaitingDecision');
  const { data: awaitingReturnsTotal } = useReturnsAwaitingCounter(!sellersNotFound);
  const { data: awaitingUnclaimedTotal } = useUnclaimedAwaitingCounter(!sellersNotFound);
  const { data: complaintsTotal } = useComplaintMessagesCounter(!sellersNotFound);
  const { data: newsTotal } = useNewsCounter(currentLanguage);

  const {
    data: dataGetPushes,
    refetch: refetchGetPushes
  } = useQuery({
    queryFn: () => getPushes(currentLanguage),
    queryKey: ['pushes'],
    retry: 1
  });

  useEffect(() => {
    if (dataGetPushes && dataGetPushes.items.length) {
      setIsPushesVisible(true);
    }
  }, [dataGetPushes]);

  useEffect(() => {
    setIsPushesVisible(false);
    refetchGetPushes();
  }, [currentLanguage]);

  useEffect(() => {
    if (isKnowledgeBaseVisible) {
      document.getElementById('root')?.classList.add('blurred');
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.getElementById('root')?.classList.remove('blurred');
      document.body.style.overflow = 'auto';
    };
  }, [isKnowledgeBaseVisible]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={cn(styles.headerLogo)}>
          <Logo />
        </div>
        <div className={cn(styles.headerActions)}>
          <WalletWidget />
          <LanguageSwitcherContainer />
          <UserWidget />
          {region === 'CN' && currentLanguage === Langs.cn && (
              <Tooltip
                  action={
                    <button
                        className={styles.knowledgeBaseBtn}
                        onClick={() => setKnowledgeBaseParams(`https://unitrade-global.com/instructions-${currentLanguage}`)}
                    >
                      <img
                          className={styles.knowledgeBaseIcon}
                          alt="KnowledgeBaseIcon"
                          src={KnowledgeBaseIcon}
                      />
                    </button>
                  }
                  right={10}
                  text={t('authenticatedLayout.btnTooltip')}
                  top={40}
              />
          )}
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.navigation}>
          <div className={styles.navbar}>
            <Navbar
              menu={[
                // {
                //   icon: Img1,
                //   path: "dashboard",
                //   text: t("navbar.dashboard"),
                // },
                {
                  icon: ParcelsIcon,
                  path: "parcels",
                  text: t("navbar.parcels"),
                  key: "parcels",
                },
                {
                  icon: UnclaimedIcon,
                  path: 'unclaimed',
                  text: t('navbar.unclaimed'),
                  count: awaitingUnclaimedTotal,
                  key: 'unclaimed',
                },
                ...((region === "CN") ? [
                  {
                    icon: WarningIcon,
                    path: "problematic-parcels",
                    text: t("navbar.problematicParcels"),
                    key: "problematic",
                    count: awaitingProblematicTotal,
                  }
                ] : []),
                {
                  icon: ReturnsIcon,
                  path: 'returns',
                  text: t('navbar.returns'),
                  count: awaitingReturnsTotal,
                  key: 'returns'
                },
                // {
                //   icon: Img5,
                //   path: "products",
                //   text: t("navbar.products"),
                //   count: 0,
                //   key: "returns",
                // },
                ...((region === "CN") ? [{
                   icon: DropshippingIcon,
                   path: "labelling",
                   text: t("navbar.dropshipping"),
                   key: "labelling",
                 }] : []),
                 {
                   icon: FulfilmentIcon,
                   path: "fulfillment",
                   text: t("navbar.fulfillment"),
                   key: "fulfillment",
                 },
                 ...((region === "CN") ? [
                   {
                     icon: ComplaintsIcon,
                     path: "complaints",
                     text: t("navbar.complaints"),
                     key: "complaints",
                     count: complaintsTotal,
                   },
                 ] : []),
                // {
                //   icon: Img4,
                //   path: "support",
                //   text: t("navbar.support"),
                //   key: "support",
                // },
                ...((region === "CN") ? [
                  {
                    icon: PickUpIcon,
                    path: "pick-up",
                    text: t("pickUp.title"),
                    key: "pickup",
                  },
                ] : []),
                {
                  icon: BunchIcon,
                  path: "bunch",
                  text: t("bunchPage.title"),
                  key: "bunch",
                },
                {
                  count: newsTotal,
                  icon: NewsIcon,
                  key: 'news',
                  path: 'news',
                  text: t('news.title')
                },
              ]}
            />
          </div>
        </div>
        <div className={styles.content}>
          <Outlet />
        </div>
        {isPushesVisible && (
            <Banner
                bottom={15}
                handlerRefetchGetPushes={refetchGetPushes}
                handlerSetIsPushesVisible={setIsPushesVisible}
                isPositionFixed
                pushes={dataGetPushes!.items}
                right={15}
                withAnimation
            />
        )}
      </div>
      {isKnowledgeBaseVisible && (
          <Portal>
            <KnowledgeBase/>
          </Portal>
      )}
    </div>
  );
};

export default AuthenticatedLayout;
