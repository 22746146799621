import SellerFilter from "@containers/SellerFilter";
import ServiceFilter from "@containers/ServiceFilter";
import DateRangeFilter from "./DateRangeFilter";
import SearchFilter from "./SearchFilter";
import MultiSelectFilter, { OptionType } from "./MultiSelectFilter";
import { FilterTypes } from "./types";
import StatusFilter from "./StatusFilter";
import StateFilter from './StateFilter';

interface FilterProps {
  type: FilterTypes;
  initialValue: string[];
  onChange: (v: string[], rv?: string[]) => void;
  placeholder?: string;
  asPanel?: boolean;
  data?: OptionType[];
}

const Filter = ({
  type,
  initialValue,
  onChange,
  placeholder,
  asPanel,
  data,
}: FilterProps) => {
  if (type === FilterTypes.SELLER) {
    return (
      <SellerFilter
        initialValue={initialValue}
        onChange={onChange}
        asPanel={asPanel}
      />
    );
  }
  if (
    (type === FilterTypes.SERVICE_PICKUP)
    || (type === FilterTypes.SERVICE_PARCELS)
    || (type === FilterTypes.SERVICE_RETURNS)
    || (type === FilterTypes.SERVICE_PROBLEMATIC)
  ) {
    return (
      <ServiceFilter
        initialValue={initialValue}
        onChange={onChange}
        asPanel={asPanel}
        type={type}
      />
    );
  }

  if (type === FilterTypes.DATE_RANGE) {
    return (
      <DateRangeFilter
        initialValue={initialValue}
        onChange={onChange}
        asPanel={asPanel}
        placeholder={placeholder}
      />
    );
  }
  if (type === FilterTypes.SEARCH) {
    return (
      <SearchFilter
        initialValue={initialValue?.[0]}
        onChange={onChange}
        placeholder={placeholder}
        asPanel={asPanel}
      />
    );
  }
  if (type === FilterTypes.MULTI_SEARCH) {
    return (
      <SearchFilter
        initialValue={initialValue?.[0]}
        onChange={onChange}
        placeholder={placeholder}
        multi
        asPanel={asPanel}
      />
    );
  }
  if (type === FilterTypes.MULTI_SEARCH_OR_NAME) {
    return (
      <SearchFilter
        initialValue={initialValue?.[0]}
        onChange={onChange}
        placeholder={placeholder}
        multiOrName
        asPanel={asPanel}
      />
    );
  }
  if (type === FilterTypes.MULTI_SELECT) {
    return (
      <MultiSelectFilter
        onChange={onChange}
        initialValue={initialValue}
        isFetching={false}
        data={data || []}
        placeholder={placeholder}
        asPanel={asPanel}
      />
    );
  }
  if (type === FilterTypes.STATUS) {
    return (
        <StatusFilter
            initialValue={initialValue}
            onChange={onChange}
            asPanel={asPanel}
        />
    );
  }
  if (type === FilterTypes.STATE) {
    return (
        <StateFilter
            initialValue={initialValue}
            onChange={onChange}
            asPanel={asPanel}
        />
    );
  }
  return null;
}

export default Filter;
