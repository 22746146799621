import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import cn from "classnames";
import Box from "@components/Box";
import { Row, Column } from "@components/Grid";
import { AddSellerPanel } from "@components/AddSeller";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import { TabsList, TabsContent } from "@components/Tabs/Tabs.tsx";
import Page from "@components/Page/Page.tsx";
import AwaitingTab from "./tabs/AwaitingTab.tsx";
import ReturnsTab from "./tabs/ReturnsTab.tsx";
import { useTranslation } from "react-i18next";
import { Filters, FilterTypes } from "@containers/Filters";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import { useProblematicPageCounter} from "@api/apiClient/problematicParcels";
import useTab from "@hooks/useTab";
import FilterIcon from "@assets/icons/filter.svg";
import styles from "./ProblematicPage.module.css";
import { useSellersCounter } from '@contexts/SellersCounterContext';

const ProblematicPage = () => {
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<FilterModel[]>([]);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [savedAwaitingSelection, setSavedAwaitingSelection] = useState<string[]>([]);

  const queryClient = useQueryClient();
  const { isFetching, sellersNotFound } = useSellersCounter();

  const { data: awaitingTotal } = useProblematicPageCounter(!sellersNotFound, 'AwaitingDecision');
  const { data: returnTotal } = useProblematicPageCounter(!sellersNotFound, 'Return');
  const { data: disposalTotal } = useProblematicPageCounter(!sellersNotFound, 'Disposal');

  useEffect(() => {
    if (filters[0]?.value.length) {
      setSavedAwaitingSelection([]);
    }
  }, [filters]);

  const refreshData = () => {
    queryClient.invalidateQueries(
      { queryKey: ["getProblematicParcels"] }
    );
  }

  const tabs = [
    {
      id: "awaiting",
      title: t("problematicParcelsPage.tabAwaiting"),
      content: <AwaitingTab
        filters={filters}
        pending={!isInitialized || isFetching}
        handlerSetSavedAwaitingSelection={setSavedAwaitingSelection}
        savedAwaitingSelection={savedAwaitingSelection}
        refreshData={refreshData}
      />,
      count: awaitingTotal ?? 0,
    },
    {
      id: "returns",
      title: t("problematicParcelsPage.tabReturns"),
      content: <ReturnsTab
        filters={filters}
        pending={!isInitialized || isFetching}
        status="Return"
        refreshData={refreshData}
      />,
      count: returnTotal ?? 0,
      disable: sellersNotFound,
    },
    {
      id: "disposals",
      title: t("problematicParcelsPage.tabDisposal"),
      content: <ReturnsTab
        filters={filters}
        pending={!isInitialized || isFetching}
        status="Disposal"
        refreshData={refreshData}
      />,
      count: disposalTotal ?? 0,
      disable: sellersNotFound,
    },
  ];
  const { activeTab, setActiveTab } = useTab(tabs);

  return (
    <Page
        title={t("problematicParcelsPage.title")}
    >
      <div className={styles.tabsRow}>
        <TabsList
          tabs={tabs}
          onChange={setActiveTab}
          value={activeTab || tabs[0].id}
        />

        <div className={styles.filters}>
          <Button
            className={cn(isFilterOpen ? styles.filterBtnActive : styles.filterBtnInactive)}
            icon={FilterIcon}
            onClick={() => setIsFilterOpen(!isFilterOpen)}
            size={ButtonSize.S}
            type={ButtonType.ROUNDED}
            title={t("filters.filters")}
          />
        </div>
      </div>

      {sellersNotFound ? (
        <AddSellerPanel onSuccess={refreshData} isFirstSeller />
      ) : (
        <>
          <Box pb={5} className={cn(!isFilterOpen && styles.hidden)}>
            <Column phone={12}>
              <Row justify="right" gap={5}>
                <Filters
                  key={activeTab}
                  id={`problematicParcels-${activeTab}`}
                  openFilters={() => setIsFilterOpen(true)}
                  items={[
                    {
                      name: "trackOrExternalNumbers",
                      type: FilterTypes.MULTI_SEARCH,
                      placeholder: t("filters.searchByTrackNumber"),
                    },
                    {
                      name: "serviceIds",
                      type: FilterTypes.SERVICE_PROBLEMATIC,
                    },
                    {
                      name: "sellerIds",
                      type: FilterTypes.SELLER,
                    },
                  ]}
                  onChange={(v, isReady) => {
                    if (isReady) {
                      setIsInitialized(true);
                    }
                    setFilters(v);
                  }}
                  values={filters}
                  asPanel
                />
              </Row>
            </Column>
          </Box>
          <TabsContent
            tab={tabs.find((tab) => (tab.id === activeTab))}
          />
        </>
      )}
    </Page>
  );
};

export default ProblematicPage;
