import { useState } from 'react';
import { useQueryClient } from "react-query";
import NameForm from "./NameForm";
import AddShopForm from "./AddShopForm";
import AddShopFormOzon from "./AddShopFormOzon";
import ConfirmEmail from "./ConfirmEmail";
import { Loader } from "@components/Loader";
import { Grid, Row, Column } from "@components/Grid";
import { useSellersCounter } from '@contexts/SellersCounterContext';
import { Seller } from "@api/apiClient";

enum steps {
  EMAIL = "email",
  NAME = "name",
  CONFIRM = "confirm",
}

const AddSeller = ({ onSuccess, isOzonSeller, isFirstSeller }: {
  isFirstSeller?: boolean,
  isOzonSeller?: boolean,
  onSuccess?: (data: Seller[]) => void,
}) => {
  const queryClient = useQueryClient();
  const [step, setStep] = useState(steps.EMAIL);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [ticket, setTicket] = useState("");
  const [apiKey, setApikey] = useState("");
  const [clientId, setClientId] = useState("");
  const { isFetching } = useSellersCounter();

  if (isFetching) {
    return (
      <Grid>
        <Row>
          <Column phone={12}>
            <Loader alignment="center" size="s" />
          </Column>
        </Row>
      </Grid>
    );
  }

  if (step === steps.CONFIRM) {
    return (
      <ConfirmEmail
        onSuccess={(data) => {
          queryClient.invalidateQueries(
            { queryKey: ["sellers"] }
          );
          queryClient.invalidateQueries(
            { queryKey: ["parcels-page"] }
          );
          queryClient.invalidateQueries(
            { queryKey: ["returns-page"] }
          );
          setStep(steps.EMAIL);
          onSuccess && onSuccess(data);
        }}
        setTicketId={(v: string) => setTicket(v)}
        ticketId={ticket}
        email={email}
        name={name}
        apiKey={apiKey}
        clientId={clientId}
      />
    );
  }

  if (step === steps.NAME) {
    return (
      <NameForm
        onSuccess={(name: string) => {
          setStep(steps.CONFIRM);
          setName(name);
        }}
      />
    );
  }

  if (isOzonSeller) {
    return (
      <AddShopFormOzon
        onSuccess={({
          email,
          apiKey,
          clientId,
          ticketId,
          isCompanyFound,
        }) => {
          setEmail(email);
          setApikey(apiKey);
          setClientId(clientId);
          setTicket(ticketId);
          setStep(isCompanyFound ? steps.CONFIRM : steps.NAME);
        }}
      />
    );
  }

  return (
    <AddShopForm
      isFirstSeller={isFirstSeller}
      onSuccess={({
        email,
        ticketId,
        isCompanyFound,
      }) => {
        setEmail(email);
        setTicket(ticketId);
        setStep(isCompanyFound ? steps.CONFIRM : steps.NAME);
      }}
    />
  );
};

export default AddSeller;
