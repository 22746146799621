import styles from './PaymentReturnModalWindow.module.scss';
import Box from '@components/Box';
import ButtonWithTimer, { ButtonWithTimerType } from '@components/ButtonWithTimer/ButtonWithTimer';
import cn from 'classnames';
import FormErrorMessage from '@components/FormErrorMessage';
import moment from 'moment/moment';
import Title from '@components/Title';
import { Button } from '@components/Button';
import { Column, Row } from '@components/Grid';
import { formatSumWithCurrency } from '@utils/formatValues';
import { IPaymentReturnModalWindowProps } from './types';
import { Loader } from '@components/Loader';
import { Text, TextColor, TextSize, TextWeight } from '@components/Text';
import { useProfile } from '@contexts/ProfileContext';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const PaymentReturnModalWindow: React.FC<IPaymentReturnModalWindowProps> = (props: IPaymentReturnModalWindowProps): React.ReactElement => {
  const {
    dataSendTopUpBalance,
    errorGetPaymentLink,
    handlerOnClickPaymentLink,
    isErrorGetPaymentLink,
    isLoadingGetPaymentLink,
    isLoadingSendTopUpBalance,
    mutateSendTopUpBalance,
    parcel,
    paymentReturnError,
    paymentReturnInProcessed,
    returnProcessingCalledFrom,
    selectedDelivery
  } = props;

  const [timer, setTimer] = useState<number>(180);

  const { account } = useProfile();
  const { t } = useTranslation();

  const condition: boolean = returnProcessingCalledFrom === 'card';
  const delivery: number = condition ? parcel!.reexportPrice.amount : selectedDelivery;
  const variableCurrency: string = account?.region === 'TR' ? '$' : '¥';

  const handlerStartTimer = () => {
    let counter = timer;
    const interval = setInterval(() => {
      counter--;
      setTimer(counter);
      if (counter === 0) clearInterval(interval);
    }, 1000);
  };

  return (
      <div className={styles.paymentReturn}>
        <Row>
          <Column phone={12}>
            <Box pb={3}>
              <Title title={`${t('paymentReturnModalWindow.title')} ${formatSumWithCurrency(delivery - account!.balance!, variableCurrency)}`}/>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box pb={8}>
              <Text
                  color={TextColor.PRIMARY}
                  size={TextSize.S}
                  weight={TextWeight.MEDIUM}
              >
                {t('paymentReturnModalWindow.subTitle')}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column phone={12}>
            <Box pb={8}>
              {account!.region === 'TR' ? (
                  <Text
                      className={!paymentReturnInProcessed ? styles.hidden : ''}
                      color={TextColor.PRIMARY}
                      size={TextSize.S}
                      weight={TextWeight.MEDIUM}
                  >
                    {t('paymentReturnModalWindow.expectation') + '... '}
                    <span className={styles.timer}>
                      {moment(timer * 1000).format('mm:ss')}
                    </span>
                  </Text>
              ) : (
                  <div className={styles.qrCode}>
                    {!isLoadingSendTopUpBalance && (
                        <img
                            className={cn(styles.qrCodeImg, paymentReturnInProcessed && styles.qrCodeImgHidden)}
                            alt="qrCode"
                            src={`data:image/svg+xml;base64,${dataSendTopUpBalance?.qrCode}`}
                        />
                    )}
                    {(paymentReturnInProcessed || isLoadingSendTopUpBalance) && (
                        <Loader
                            alignment="center"
                            size="xl"
                        />
                    )}
                  </div>
              )}
            </Box>
          </Column>
        </Row>
        <Row>
          {account!.region === 'TR' ? (
              <Box>
                <Button
                    onClick={() => {
                      handlerOnClickPaymentLink((delivery - account!.balance!).toString());
                      handlerStartTimer();
                    }}
                    pending={isLoadingGetPaymentLink}
                    title={t('paymentReturnModalWindow.paymentLink')}
                />
              </Box>
          ) : (
              <Box>
                {!paymentReturnInProcessed && (
                    <ButtonWithTimer
                        onClick={() => mutateSendTopUpBalance({ amount: delivery - account!.balance })}
                        label={t('paymentReturnModalWindow.buttonUpdate')}
                        startTimerFromOutside={true}
                        timeout={timer}
                        type={ButtonWithTimerType.LINK}
                    />
                )}
              </Box>
          )}
        </Row>
        {paymentReturnError && <FormErrorMessage error={paymentReturnError}/>}
        {isErrorGetPaymentLink && <FormErrorMessage error={errorGetPaymentLink}/>}
      </div>
  );
};

export default PaymentReturnModalWindow;
