import {
  RegisterOptions,
  useController,
  Control,
  FieldValues,
  Path,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input";
import TextInput, { preparePhoneString } from "../components/TextInput/TextInput.tsx";

interface TextInputContainerProps<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  rules?: Omit<
    RegisterOptions<TFieldValues>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  title: string;
  type?: "text" | "password" | "phone" | "email" | "number";
  disabled?: boolean;
  maxLength?: number,
  min?: number
}

const TextInputContainer = <TFieldValues extends FieldValues = FieldValues>(
  props: TextInputContainerProps<TFieldValues>,
) => {
  const { t } = useTranslation();

  const typeRules = {
    email: {
      pattern: {
        value: /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/,
        message: t("defaultFormErrors.email"),
      },
    },
    phone: {
      validate: (value: string) => (
        isValidPhoneNumber(preparePhoneString(value))
        || t("defaultFormErrors.phone")
      ),
    },
    text: {},
    password: {},
    number: {}
  }

  const { field, fieldState } = useController<TFieldValues>({
    control: props.control,
    name: props.name,
    rules: {
      ...(typeRules[props.type ?? "text"] || {}),
      ...props.rules,
    },
  });

  const maxLength = props.maxLength ?? 50;

  return (
    <TextInput
      value={field.value}
      onChange={field.onChange}
      name={field.name}
      title={props.title}
      errorMessage={fieldState.error?.message}
      hasError={!!fieldState.error}
      type={props.type}
      disabled={props.disabled}
      maxLength={maxLength}
      min={props.min}
    />
  );
};

export default TextInputContainer;
