import { useQuery } from 'react-query';
import { getParams, sellerApiClient } from '@api/apiClient/sellerApiClient';
import { ResponsePages, RequestParams } from "./types";
import { PaginationModel } from '@hooks/usePagingSearchParams';

export type TSavingParcelItem = {
  id: string;
  name: string;
  url: string;
  quantity?: number;
}

export type TSavingParcel = {
  id: string;
  trackNumber: string;
  externalId: string;
  items: TSavingParcelItem[];
  serviceId: string;
  serviceName: string;
  estimatedPrice: {
    amount: number;
    currency: string;
  },
  sellerId: string;
  sellerName: string;
  estimatedUtilizationDate: Date;
  savingType: string;
  rescueAmount: number;
};

export interface TCountryTariffs {
  countryTariffs: {
    country: string;
    operationType: string;
    cost: {
      amount: number;
      currency: string;
    };
  }[];
}

export const getSavings = async (params: RequestParams): Promise<ResponsePages<TSavingParcel>> => {
  const { filters, page } = params;
  const response = await sellerApiClient.get<ResponsePages<TSavingParcel>>('/v1/returns/save-page', {
    params: getParams(page, filters),
    paramsSerializer: { indexes: null },
  });

  return response.data;
};

const defaultParams: PaginationModel = { page: 1, pageSize: 1 };

export const useSavingsCounter = (enabled: boolean = true) => {
  const { data, refetch } = useQuery({
    queryKey: ['getSavings', defaultParams],
    queryFn: () => getSavings({
      filters: [],
      page: defaultParams
    }),
    retry: 1,
    select: ({ total }) => total ?? 0,
    enabled: enabled
  });
  return { data, refetch };
};

export const getCountryTariffs = async (): Promise<TCountryTariffs> => {
  const response = await sellerApiClient.get<TCountryTariffs>('/v1/returns/countryTariffs');

  return response.data;
};

interface TSaveResult {
  parcelIds: string[];
}

export const saveReturns = async ({ parcelIds, aseAmount }: {
  parcelIds: string[],
  aseAmount?: number,
}): Promise<TSaveResult> => {
  const response = await sellerApiClient.post<TSaveResult>(
    "/v1/returns/save",
    {
      parcelIds,
      ...(aseAmount ? { aseAmount } : {})
    },
  );

  return response.data;
};
