import SellersCounterContext from './index.ts';
import { getSellers } from '@api/apiClient';
import { ReactNode, useEffect, useState } from 'react';
import { useAuth } from '../AuthenticationContext';
import { useQuery } from 'react-query';

interface ISellersCounterProviderProps {
  children: ReactNode;
}

const SellersCounterProvider = (props: ISellersCounterProviderProps) => {
  const [sellersNotFound, setSellersNotFound] = useState<boolean>(true);
  const [value, setValue] = useState<number>(0);

  const auth = useAuth();

  const query = useQuery({
    queryKey: ['sellers'],
    queryFn: () => getSellers(),
    retry: 1,
    enabled: auth.isAuthenticated
  });

  useEffect(() => {
    const length: number = query.data?.items.length ?? 0;
    setValue(length);
    setSellersNotFound(!length);
  }, [query.data?.items.length]);

  const update = () => {
    query.refetch();
  };

  return (
      <SellersCounterContext.Provider
          value={{
            isFetching: query.isFetching,
            sellersNotFound,
            update,
            value
          }}
      >
        <>{props.children}</>
      </SellersCounterContext.Provider>
  );
};

export default SellersCounterProvider;