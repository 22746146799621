import { sellerApiClient } from './sellerApiClient';
import { useQuery } from 'react-query';

export type TOneNews = {
  content: string;
  description: string;
  imgUrl: string;
  isRead: boolean;
  language: string;
  link: string;
  pubDate: Date;
  title: string;
}

export type TNews = TOneNews[];

export type TNewsResponse = {
  items: TNews;
}

export const getNews = async (currentLanguage: string): Promise<TNewsResponse> => {
  const response = await sellerApiClient.get<TNewsResponse>(`/v1/news/all?Language=${currentLanguage}`);
  return response.data;
};

export type TPush = {
  content: string;
  description: string;
  imgUrl: string;
  isExternal: boolean;
  language: string;
  link: string;
  pubDate: Date;
  title: string;
};

export type TPushes = TPush[];

export type TPushesResponse = {
  items: TPushes;
};

export const getPushes = async (currentLanguage: string): Promise<TPushesResponse> => {
  const response = await sellerApiClient.get<TPushesResponse>(`/v1/news/notifications?Language=${currentLanguage}`);
  return response.data;
};

export const sendNewsRead = async (link: string): Promise<void> => {
  await sellerApiClient.post<void>('/v1/news/mark-as-read', { link: link });
};

export type TSendPushesReadRequest = {
  cancelled: boolean;
  link: string;
};

export const sendPushesRead = async (data: TSendPushesReadRequest): Promise<void> => {
  await sellerApiClient.post<void>('/v1/news/mark-as-read-notification', data);
};

export const useNewsCounter = (currentLanguage: string) => {
  const { data, refetch } = useQuery({
    queryKey: ['news-counter'],
    queryFn: () => getNews(currentLanguage),
    retry: 1,
    select: (data) => data.items.filter((item: TOneNews) => !item.isRead).length ?? 0
  });
  return { data, refetch };
};