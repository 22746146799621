import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
  getDropShippingOzon,
  getDropShippingParcelCount,
  getAuthorized,
  AuthorizedAPIs
} from '@api/apiClient/dropshipping';
import { Button, ButtonSize, ButtonType } from "@components/Button";
import Page from "@components/Page/Page.tsx";
import Tabs, { Tab } from '@components/Tabs/Tabs.tsx';
import InProcessTab from "./tabs/InProcessTab.tsx";
import NewTab from "./tabs/NewTab.tsx";
import SellerFilter from '@containers/SellerFilterSingle';
import { Seller } from '../../api/apiClient';
import { useSellersCounter } from '@contexts/SellersCounterContext';
// import AwaitingResolutionTab from "./tabs/AwaitingResolutionTab.tsx";

const DropshippingPage = () => {
  const { t } = useTranslation();
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [seller, setSeller] = useState<AuthorizedAPIs | undefined | null>(undefined);
  const [authorizedSellers, setAuthorizedSellers] = useState<string[]>([]);
  const { sellersNotFound } = useSellersCounter();

  const authorizedQuery = useQuery({
    queryKey: ["dropShippngAuthorized"],
    queryFn: () => getAuthorized(),
    retry: 1,
    onSuccess: (s) => {
      setAuthorizedSellers(s.filter(v => v.isAuthorizedApi).map(v => v.id));
    },
    enabled: !sellersNotFound
  });
  const query = useQuery({
    queryKey: ["dropShippingOzon", seller?.id ?? ""],
    queryFn: () => getDropShippingOzon(seller?.id ?? ""),
    retry: 1,
    enabled: !sellersNotFound && isAuthorized,
  });
  const parcelCountQuery = useQuery({
    queryKey: ["dropShippingParcelCount", seller?.id ?? ""],
    queryFn: () => getDropShippingParcelCount(seller?.id ?? ""),
    retry: 1,
    enabled: !sellersNotFound && isAuthorized,
  });

  const handlerSetSeller = (seller?: Seller): void => {
    const formattedSeller: AuthorizedAPIs = {
      id: seller!.id,
      isAuthorizedApi: seller!.ozonApiExists!,
      name: seller!.name
    };
    setSeller(formattedSeller);
  };

  useEffect(() => {
    if (authorizedQuery.isFetched) {
      const data: AuthorizedAPIs[] = authorizedQuery.data!;
      const sellerId = localStorage.getItem('dropShippingSellerId');
      let savedSeller: AuthorizedAPIs | undefined | null;
      if (sellerId) {
        savedSeller = data.find((item: AuthorizedAPIs) => item.id === sellerId);
      } else {
        savedSeller = data.find((item: AuthorizedAPIs) => !item.isAuthorizedApi) || data[0];
      }
      setIsAuthorized(true);
      setSeller(savedSeller);
    }
  }, [authorizedQuery.isFetched, authorizedQuery.data]);

  useEffect(() => {
    if (seller) {
      localStorage.setItem('dropShippingSellerId', seller!.id);
      seller.isAuthorizedApi && query.refetch();
    }
  }, [seller]);

  const HeaderFilters = useCallback(({ disabled }: { disabled?: boolean }) => (
    sellersNotFound ? null : (
      <>
        <Button
          onClick={() => setSeller(null)}
          size={ButtonSize.XS}
          type={ButtonType.ROUNDED}
          title={t("dropshipping.selectAllSellers")}
        />
        <SellerFilter
          disabled={disabled}
          initialValue={seller || undefined}
          needSortBy={'ozonApiExists'}
          onChange={handlerSetSeller}
          placeholder={t("dropshipping.allSellers")}
        />
      </>
    )
  ), [seller?.id, sellersNotFound]);

  const onApiAuth = () => {
    authorizedQuery.refetch();
    setSeller(seller ? ({ ...seller, isAuthorizedApi: true }) : seller);
  }

  const tabs: Tab[] = [
    {
      id: "NewTab",
      title: t("dropshipping.newTab.title"),
      content: (
        <NewTab
          handlerOnApiAuth={onApiAuth}
          seller={seller}
          authorizedSellers={authorizedSellers}
          sellersNotFound={sellersNotFound}
          onRefetch={() => {
            authorizedQuery.refetch();
          }}
        />
      ),
      count: query.data?.length,
    },
    {
      id: "InProcessTab",
      title: t("dropshipping.inProcessTab.title"),
      content: (
        <InProcessTab
          filter="inProcess"
          seller={seller}
          authorizedSellers={authorizedSellers}
          sellersNotFound={sellersNotFound}
        />
      ),
      count: parcelCountQuery.data?.progress,
      disable: sellersNotFound,
    },
    // {
    //   id: "AwaitingTab",
    //   title: t("dropshipping.awaitingTab.title"),
    //   content: <NewTab />,
    // },
    {
      id: "Complete",
      title: t("dropshipping.completeTab.title"),
      content: (
        <InProcessTab
          filter="complete"
          seller={seller}
          authorizedSellers={authorizedSellers}
          sellersNotFound={sellersNotFound}
        />
      ),
      count: parcelCountQuery.data?.completed,
      disable: sellersNotFound,
    },
    // {
    //   id: "AwaitingResolutionTab",
    //   title: t("dropshipping.awaitingResolutionTab.title"),
    //   content: <AwaitingResolutionTab />,
    // },
  ]

  return (
    <Page
      title={t("dropshipping.title")}
      actions={!sellersNotFound ? <HeaderFilters /> : null}
    >
      <Tabs tabs={(seller !== undefined)
        && (!seller || seller.isAuthorizedApi)
        ? tabs : [tabs[0]]} />
    </Page>
  );
};

export default DropshippingPage;
