import { useState } from "react";
import { Seller } from "@api/apiClient";
import AddSeller from "@components/AddSeller";
import { Modal } from "@components/Modal";
import AddShopModalStepSuccess from "./AddShopModalStepSuccess";

interface AddShopModalProps {
  onClose: () => void;
}

const AddShopModal = ({ onClose }: AddShopModalProps) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [shops, setShops] = useState<Seller[]>([]);

  if (isSuccess) {
    return (
      <Modal
        isOpen={true}
        onClose={onClose}
        onClickOverlay={onClose}
      >
        <AddShopModalStepSuccess
          onClose={onClose}
          data={shops}
        />
      </Modal>
    )
  }

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      onClickOverlay={onClose}
    >
      <AddSeller
        isOzonSeller={false}
        onSuccess={(data) => {
          setShops(data)
          setIsSuccess(true)
        }}
      />
    </Modal>
  )
}

export default AddShopModal;
