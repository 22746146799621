import { Address } from './index';
import { FilterModel } from "@hooks/useFiltersSearchParams";
import { PaginationModel } from "@hooks/usePagingSearchParams";
import { sellerApiClient, getParams, getPost } from "./sellerApiClient";

interface Response<T> {
  total: number,
  items: T[],
}

export const getPickUpServices = async (): Promise<Service[]> => {
  const response = await sellerApiClient.get<{ items: Service[] }>(
    "/v1/pick-up/services",
  );

  return response.data?.items;
}

export const getPickUpParcels = async ({
  page,
  filters,
}: {
  page: PaginationModel;
  filters?: FilterModel[];
}): Promise<Response<PickUpParcel>> => {
  const response = await getPost<Response<PickUpParcel>>(
    "/v1/pick-up/parcels/page",
    {
      params: getParams(page, filters),
      paramsSerializer: { indexes: null },
    }
  );

  return response.data;
};

export const getPickUpOrders = async ({
  page,
  filters,
}: {
  page: PaginationModel;
  filters?: FilterModel[];
}): Promise<Response<PickUpOrderForm>> => {
  const response = await sellerApiClient.get<Response<PickUpOrderForm>>(
    "/v1/pick-up/request-forms/page",
    {
      params: getParams(page, filters),
      paramsSerializer: { indexes: null },
    }
  );
  return response.data;
};

interface Service {
  id: string;
  name: string;
}

interface Seller {
  id: string;
  name: string;
}

interface Weight {
  value: number;
  unit: string;
}

export interface PickUpParcel {
  id: string;
  createdAt: string;
  trackNumber: string;
  externalNumber: string;
  sellerId: string;
  sellerName: string;
  serviceId: string;
  serviceName: string;
  isExtraSmall?: boolean;
  weight: Weight;
}

export interface PickUpOrderForm {
  id: string,
  createdAt: string,
  status: string,
  address: {
    rawLine: string,
    street: string,
    city: string,
    cityDistrict: string,
    settlement: string,
    country: string,
    countryCode: string,
    zipCode: string,
    region: string,
    area: string,
    house: string,
    flat: string
  },
  contactPhone: string,
  contactName: string,
  shipmentDate: string,
  comment: string,
  sellers: Seller[],
  parcelsCount: number,
  deliveryServiceId?: string | null,
  deliveryServiceName?: string | null,
  deliveryTrackNumber?: string | null
}

export interface PickUpOrder {
  id: string,
  createdAt: string,
  status: string,
  address: {
    rawLine: string,
    street: string,
    city: string,
    cityDistrict: string,
    settlement: string,
    country: string,
    countryCode: string,
    zipCode: string,
    region: string,
    area: string,
    house: string,
    flat: string
  },
  contactPhone: string,
  contactName: string,
  shipmentDate: string,
  comment: string,
  parcels: PickUpParcel[],
}

type sendOneTimePickUpRequest = {
  address: {
    area: string;
    city: string;
    cityDistrict: string;
    country: string;
    countryCode: string;
    flat: string;
    house: string;
    rawLine: string;
    region: string;
    settlement: string;
    street: string;
    zipCode: string;
  },
  comment?: string;
  contactName: string;
  contactPhone: string;
  parcelIds: string[];
  shipmentDate: string;
}

type sendOneTimePickUpResponse = {
  id: string;
};

export const sendOneTimePickUp = async (data: sendOneTimePickUpRequest): Promise<sendOneTimePickUpResponse> => {
  const response = await sellerApiClient.post(
      '/v1/pick-up/request-forms',
      data
  );
  return response.data?.id;
};

type getOneTimePickUpRequest = string;

export const getOneTimePickUp = async (id: getOneTimePickUpRequest): Promise<PickUpOrder> => {
  const response = await sellerApiClient.get(
      `/v1/pick-up/request-forms/${id}`
  );
  return response.data;
};

type cancelOneTimePickUpRequest = string;

export const cancelOneTimePickUp = async (id: cancelOneTimePickUpRequest): Promise<void> => {
  await sellerApiClient.post(`/v1/pick-up/request-forms/cancel/${id}`);
};

type changeOneTimePickUpOptionsRequest = {
  address: Address;
  comment: string;
  contactName: string;
  contactPhone: string;
  id: string;
  shipmentDate: string;
};

export const changeOneTimePickUpOptions = async (data: changeOneTimePickUpOptionsRequest): Promise<void> => {
  const {id, ...rest} = data;
  await sellerApiClient.put(`/v1/pick-up/request-forms/options/${id}`, rest);
};

type changeOneTimePickUpParcelsRequest = {
  id: string;
  parcelIds: string[];
};

export const changeOneTimePickUpParcels = async (data: changeOneTimePickUpParcelsRequest): Promise<void> => {
  const {id, ...rest} = data;
  await sellerApiClient.put(`/v1/pick-up/request-forms/parcels/${id}`, rest);
};

export type TPickUpInfo = {
  isAvailable: boolean;
  isNew: boolean;
  allowAllParcels: boolean;
  serviceConstraintsInfo: {
    defaultMinWeightInGrams: number;
    defaultMinCount?: number;
    constraints: {
      isAllowed: boolean;
      serviceGroupId: string;
      serviceGroupName: string;
      minWeightInGrams: number;
      minCount: number;
      services: {
        id: string;
        name: string;
      }[],
    }[];
  };
}

export type TPickUpInfoResponse = TPickUpInfo;

export const getPickUpInfo = async (): Promise<TPickUpInfoResponse> => {
  const response = await sellerApiClient.get(`/v1/pick-up/info`);
  return response.data;
};

export type TDeliveryServices = {
  id: string;
  isDefault: boolean;
  name: string;
};

export type TDeliveryServicesResponse = {
  services: TDeliveryServices[];
};

export const getDeliveryServices = async (): Promise<TDeliveryServicesResponse> => {
  const response = await sellerApiClient.get(`/v1/pick-up/delivery-services`);
  return response.data;
};

export type TDeliveryServiceRequest = {
  deliveryServiceId: string;
  id: string;
  trackNumber: string;
};

export type TDeliveryServiceResponse = {
  deliveryServiceId: string;
  trackNumber: string;
};

export const setDeliveryServices = async (data: TDeliveryServiceRequest): Promise<TDeliveryServiceResponse> => {
  const { id, ...rest } = data;
  const response = await sellerApiClient.post(`/v1/pick-up/request-forms/${id}/set-delivery-info`, rest);
  return response.data;
};
