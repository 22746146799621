import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "@utils/formatValues";
import cn from "classnames";
import Avatar from "@components/Avatar";
import {
  TableColumn,
  TableColumnType,
  TableRowType,
} from "@components/Table/TableTypes";
import { Text, TextColor, TextSize, TextType } from "@components/Text";
import { getParcels, ParcelPageItem } from "@api/apiClient";
import { Row, Column } from "@components/Grid";
import ProgressBar from "@components/ProgressBar";
import { Filters, FilterTypes } from "@containers/Filters";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import {
  getProgressByTrackEvents,
  getLastTrackEvent,
} from "@utils/trackEvents";
import Box from "@components/Box";
import DataTable from "@containers/DataTable";
import styles from "./newTab.module.css";

const NewTab = ({
  isFilterOpen,
  setIsFilterOpen,
  pending,
  onOpenParcel,
  sellersNotFound,
}: {
  pending: boolean,
  onOpenParcel: (id: string) => void,
  isFilterOpen: boolean,
  setIsFilterOpen: (v: boolean) => void,
  sellersNotFound: boolean,
}) => {
  const { t } = useTranslation();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [innerFilters, setInnerFilters] = useState<FilterModel[]>([]);

  const columns: TableColumn<ParcelPageItem>[] = [
    {
      header: t("parcelsPage.newTable.parcelNumber"),
      accessor: (row: TableRowType<ParcelPageItem>) => (
        <span
          className={styles.trackNumber}
          onClick={() => onOpenParcel(row.data.id)}
        >
          {row.data.trackNumber}
        </span>
      ),
      type: TableColumnType.PRIMARY,
      alignment: "left",
      width: "150px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("parcelsPage.newTable.externalNumber"),
      accessor: (row: TableRowType<ParcelPageItem>) => (row.data.externalNumber ?? ""),
      type: TableColumnType.TERTIARY,
      alignment: "left",
      width: "150px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("parcelsPage.newTable.service"),
      accessor: (row: TableRowType<ParcelPageItem>) => row.data.serviceName,
      type: TableColumnType.SECONDARY,
      alignment: "left",
      width: "220px",
    },
    {
      header: t("parcelsPage.newTable.step"),
      accessor: (row: TableRowType<ParcelPageItem>) => {
        const status = getLastTrackEvent(row.data.trackEvents);
        return (
          <>
            <ProgressBar
              progress={getProgressByTrackEvents(row.data.trackEvents)}
              className={styles.progress}
              cells
            />
            <Text
              color={TextColor.DARK_MUTED}
              type={TextType.SPAN}
              size={TextSize.S}
              className={styles.progressText}
            >
              {t(`trackEventTypes.${status ? status.typeId : "0"}`, {
                defaultValue: status?.message || "",
              })}
            </Text>
          </>
        );
      },
      type: TableColumnType.SECONDARY,
      alignment: "left",
      width: "220px",
    },
    {
      header: t("parcelsPage.newTable.seller"),
      accessor: (row: TableRowType<ParcelPageItem>) => (
        <Row noWrap align="center">
          <Avatar
            className={styles.avatar}
            name={row.data.sellerName}
            id={row.data.sellerId}
          />
          <span className={styles.sellerText}>
            {row.data.sellerName}
          </span>
        </Row>
      ),
      type: TableColumnType.TERTIARY,
      alignment: "left",
      width: "220px",
    },
    {
      header: t("parcelsPage.newTable.date"),
      accessor: (row: TableRowType<ParcelPageItem>) => formatDate(row.data.createdAt, "dd.MM.yy HH:mm"),
      type: TableColumnType.TERTIARY,
      alignment: "left",
      width: "110px",
    },
  ];

  return (
    <div>
      <Box pb={5} className={cn(!isFilterOpen && styles.hidden)}>
        <Column phone={12}>
          <Row justify="right" gap={5}>
            <Filters
              id="parcels-new"
              openFilters={() => setIsFilterOpen(true)}
              items={sellersNotFound ? [] : [
                {
                  name: "trackOrExternalNumbers",
                  type: FilterTypes.MULTI_SEARCH,
                  placeholder: t("parcelsPage.trackNumberSearch"),
                },
                {
                  name: "serviceIds",
                  type: FilterTypes.SERVICE_PARCELS,
                },
                {
                  name: "sellerIds",
                  type: FilterTypes.SELLER,
                },
                {
                  name: "dateRange",
                  type: FilterTypes.DATE_RANGE,
                  placeholder: t("filters.createAt"),
                  fieldNames: ["startDate", "endDate"],
                },
              ]}
              onChange={(v, isReady) => {
                if (isReady) {
                  setIsInitialized(true);
                }
                setInnerFilters(v)
              }}
              values={innerFilters}
              asPanel
            />
          </Row>
        </Column>
      </Box>
      <DataTable<ParcelPageItem>
        queryKey="parcels-page"
        pending={pending || !isInitialized}
        getData={getParcels}
        columns={columns}
        notFound={t("parcelsPage.notParcels")}
        filters={innerFilters}
      />
    </div>
  );
};

export default NewTab;
