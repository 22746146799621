import { useState, ReactElement } from 'react';
import DataTable from "@containers/DataTable";
import { getReturnsPage, Return, ReturnTypes } from "@api/apiClient";
import { useTranslation } from "react-i18next";
import {
  TableColumn,
  TableColumnType,
  TableRowType,
} from "@components/Table/TableTypes";
import { AddSellerPanel } from "@components/AddSeller";
import NoItems from "@components/NoItems";
import { Row } from "@components/Grid";
import { Modal } from "@components/Modal";
import CancelModalWindow from "../modals/CancelModalWindow.tsx";
import DisposalQueueModalWindow from "../modals/DisposalQueueModalWindow.tsx";
import ParcelModalWindow from "@containers/ParcelModalWindow";
import Box from "@components/Box";
import { Button, ButtonSize } from "@components/Button";
import Popup from "@components/Popup";
import CopyTextToClipboard from "@components/CopyTextToClipboard";
import Avatar from "@components/Avatar";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import styles from "./returnsPageTabs.module.css";
import moment from "moment";
import { formatDate } from "@utils/formatValues";
import TableSelectionPanel from '@components/Table/TableSelectionPanel';

const ReturnsTab = ({
  filters,
  pending,
  downloadingBtn,
  handlerSetSavedReturnsSelection,
  savedReturnsSelection,
  refreshData,
  sellersNotFound,
  noData,
}: {
  filters: FilterModel[],
  pending: boolean,
  downloadingBtn: ReactElement,
  handlerSetSavedReturnsSelection: (value: (((prevState: string[]) => string[]) | string[])) => void,
  savedReturnsSelection: string[],
  refreshData: () => void,
  sellersNotFound: boolean,
  noData: boolean,
}) => {
  const [isOpenCancelWindow, setIsOpenCancelWindow] = useState(false);
  const [isOpenParcelWindow, setIsOpenParcelWindow] = useState(false);
  const [isOpenDisposalQueueWindow, setIsOpenDisposalQueueWindow] = useState(false);
  const [cancelId, setIsCancelId] = useState("");
  const [detailId, setDetailId] = useState("");
  const { t } = useTranslation();

  const closeParcelWindow = () => {
    setIsOpenParcelWindow(false);
  }

  const columns: TableColumn<Return>[] = [
    {
      header: t("returnTable.parcel_number"),
      accessor: (row: TableRowType<Return>) => (
        <CopyTextToClipboard text={row.data?.trackNumber}>
          <b
            onClick={() => {
              setIsOpenParcelWindow(true)
              setDetailId(row.data.id)
            }}
          >
            {row.data?.trackNumber}
          </b>
        </CopyTextToClipboard>
      ),
      alignment: "left",
      width: "auto"
    },
    {
      header: t("returnTable.internal_number"),
      accessor: (row: TableRowType<Return>) => (
        <CopyTextToClipboard text={row.data?.externalId}>
          {row.data?.externalId ?? ""}
        </CopyTextToClipboard>
      ),
      type: TableColumnType.TERTIARY,
      alignment: "left",
      width: "auto"
    },
    {
      header: t("returnTable.service"),
      accessor: (row: TableRowType<Return>) => row.data.serviceName,
      alignment: "left",
      width: "200px"
    },
    {
      header: t("returnTable.attachment"),
      accessor: (row: TableRowType<Return>) => {
        const firstItem = row.data.items?.[0];
        return firstItem ? (
          <a href={firstItem.url} target="_blank" rel="noreferrer">
            {firstItem.name}
          </a>
        ) : null;
      },
      alignment: "left",
      width: "200px"
    },
    {
      header: t("returnTable.cost"),
      accessor: (row: TableRowType<Return>) =>
        (new Intl.NumberFormat().format(row.data.declaredValue.amount) ?? "") + " ₽",
      alignment: "left",
      width: "auto"
    },
    {
      header: t("returnTable.weight"),
      accessor: (row: TableRowType<Return>) =>
        new Intl.NumberFormat().format(row.data?.weight.value) ?? "",
      alignment: "left",
      width: "auto"
    },
    {
      header: t("returnTable.seller"),
      accessor: (row: TableRowType<Return>) => (
        <Row noWrap align="center">
          <Avatar
            className={styles.avatar}
            name={row.data.sellerName}
            id={row.data.sellerId}
          />
          <span className={styles.sellerText}>
            {row.data.sellerName}
          </span>
        </Row>
      ),
      type: TableColumnType.TERTIARY,
      alignment: "left",
      width: "200px"
    },
    {
      header: t("returnTable.returnAt"),
      accessor: (row: TableRowType<Return>) => (
          <div>
            {formatDate(row.data.returnAt, "dd.MM.yy HH:mm")}
          </div>
      ),
      alignment: "left",
      width: "130px",
    },
    {
      header: "",
      accessor: (row: TableRowType<Return>) => (
        <Box beforeSpace pr={10}>
          {row.data.isCancelable ? (
            <Button
              onClick={() => {
                const condition: boolean = row.data.estimatedUtilizationDate !== null;
                setIsCancelId(row.data.id);
                (condition && moment(row.data.estimatedUtilizationDate).diff(moment(), 'hours') < 24)
                    ? setIsOpenDisposalQueueWindow(true)
                    : setIsOpenCancelWindow(true);
              }}
              size={ButtonSize.XS}
              title={t("disposalTable.resetButton")}
            />
          ) : (
            <Popup text={t("disposalTable.resetButtonNoty")}>
              <Button
                disabled
                onClick={() => {}}
                size={ButtonSize.XS}
                title={t("disposalTable.resetButton")}
              />
            </Popup>
          )}
        </Box>
      ),
      alignment: "left",
      noOverflow: true,
      width: "auto"
    },
  ];

  if (sellersNotFound) {
    return (
      <AddSellerPanel isFirstSeller />
    );
  }

  if (noData) {
    <NoItems
      title={t("noItems.returns.title")}
      description={t("noItems.returns.description")}
    />
  }

  return (
    <TableSelectionPanel
        isShown={savedReturnsSelection.length > 0}
        items={[
          {
            label: t("awaitingTableSelectionPanel.selected"),
            value: savedReturnsSelection.length,
          }
        ]}
        buttons={[downloadingBtn]}
    >
      <DataTable<Return>
        queryKey="returns-page"
        pending={pending}
        getData={getReturnsPage}
        columns={columns}
        selectable
        selectedIds={savedReturnsSelection}
        onSelectionChange={handlerSetSavedReturnsSelection}
        notFound={t("returnTab.notReturns")}
        filters={[
          ...filters,
          {
            name: "ReturnTypes",
            value: [ReturnTypes.DeliverToRussia, ReturnTypes.DeliverToSeller],
          },
        ]}
      />

      <Modal
        isOpen={isOpenCancelWindow}
        onClose={() => {
          setIsOpenCancelWindow(false);
        }}
        onClickOverlay={() => {
          setIsOpenCancelWindow(false);
        }}
      >
        <CancelModalWindow
          parcelId={cancelId}
          onSuccess={() => {
            handlerSetSavedReturnsSelection([]);
            refreshData();
            setIsCancelId("");
            setIsOpenCancelWindow(false);
          }}
          onClose={() => {
            setIsOpenCancelWindow(false);
            setIsCancelId("");
          }}
        />
      </Modal>

      <Modal
        isOpen={isOpenDisposalQueueWindow}
        onClose={() => {
          setIsOpenDisposalQueueWindow(false);
        }}
        onClickOverlay={() => {
          setIsOpenDisposalQueueWindow(false);
        }}
      >
        <DisposalQueueModalWindow
          parcelId={cancelId}
          onSuccess={() => {
            handlerSetSavedReturnsSelection([]);
            refreshData();
            setIsCancelId("");
            setIsOpenDisposalQueueWindow(false);
          }}
          onClose={() => {
            setIsOpenDisposalQueueWindow(false);
            setIsCancelId("");
          }}
          tabType="returns"
        />
      </Modal>

      {isOpenParcelWindow ? (
        <Modal
          isOpen={isOpenParcelWindow}
          onClose={closeParcelWindow}
          onClickOverlay={closeParcelWindow}
          width={900}
          bodyWithoutIndentations
        >
          <ParcelModalWindow parcelId={detailId} />
        </Modal>
      ) : null}
    </TableSelectionPanel>
  );
};

export default ReturnsTab;
