import { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import {
  getReturnsPage,
  Return,
  ReturnItem,
  ReturnTypes,
} from "@api/apiClient";
import DataTable, { BeforeProps } from "@containers/DataTable";
import TableSelectionPanel from "@components/Table/TableSelectionPanel";
import {
  TableColumn,
  TableColumnType,
  TableRowType,
} from "@components/Table/TableTypes";
import { Button, ButtonSize, ButtonType } from "@components/Button";
import Popup from "@components/Popup";
import ProgressBar from "@components/ProgressBar";
import { Text, TextColor, TextSize, TextType } from "@components/Text";
import Box from "@components/Box";
import { Row } from "@components/Grid";
import CopyTextToClipboard from "@components/CopyTextToClipboard";
import { useProfile } from "../../../contexts/ProfileContext";
import Avatar from "@components/Avatar";
import Bag from "@assets/icons/bag.svg";
import Car from "@assets/icons/car.svg";
import Fire from "@assets/icons/fire.svg";
import { getProgressFromTo, getDaysBetweenDates } from "@utils/progress";
import BeforeAwaitingTable from "./BeforeAwaitingTable";
import { getCurrentLanguage, Langs } from "@utils/lang";
import { formatSumWithCurrency } from "@utils/formatValues";
import { FilterModel } from "@hooks/useFiltersSearchParams";
import styles from "./AwaitingTable.module.css";
import WarningIcon from "@assets/icons/warning-red-12.svg";

interface AwaitingTableProps {
  selectedSum: number;
  onClickDisposal: () => void;
  onClickDeliver: () => void;
  onSelectionChange: (ids: string[], data: Return[]) => void;
  onClickResale: (row: Return) => void;
  onClickPhoneConfirm: () => void;
  onParcelClick: (parcelId: string) => void;
  pending?: boolean,
  filters: FilterModel[],
  selectedRowIds: string[],
  selectedExtraSmallCount: number;
  selectedDelivery: number;
  selectedDepartureCount: number;
  downloadingBtn: ReactElement;
}

const AwaitingTable = ({
  onClickPhoneConfirm,
  onClickResale,
  selectedRowIds,
  onClickDisposal,
  onClickDeliver,
  onParcelClick,
  selectedSum,
  selectedExtraSmallCount,
  pending,
  onSelectionChange,
  filters,
  selectedDelivery,
  selectedDepartureCount,
  downloadingBtn,
}: AwaitingTableProps) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const profile = useProfile();
  const isPhoneConfirmed = profile.account?.isPhoneConfirmed || (profile.account?.region !== "CN");

  const BeforeAwaitingTableComponent = useCallback((p: BeforeProps<Return>) => {
    return (
      <BeforeAwaitingTable {...p} />
    );
  }, []);

  const onResellClick = (row: TableRowType<Return>) => {
    isPhoneConfirmed
      ? onClickResale(row.data)
      : onClickPhoneConfirm();
  }

  const columns: TableColumn<Return>[] = useMemo(() => [
    {
      header: t("awaitingTable.parcel_number"),
      accessor: (row: TableRowType<Return>) => (
        <CopyTextToClipboard text={row.data?.trackNumber}>
          <b onClick={(e) => {
            e.stopPropagation()
            onParcelClick(row.data?.id)
          }}>
            {row?.data?.trackNumber}
          </b>
        </CopyTextToClipboard>
      ),
      alignment: "left",
      width: "150px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("awaitingTable.external_number"),
      accessor: (row: TableRowType<Return>) => (
        <CopyTextToClipboard text={row.data?.externalId}>
          {row.data?.externalId ?? ""}
        </CopyTextToClipboard>
      ),
      type: TableColumnType.TERTIARY,
      alignment: "left",
      width: "150px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: t("awaitingTable.service"),
      accessor: (row: TableRowType<Return>) => row.data.serviceName,
      alignment: "left",
      width: "200px",
    },
    {
      header: t("awaitingTable.attachment"),
      accessor: (row: TableRowType<Return>) => {
        const firstItem = row.data.items?.[0];
        return firstItem ? (
          <a href={firstItem.url ?? ""} target="_blank" rel="noreferrer">
            {firstItem.name ?? ""}
          </a>
        ) : null;
      },
      alignment: "left",
      width: "200px",
    },
    {
      header: t("awaitingTable.value"),
      accessor: (row: TableRowType<Return>) =>
        formatSumWithCurrency(row.data?.declaredValue?.amount, "₽"),
      alignment: "left",
      width: "70px",
      fixWidth: true,
    },
    {
      header: t("awaitingTable.quantity"),
      accessor: (row: TableRowType<Return>) => row.data.items.length ? row.data.items.reduce((accumulator: number, item: ReturnItem) => accumulator + item.quantity, 0) : '',
      alignment: "left",
      width: "50px",
    },
    {
      header: t("awaitingTable.delivery_cost"),
      accessor: (row: TableRowType<Return>) => (
        <div
          style={{
            backgroundColor: "#f3f4f8",
            borderRadius: "12px",
            display: "inline-block",
            padding: "3px 10px",
          }}
        >
          {currentLanguage === Langs.tr ? "12$" : row.data.isDeparture ? "95 ¥" : "10 ¥"}
        </div>
      ),
      alignment: "left",
      width: "70px",
    },
    {
      header: t("awaitingTable.until_disposal"),
      accessor: (row: TableRowType<Return>) => {
        const restDays = getDaysBetweenDates(null, row.data.estimatedUtilizationDate);
        return (
          <ProgressBar
            reverseColors
            progress={getProgressFromTo(row.data.arrivedToDropOffAt, row.data.estimatedUtilizationDate, true)}
            label={restDays ? `${restDays} ${t("awaitingTable.days")}` : t("awaitingTable.lastDay")}
            column
          />
        )
      },
      alignment: "left",
      width: "100px",
    },
    {
      header: t("awaitingTable.seller"),
      accessor: (row: TableRowType<Return>) => (
        <Row noWrap align="center">
          <Avatar
            className={styles.avatar}
            name={row.data.sellerName}
            id={row.data.sellerId}
          />
          <span className={styles.sellerText}>
            {row.data.sellerName}
          </span>
        </Row>
      ),
      type: TableColumnType.TERTIARY,
      alignment: "left",
      width: "220px",
    },
    {
      header: "",
      accessor: (row: TableRowType<Return>) => (
        <Box beforeSpace pr={10}>
          {(
            row.data.hasLinkedResales
            && !row.data.isDeparture
          ) ? (
            <Button
              icon={Bag}
              onClick={() => onResellClick(row)}
              size={ButtonSize.XS}
              title={t("awaitingTable.resellButton")}
            />
          ) : (
            <Popup text={t("awaitingTable.notResale")}>
              <Button
                icon={Bag}
                disabled
                onClick={() => {}}
                size={ButtonSize.XS}
                title={t("awaitingTable.resellButton")}
              />
            </Popup>
          )}
        </Box>
      ),
      alignment: "left",
      noOverflow: true,
      width: "200px",
    },
  ], [isPhoneConfirmed, currentLanguage]);

  return (
    <TableSelectionPanel
      isShown={selectedRowIds.length > 0}
      items={[
        {
          label: t("awaitingTableSelectionPanel.selected"),
          value: selectedRowIds.length,
        },
        {
          label: t("awaitingTableSelectionPanel.sum"),
          value: `${Intl.NumberFormat().format(selectedSum)} ₽`,
        },
        {
          label: t("awaitingTableSelectionPanel.delivery"),
          value: (
            currentLanguage === Langs.tr ? (
              `${Intl.NumberFormat().format(selectedRowIds.length * 12)} $`
            ) : (
              `${Intl.NumberFormat().format(selectedDelivery)} ¥`
            )
          ),
        },
      ]}
      buttons={[
        downloadingBtn,
        (<Button
          key="disposal"
          onClick={onClickDisposal}
          size={ButtonSize.S}
          icon={Fire}
          type={ButtonType.DANGER}
          title={t("awaitingTableSelectionPanel.to_recycle")}
          disabled={selectedDepartureCount > 0}
        />),
        (<Button
          key="deliver"
          onClick={onClickDeliver}
          size={ButtonSize.S}
          icon={Car}
          title={t("awaitingTableSelectionPanel.to_return")}
          disabled={selectedExtraSmallCount > 0}
        />),
      ]}
      footer={(selectedExtraSmallCount || selectedDepartureCount) ? (
          <>
            {selectedExtraSmallCount ? (
                <div className={styles.panelFooter}>
                  <Text
                      size={TextSize.M}
                      type={TextType.BLOCK}
                      color={TextColor.ERROR_DARK}
                  >
                    <img
                        alt="Warning"
                        className={styles.warning}
                        src={WarningIcon}
                    />
                    {t('awaitingPage.await_extrasmall1')}
                    {selectedRowIds.length > selectedExtraSmallCount && ' ' + t('awaitingPage.await_extrasmall2')}
                  </Text>
                </div>
            ) : null}
            {selectedDepartureCount ? (
                <>
                  <div className={styles.panelFooter}>
                    <Text
                        size={TextSize.M}
                        type={TextType.BLOCK}
                        color={TextColor.ERROR_DARK}
                    >
                      <img
                          alt="Warning"
                          className={styles.warning}
                          src={WarningIcon}
                      />
                      {t('awaitingPage.await_departure1')}
                    </Text>
                  </div>
                  {selectedRowIds.length > selectedDepartureCount && (
                      <div className={styles.panelFooter}>
                        <Text
                            size={TextSize.M}
                            type={TextType.BLOCK}
                            color={TextColor.ERROR_DARK}
                        >
                          <img
                              alt="Warning"
                              className={styles.warning}
                              src={WarningIcon}
                          />
                          {t('awaitingPage.await_departure2')}
                        </Text>
                      </div>
                  )}
                </>
            ) : null}
          </>
      ) : null}
    >
      <DataTable<Return>
        Before={BeforeAwaitingTableComponent}
        queryKey="returns-page"
        pending={pending}
        getData={getReturnsPage}
        columns={columns}
        selectable
        selectedIds={selectedRowIds}
        onSelectionChange={onSelectionChange}
        notFound={t("awaitingPage.not_await")}
        filters={[
          ...filters,
          { name: "ReturnTypes", value: [ReturnTypes.NotSelected] },
        ]}
        rowClassName={(row) => row.isSelected && (row.data?.isExtraSmall || row.data?.isDeparture) ? styles.extraSmallParcelRow : ""}
      />
    </TableSelectionPanel>
  );
};

export default AwaitingTable;
