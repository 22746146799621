// import moment from "moment";
import { ru, tr, zhCN, enUS } from 'date-fns/locale';
import { format } from 'date-fns';
import { getCurrentLanguage } from "@utils/lang";

const locales = {
  cn: zhCN,
  en: enUS,
  ru: ru,
  tr: tr,
};

interface Weight {
  unit: string;
  value: number;
}

export function weightKg(weight: Weight): string {
  if (!weight) return ""
  const res = ({
    'g': 0.001,
    'kg': 1,
  }[weight.unit] || 1) * weight.value;
  if (!res) return "";
  return new Intl.NumberFormat().format(res) ?? "";
}

export function weightGValue(weight: Weight): number {
  if (!weight) return 0
  return (({
    'g': 1,
    'kg': 1000,
  }[weight.unit] || 1) * weight.value);
}

export function formatDate(value: string | Date | undefined | null, dateFormat: string): string {
  if (!value) return ""
  const currentLanguage = getCurrentLanguage();
  return format(value, dateFormat, { locale: locales[currentLanguage] })
  // return moment(value).locale('ru').format(dateFormat);
}

export function formatSumWithCurrency(
  value: string | number | undefined,
  currency: string,
): string {
  if (typeof value === "undefined") return ""
  const v = (typeof value === "string") ? value : (new Intl.NumberFormat().format(value));
  return `${v} ${currency}`;
}

export function formatSumFromObject(sumObj: {
  amount: string | number,
  currency: string,
}): string {
  if (!sumObj) return "";
  const { amount, currency } = sumObj;
  if (!amount) return "";
  const v = (typeof amount === "string") ? amount : (new Intl.NumberFormat().format(amount));
  return `${v} ${getCurrency(currency)}`;
}

const currencies: Record<string, string> = {
  EUR: "€",
  USD: "$",
  CNY: "¥",
  RUB: "₽",
  TRY: "TRY",
}

export function getCurrency(code: string) {
  return currencies[code] ?? code;
}
