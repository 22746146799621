import { FilterModel } from '@hooks/useFiltersSearchParams';

export interface ISortBy {
  columnsSort: string[];
  filters: FilterModel[];
  position: number;
  setColumnsSort: (value: (((prevState: string[]) => string[]) | string[])) => void;
  setInnerFilters: (value: (((prevState: FilterModel[]) => FilterModel[]) | FilterModel[])) => void;
  value: string;
}

export const sortByPrice = (params: ISortBy): void => {
  const { columnsSort, filters, position, setColumnsSort, setInnerFilters, value } = params;
  const newColumnsSort: string[] = columnsSort.map((item: string, index: number) => {
    if (position === index) {
      return item === 'desc' ? 'asc' : 'desc';
    } else {
      return item.length ? 'desc' : '';
    }
  });
  setColumnsSort(newColumnsSort);
  const newInnerFilters: FilterModel[] = filters.filter((item: FilterModel) =>
      item.value[0] !== 'Price' && item.value[0] !== 'SellerName');
  const filtersByPrice: FilterModel = {
    fieldNames: ['field', 'direction'],
    name: 'Sort',
    value: ['Price', value.charAt(0).toUpperCase() + value.slice(1)]
  };
  newInnerFilters.push(filtersByPrice);
  setInnerFilters(newInnerFilters);
};

export const sortByStores = (params: ISortBy): void => {
  const { columnsSort, filters, position, setColumnsSort, setInnerFilters, value } = params;
  const newColumnsSort: string[] = columnsSort.map((item: string, index: number) => {
    if (position === index) {
      return item === 'desc' ? 'asc' : 'desc';
    } else {
      return item.length ? 'desc' : '';
    }
  });
  setColumnsSort(newColumnsSort);
  const newInnerFilters: FilterModel[] = filters.filter((item: FilterModel) =>
      item.value[0] !== 'Price' && item.value[0] !== 'SellerName');
  const filtersByStores: FilterModel = {
    fieldNames: ['field', 'direction'],
    name: 'Sort',
    value: ['SellerName', value.charAt(0).toUpperCase() + value.slice(1)]
  };
  newInnerFilters.push(filtersByStores);
  setInnerFilters(newInnerFilters);
};