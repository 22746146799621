import { createContext, useContext } from 'react';

interface ISellersCounterContext {
  isFetching: boolean;
  sellersNotFound: boolean;
  update: () => void;
  value: number;
}

const SellersCounterContext = createContext<ISellersCounterContext | null>(null);

export const useSellersCounter = (): ISellersCounterContext => {
  const context = useContext(SellersCounterContext);
  if (!context) {
    throw new Error('useSellersCounter must be used within an SellersCounterProvider');
  }
  return context;
};

export default SellersCounterContext;
export { default as SellersCounterProvider } from '././SellersCounterProvider';