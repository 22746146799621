import { useState, useEffect, useMemo } from "react";
import cn from "classnames";
import { Filter, FilterTypes } from "@containers/Filter";
import { useFiltersSearchParams, FilterModel } from "@hooks/useFiltersSearchParams";
import CrossIcon from "@assets/icons/cross-grey-16.svg";
import { ButtonIcon } from "@components/ButtonIcon";
import styles from "./Filters.module.css";

// interface FilterValue {
//   name: string,
//   value: string[] | string,
//   fieldNames?: string[],
// }

interface FiltersDescription {
  name: string,
  type: FilterTypes,
  placeholder?: string,
  fieldNames?: string[],
}

// interface FiltersItem {
//   name: string,
//   value: string[] | string,
// }

const Filters = ({ id, items, onChange, values, asPanel, openFilters }: {
  id?: string,
  items: FiltersDescription[],
  onChange?: (v: FilterModel[], isReady?: boolean) => void,
  values?: FilterModel[],
  asPanel?: boolean,
  openFilters?: () => void,
}) => {
  const [initialValues, setInitialValues] = useState<Record<string, string[]>>({});
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [unreadyFilters, setUnreadyFilters] = useState<string[]>([]);
  const { filters, updateFilter, setFilters } = useFiltersSearchParams(id);
  const key = useMemo(() => JSON.stringify(new Date), [initialValues]);

  useEffect(() => {
    let actualFilters = filters;
    try {
      if (id && !filters.length) {
        actualFilters = JSON.parse(localStorage.getItem(`savedFilters-${id}`) || "");
      }
    } catch (e) {
      console.warn(e);
    }
    let values = (actualFilters).reduce<Record<string, string[]>>((
      acc: Record<string, string[]>,
      v: FilterModel,
    ) => {
      const res = { ...acc };
      if (items.some(f => f.name === v.name)) {
        res[v.name] = [
          ...(acc[v.name] || []),
          ...(Array.isArray(v.value) ? v.value : [v.value]),
        ];
      }
      return res;
    }, {});
    setInitialValues(values);

    const nonEmptyFilters = actualFilters.filter(f => {
      if (!f.value) return false;
      if (Array.isArray(f.value)) return Boolean(f.value.find(v => v))
      return true;
    })
    if (openFilters && nonEmptyFilters.length) openFilters();

    onChange && onChange((Object.keys(values) || []).map((k) => ({
      name: k,
      value: values[k],
    })));
    setIsInitialized(true);

    const simpleTypes = [
      FilterTypes.STATUS,
      FilterTypes.STATE,
      FilterTypes.SEARCH,
      FilterTypes.MULTI_SEARCH,
      FilterTypes.MULTI_SEARCH_OR_NAME,
      FilterTypes.MULTI_SELECT,
    ];
    setUnreadyFilters(items
      .filter(f => (
        !simpleTypes.find(v => v === f.type)
        && !nonEmptyFilters.find(v => v.name === f.name)
      ))
      .map(v => v.name)
    );
  }, [])

  useEffect(() => {
    if (isInitialized && !unreadyFilters?.length) {
      onChange && onChange(values || [], true);
    }
  }, [unreadyFilters?.length, isInitialized])

  if (!isInitialized) return null;

  return (
    <div key={key} className={cn(styles.filters, asPanel && styles.filtersPanel)}>
      {items.map(f => (
        <Filter
          key={`${key}-${f.name}`}
          initialValue={initialValues[f.name]}
          placeholder={f.placeholder}
          type={f.type}
          onChange={(v: string[], rv?: string[]) => {
            const newUnreadyFilters = unreadyFilters.filter(v => v !== f.name);
            updateFilter({
              name: f.name,
              value: v,
            });
            const newValues = [...(values || [])];
            const idx = newValues.findIndex(item => (item.name === f.name));
            newValues[(idx > -1) ? idx : newValues.length] = {
              name: f.name,
              fieldNames: f.fieldNames,
              value: rv?.length ? rv : v,
            };

            localStorage.setItem(`savedFilters-${id}`, JSON.stringify(newValues));
            onChange && onChange(newValues, !newUnreadyFilters?.length);

            setUnreadyFilters((curFilters) => curFilters.filter(v => v !== f.name));
          }}
          asPanel={asPanel}
        />
      ))}
      {asPanel ? (
        <ButtonIcon
          className={styles.closeBtn}
          icon={CrossIcon}
          onClick={() => {
            setFilters(items.map((f) => ({
              name: f.name,
              value: "",
            })));
            onChange && onChange([
              ...(values || []).map(item => {
                const filter = items.find(f => item.name === f.name);
                if (!filter) return item;
                return { ...item, value: [] };
              }),
            ]);
            setInitialValues(items.reduce((acc, v) => ({ ...acc, [v.name]: "" }), {}))
          }}
        />
      ) : null}
    </div>
  );
}

export { Filters, FilterTypes };
