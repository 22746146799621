import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { getPickUpServices } from "@api/apiClient/pickUp";
import { getReturnServices } from "@api/apiClient/returns";
import { getParcelsServices, Service } from "@api/apiClient";
import { getProblematicServices } from "@api/apiClient/problematicParcels";
import MultiSelectFilter from "../Filter/MultiSelectFilter";
import { FilterTypes } from "@containers/Filter/types";

type AvailableTypes = FilterTypes.SERVICE_PICKUP
  | FilterTypes.SERVICE_PARCELS
  | FilterTypes.SERVICE_RETURNS
  | FilterTypes.SERVICE_PROBLEMATIC;

const queryKeys = {
  [FilterTypes.SERVICE_PICKUP]: "pickup-services",
  [FilterTypes.SERVICE_PARCELS]: "parcels-services",
  [FilterTypes.SERVICE_RETURNS]: "returns-services",
  [FilterTypes.SERVICE_PROBLEMATIC]: "problematic-services",
}

const queryFns: Record<AvailableTypes, () => Promise<Service[]>> = {
  [FilterTypes.SERVICE_PICKUP]: () => getPickUpServices(),
  [FilterTypes.SERVICE_PARCELS]: () => getParcelsServices(),
  [FilterTypes.SERVICE_RETURNS]: () => getReturnServices(),
  [FilterTypes.SERVICE_PROBLEMATIC]: () => getProblematicServices(),
}

const ServiceFilter = ({ type, onChange, initialValue, asPanel }: {
  onChange: (sellers: string[]) => void,
  initialValue: string[],
  asPanel?: boolean,
  type: AvailableTypes,
}) => {
  const { t } = useTranslation();

  const { isFetching, data } = useQuery({
    queryKey: queryKeys[type],
    queryFn: queryFns[type],
    retry: 1,
    select: (items) => items.map(item => ({ id: item.id, value: item.name })),
  });

  return (
    <MultiSelectFilter
      onChange={onChange}
      initialValue={initialValue}
      isFetching={isFetching}
      data={data || []}
      placeholder={t("filters.serviceFilter")}
      asPanel={asPanel}
    />
  );
}

export default ServiceFilter;
