import { TableColumn, TableColumnType, TableRowType } from "./TableTypes.ts";
import cn from "classnames";
import Checkbox from "../Checkbox/Checkbox.tsx";

export type TableRowProps<T> = {
  index: number;
  row: T;
  isSelected: boolean;
  onToggleSelect: (id: string) => void;
  columns: TableColumn<T>[];
  selectable?: boolean;
  isRowSelectable?: (data: T) => boolean;
  id: string;
  rowClassName?: string | ((row: TableRowType<T>) => string);
  isStriped?: boolean;
  rowOnClick?: (row: T) => void;
};

function TableRow<T>(props: TableRowProps<T>): JSX.Element {
  const rowClassName: string = (typeof props.rowClassName === "function")
    ? props.rowClassName({
        index: props.index,
        data: props.row,
        isSelected: props.isSelected,
      })
    : props.rowClassName ?? "";

  return (
    <tr
      className={cn({
        "table__row": true,
        "table__row--clickable": props.rowOnClick,
        "table__row--selected": props.isSelected,
        "table__row--striped": props.isStriped,
        [rowClassName]: rowClassName,
      })}
      onClick={() => props.rowOnClick?.(props.row)}
    >
      {props.selectable && (
        <td className={"table__cell table__cell--checkbox"}>
          {(
            (typeof props.isRowSelectable !== "function")
            || props.isRowSelectable(props.row)
          ) ? (
            <Checkbox
              id={props.id}
              checked={props.isSelected}
              onChange={() => props.onToggleSelect(props.id)}
            />
          ) : null}
        </td>
      )}

      {props.columns.map((column, index) => (
        <td
          className={cn("table__cell", column.alignment && `table__cell--${column.alignment}`)}
          key={index}
          style={
            column.fixWidth === true
              ? { width: column.width }
              : { maxWidth: column.width }
          }
        >
          <span
            style={
              column.fixWidth === true
                ? { width: column.width }
                : { maxWidth: column.width }
            }
            className={cn({
              "table__cell-content": true,
              "table__cell-content--primary": column.type === TableColumnType.PRIMARY,
              "table__cell-content--secondary": column.type === TableColumnType.SECONDARY,
              "table__cell-content--tertiary": column.type === TableColumnType.TERTIARY,
              "table__cell-content--no-overflow": column.noOverflow,
            })}
          >
            {column.accessor({
              index: props.index,
              data: props.row,
              isSelected: props.isSelected,
            })}
          </span>
        </td>
      ))}
    </tr>
  );
}

export default TableRow;
