import { useEffect, useMemo, useState, useRef } from 'react';
import { Button, ButtonType, ButtonSize } from "@components/Button";
import { Grid, Column, Row } from "@components/Grid";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from 'react-query';
import { TabsList } from "@components/Tabs/Tabs";
import { Text, TextColor, TextSize, TextType, TextWeight } from "@components/Text";
import Box from "@components/Box";
import Table from "@components/Table/Table";
import {
  TableColumn,
  TableRowType,
} from "@components/Table/TableTypes";
import FormErrorMessage from "@components/FormErrorMessage";
import { TSavingParcel, saveReturns } from "@api/apiClient/saving";
import {
  checkBalanceReplenishment,
  checkBalanceReplenishmentResponse,
  getPaymentLink,
  getWallet,
  sendTopUpBalance,
  sendTopUpBalanceResponse,
  TGetPaymentLinkResponse
} from '@api/apiClient/finance';
import { formatSumFromObject } from "@utils/formatValues";
import PinkWalletIcon from "@assets/icons/pink-wallet.svg";
import WalletIcon from "@assets/icons/wallet.svg";
import CheckIcon from '@assets/icons/check_62.svg';
import { useProfile } from "@contexts/ProfileContext";
import PaymentReturnModalWindow from '@components/modals/PaymentReturnModalWindow/PaymentReturnModalWindow';
// import { IPaymentReturnModalWindowProps } from '@components/modals/PaymentReturnModalWindow/types';

interface SavingModalWindowProps {
  parcels: TSavingParcel[];
  success: () => void;
  close: () => void;
}

const SavingModalWindow = (props: SavingModalWindowProps) => {
  const { t } = useTranslation();
  const { account, get } = useProfile();
  const timeoutCheckBalance = useRef<number | null>(null);
  const timeoutCheckPayment = useRef<number | null>(null);
  const [paymentReturnError, setPaymentReturnError] = useState<string | undefined>(undefined);
  const [isOpenPaymentReturnWindow, setIsOpenPaymentReturnWindow] = useState(false);
  const [paymentReturnInProcessed, setPaymentReturnInProcessed] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [topUpAmount, setTopUpAmount] = useState<number>(0);
  const moreCount = props.parcels?.length > 8 ? (props.parcels.length - 8) : 0;

  const walletQuery = useQuery({
    queryKey: ['getWallet'],
    queryFn: () => getWallet(),
    retry: 1
  });
  const rescueAmount = +props.parcels.reduce((acc: number, item: TSavingParcel) => (acc + item.rescueAmount), 0).toFixed(2);
  const rescueCurrency = account?.region === "TR" ? "USD" : "CNY";
  const lackOfFunds: number = Math.max(rescueAmount - (walletQuery.data?.balance ?? 0), 0);

  const {
    data: dataSendTopUpBalance,
    error: errorSendTopUpBalance,
    isError: isErrorSendTopUpBalance,
    isLoading: isLoadingSendTopUpBalance,
    mutate: mutateSendTopUpBalance,
    // reset: resetSendTopUpBalance
  } = useMutation({
    mutationFn: sendTopUpBalance,
    onSuccess: (result: sendTopUpBalanceResponse) => {
      setIsOpenPaymentReturnWindow(true);
      // setIsOpenReturnProcessingWindow(false);
      setPaymentTimeout();
      waitingBalanceReplenished(result.outTradeNo);
    }
  });

  const {
    error: errorGetPaymentLink,
    isError: isErrorGetPaymentLink,
    isLoading: isLoadingGetPaymentLink,
    mutate: mutateGetPaymentLink
  } = useMutation({
    mutationFn: getPaymentLink,
    onSuccess: (result: TGetPaymentLinkResponse) => {
      setPaymentReturnInProcessed(true);
      setPaymentTimeout();
      waitingBalanceReplenished();
      window.open(result.paymentLink);
    }
  });

  const {
    error,
    isError,
    isLoading,
    isSuccess,
    mutate,
  } = useMutation({
    mutationFn: saveReturns,
    onSuccess: () => {
      props.success();
    },
  });

  const { mutate: mutateCheckBalance } = useMutation({
    mutationFn: getWallet,
    onSuccess: (result) => {
      if (result.balance >= rescueAmount) {
        mutate({
          parcelIds: props.parcels.map(item => item.id),
          aseAmount: topUpAmount,
        });
      } else {
        waitingBalanceReplenished();
      }
    }
  });

  const { mutate: mutateCheckBalanceReplenishment } = useMutation({
    mutationFn: checkBalanceReplenishment,
  });

  const submit = () => {
    if (lackOfFunds) {
      setTopUpAmount(lackOfFunds);
      if (account!.region === 'TR') {
        setIsOpenPaymentReturnWindow(true);
      } else {
        mutateSendTopUpBalance({ amount: lackOfFunds });
      }
    } else {
      mutate({
        parcelIds: props.parcels.map(item => item.id),
      });
    }
  };

  const waitingBalanceReplenished = (id?: string): void => {
    timeoutCheckBalance.current = window.setTimeout(() => {
      if (id) {
        mutateCheckBalanceReplenishment(id, {
          onSuccess: (data: checkBalanceReplenishmentResponse) => {
            if (data.isProcess) {
              if (!paymentReturnInProcessed) setPaymentReturnInProcessed(true);
              waitingBalanceReplenished(id);
            } else {
              if (paymentReturnInProcessed) setPaymentReturnInProcessed(false);
              if (data.isSuccess) {
                clearTimeout(timeoutCheckPayment.current as number);
                get();
                setIsOpenPaymentReturnWindow(false);
              } else {
                if (data.errorCode) {
                  clearTimeout(timeoutCheckPayment.current as number);
                  setPaymentReturnError(data.errorCode);
                } else {
                  waitingBalanceReplenished(id);
                }
              }
            }
          }
        });
      } else {
        mutateCheckBalance();
      }
    }, 3000);
  };

  const onClickPaymentLink = (amount: string) => {
    mutateGetPaymentLink(amount);
  };

  const setPaymentTimeout = (): void => {
    timeoutCheckPayment.current = window.setTimeout(() => {
      clearTimeout(timeoutCheckBalance.current as number);
      setPaymentReturnInProcessed(false);
    }, 180000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutCheckBalance.current as number);
      clearTimeout(timeoutCheckPayment.current as number);
    };
  }, [])

  const columns: TableColumn<TSavingParcel>[] = useMemo(() => [
    {
      header: "",
      accessor: (row: TableRowType<TSavingParcel>) => (
        <>
          <Text
            color={TextColor.PRIMARY}
            onHoverColor={TextColor.BLUE}
            size={TextSize.S}
            weight={TextWeight.BOLD}
          >
            {row?.data?.trackNumber}
          </Text>

          <Text
            color={TextColor.DARK_MUTED}
            size={TextSize.XS}
            type={TextType.BLOCK}
          >
            {row?.data?.externalId}
          </Text>
        </>
      ),
      alignment: "left",
      width: "150px",
      fixWidth: true,
      noOverflow: true,
    },
    {
      header: "",
      accessor: (row: TableRowType<TSavingParcel>) => {
        const firstItem = row.data.items?.[0];
        return firstItem ? (
          <a href={firstItem.url ?? ""} target="_blank" rel="noreferrer">
            {firstItem.name ?? ""}
          </a>
        ) : null;
      },
      alignment: "left",
      width: "200px",
    },
    {
      header: "",
      accessor: (row: TableRowType<TSavingParcel>) => (
        <Text
          color={TextColor.PRIMARY}
          onHoverColor={TextColor.BLUE}
          size={TextSize.S}
          weight={TextWeight.BOLD}
          type={TextType.BLOCK}
        >
          {formatSumFromObject(row.data?.estimatedPrice)}
        </Text>
      ),
      alignment: "left",
      width: "100px",
    },
  ], []);

  if (isSuccess) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}>
        <Row>
          <Column phone={12}>
            <Box pb={8} pt={5}>
              <img src={CheckIcon} alt="check" />
            </Box>
          </Column>
        </Row>
        <Row justify="center">
          <Column phone={12}>
            <Box pb={2}>
              <Text
                color={TextColor.PRIMARY}
                size={TextSize.XXL}
                weight={TextWeight.BOLD}
              >
                {t('rescueModal.successTitle')}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row justify="center">
          <Column phone={12}>
            <Box pb={4}>
              <Text
                color={TextColor.PRIMARY}
                size={TextSize.M}
              >
                {t('rescueModal.successDescription')}
              </Text>
            </Box>
          </Column>
        </Row>
        <Row noWrap>
          <Box pb={5}>
            <Button
              onClick={props.close}
              title={t('rescueModal.okBtn')}
            />
          </Box>
        </Row>
      </div>
    );
  }

  if (isOpenPaymentReturnWindow) {
    return (
      <PaymentReturnModalWindow
        dataSendTopUpBalance={dataSendTopUpBalance!}
        errorGetPaymentLink={errorGetPaymentLink}
        handlerOnClickPaymentLink={onClickPaymentLink}
        isErrorGetPaymentLink={isErrorGetPaymentLink}
        isLoadingGetPaymentLink={isLoadingGetPaymentLink}
        isLoadingSendTopUpBalance={isLoadingSendTopUpBalance}
        mutateSendTopUpBalance={mutateSendTopUpBalance}
        paymentReturnError={paymentReturnError}
        paymentReturnInProcessed={paymentReturnInProcessed}
        selectedDelivery={rescueAmount}
      />
    )
  }

  return (
    <Grid>
      <Row>
        <Column phone={12}>
          <Box pb={4}>
            <Text type={TextType.H2} color={TextColor.PRIMARY}>
              {t("rescueModal.title")}
            </Text>
          </Box>
        </Column>
      </Row>
      <Row>
        <Column phone={12}>
          <Box pb={4}>
            <Text size={TextSize.S} color={TextColor.PRIMARY}>
              {t("rescueModal.description")}
            </Text>
          </Box>
        </Column>
      </Row>

      <Row>
        <Column phone={12}>
          <Box pb={4} pt={4}>
            <Text
              type={TextType.SPAN}
              size={TextSize.L}
              color={TextColor.PRIMARY}
              weight={TextWeight.BOLD}
            >
              {t("rescueModal.infoTitle")}
            </Text>
          </Box>
        </Column>
      </Row>

      <Box flex="row" pb={5}>
        <Box flex="column" pr={10}>
          <Text size={TextSize.M} color={TextColor.PRIMARY}>
            {t("rescueModal.saving")}
          </Text>
          <Box pt={1}>
            <Text size={TextSize.L} color={TextColor.PRIMARY} weight={TextWeight.BOLD}>
              {formatSumFromObject({
                amount: rescueAmount,
                currency: rescueCurrency,
              })}
            </Text>
          </Box>
        </Box>
        <Box flex="column" pl={12}>
          <Text size={TextSize.M} color={TextColor.PRIMARY}>
            {t("rescueModal.balance")}
          </Text>
          <Box flex="row" pt={1}>
            <img
              src={lackOfFunds ? PinkWalletIcon : WalletIcon}
              alt="wallet"
              style={{ marginRight: "5px", width: "15px" }}
            />
            <Text
              size={TextSize.L}
              color={lackOfFunds ? TextColor.WARM_PINK : TextColor.PRIMARY}
              weight={TextWeight.BOLD}
            >
              {formatSumFromObject({
                amount: walletQuery.data?.balance ?? 0,
                currency: rescueCurrency,
              }) || "0"}
            </Text>
          </Box>
        </Box>
        {lackOfFunds ? (
          <Box flex="column" pl={12}>
            <Text size={TextSize.M} color={TextColor.PRIMARY}>
              {t("rescueModal.short")}
            </Text>
            <Box pt={1}>
              <Text size={TextSize.L} color={TextColor.PRIMARY} weight={TextWeight.BOLD}>
                {formatSumFromObject({
                  amount: lackOfFunds,
                  currency: rescueCurrency,
                }) || "0"}
              </Text>
            </Box>
          </Box>
        ) : (
          <Box flex="column" pl={12}>{" "}</Box>
        )}
      </Box>

      <TabsList
        tabs={[{
          id: "rescue",
          title: t("rescueModal.savingTab"),
          content: null,
          count: props.parcels.length,
        }]}
        onChange={() => {}}
        value="rescue"
      />

      <Table<TSavingParcel>
        columns={columns}
        data={showAll ? props.parcels : props.parcels.slice(0, 8)}
        isHeadless
        isGrey
      />

      {(moreCount && !showAll) ? (
        <Row>
          <Column phone={12}>
            <Box pt={3}>
              <Button
                type={ButtonType.GREY_TEXT}
                onClick={() => setShowAll(true)}
                title={t("rescueModal.more", { count: moreCount })}
                size={ButtonSize.S}
                isWide
              />
            </Box>
          </Column>
        </Row>
      ) : null}

      {isError && (
        <FormErrorMessage error={error} />
      )}
      {isErrorSendTopUpBalance && (
        <FormErrorMessage error={errorSendTopUpBalance} />
      )}
      {isErrorSendTopUpBalance && (
        <FormErrorMessage error={errorSendTopUpBalance} />
      )}

      <Row>
        <Column phone={12}>
          <Box beforeSpace={true} pt={5}>
            <Button
              type={ButtonType.SKELETON}
              onClick={props.close}
              title={t("rescueModal.cancelBtn")}
              size={ButtonSize.S}
            />
            <div style={{ marginRight: 10 }}></div>
            <Button
              pending={isLoading}
              type={ButtonType.TERTIARY}
              onClick={submit}
              title={t("rescueModal.savingBtn")}
              size={ButtonSize.S}
            />
          </Box>
        </Column>
      </Row>
    </Grid>
  );
};

export default SavingModalWindow;
