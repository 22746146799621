import styles from './Tab.module.scss';
import Avatar from '@components/Avatar';
import Box from '@components/Box';
import CardParcelModalWindow from '../modals/CardParcelModalWindow/CardParcelModalWindow';
import cn from 'classnames';
import CopyTextToClipboard from '@components/CopyTextToClipboard';
import DataTable, { DataViewHandle } from '@containers/DataTable';
import { FilterModel } from '@hooks/useFiltersSearchParams';
import { Filters, FilterTypes } from '@containers/Filters';
import { formatDate, formatSumWithCurrency } from '@utils/formatValues';
import { getCurrentLanguage } from '@utils/lang';
import { getDisposal, TDisposalParcel } from '@api/apiClient/returns';
import { IDisposalTabProps } from '../types';
import { Modal } from '@components/Modal';
import { Row, Column } from '@components/Grid';
import { sortByPrice, sortByStores } from '@utils/sorting';
import { TableColumn, TableColumnType, TableRowType } from '@components/Table/TableTypes.ts';
import { Text, TextColor, TextSize, TextWeight } from '@components/Text';
import { Tooltip } from '@components/Tooltip';
import { useProfile } from '@contexts/ProfileContext';
import { useState, useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const DisposalTab: React.FC<IDisposalTabProps> = (props: IDisposalTabProps): React.ReactElement => {
  const {
    isFilterOpen,
    setIsFilterOpen,
    // onUpdate
  } = props;

  const [columnsSort, setColumnsSort] = useState<string[]>([]);
  const [innerFilters, setInnerFilters] = useState<FilterModel[]>([]);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isOpenParcelWindow, setIsOpenParcelWindow] = useState(false);
  const [parcel, setParcel] = useState<TDisposalParcel | undefined>(undefined);

  const dataRef = useRef<DataViewHandle>(null);

  const currentLanguage = getCurrentLanguage();
  const { account } = useProfile();
  const { t } = useTranslation();

  const variableCurrency: string = account?.region === 'TR' ? '$' : '¥';

  // const refreshData = () => {
  //   if (dataRef.current?.refresh) {
  //     dataRef.current.refresh();
  //   }
  //   onUpdate();
  // };

  const onCloseParcel = () => {
    setIsOpenParcelWindow(false);
  };

  const handlerSortByPrice = (position: number, value: string, filters: FilterModel[]): void => {
    sortByPrice({ columnsSort, filters, position, setColumnsSort, setInnerFilters, value });
  };

  const handlerSortByStores = (position: number, value: string, filters: FilterModel[]): void => {
    sortByStores({ columnsSort, filters, position, setColumnsSort, setInnerFilters, value });
  };

  const columns: TableColumn<TDisposalParcel>[] = useMemo(() => [
    {
      header: t('returnsPage.disposalTab.trackNumber'),
      accessor: (row: TableRowType<TDisposalParcel>) => (
          <>
            <CopyTextToClipboard
                text={row.data?.directStreamExternalId || ''}
                visibleOnHover
            >
              <span
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpenParcelWindow(true);
                    setParcel(row.data);
                  }}
              >
                <Text
                    color={TextColor.PRIMARY}
                    onHoverColor={TextColor.BLUE}
                    size={TextSize.S}
                    weight={TextWeight.BOLD}
                >
                  {row?.data?.directStreamExternalId}
                </Text>
              </span>
            </CopyTextToClipboard>
            <CopyTextToClipboard
                text={row.data?.trackingNumber || ''}
                visibleOnHover
            >
              <span
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpenParcelWindow(true);
                    setParcel(row.data);
                  }}
              >
                <Text
                    color={TextColor.DARK_MUTED}
                    size={TextSize.XS}
                >
                  {row?.data?.trackingNumber}
                </Text>
              </span>
            </CopyTextToClipboard>
          </>
      ),
      alignment: 'left',
      width: '205px'
    },
    {
      header: t('returnsPage.disposalTab.name'),
      accessor: (row: TableRowType<TDisposalParcel>) => (
          <a
              className={styles.link}
              href={row?.data?.url}
              rel="noreferrer"
              target="_blank"
          >
            {row?.data?.title}
          </a>
      ),
      alignment: 'left',
      width: '400px'
    },
    {
      header: t('returnsPage.disposalTab.priceOfProduct'),
      accessor: (row: TableRowType<TDisposalParcel>) => (
          <div className={styles.price}>
            <Text
                color={TextColor.PRIMARY}
                size={TextSize.S}
            >
              {formatSumWithCurrency(row.data.priceRub, '₽')}
            </Text>
            <Text
                color={TextColor.DARK_MUTED}
                size={TextSize.XS}
            >
              {formatSumWithCurrency(row.data.priceCny, variableCurrency)}
            </Text>
          </div>
      ),
      alignment: 'right',
      noOverflow: true,
      sortable: true,
      sortableFn: handlerSortByPrice,
      width: '100px'
    },
    {
      header: t('returnsPage.disposalTab.store'),
      accessor: (row: TableRowType<TDisposalParcel>) => (
          <div className={styles.store}>
            <Tooltip
                action={
                  <Avatar
                      className={styles.avatar}
                      name={row.data.seller.name}
                      id={row.data.seller.id}
                  />
                }
                left={-227}
                text={row.data.seller.name}
                top={-9}
            />
          </div>
      ),
      alignment: 'center',
      noOverflow: true,
      sortable: true,
      sortableFn: handlerSortByStores
    },
    {
      header: t('returnsPage.disposalTab.date'),
      accessor: (row: TableRowType<TDisposalParcel>) => (formatDate(row.data.utilizationVoteDate, 'dd.MM.yy')),
      alignment: 'left',
      type: TableColumnType.TERTIARY,
      width: '100px'
    }
  ], [currentLanguage, columnsSort]);

  useEffect(() => {
    const columnsSort: string[] = columns.map((item: TableColumn<TDisposalParcel>) => item.sortable ? 'asc' : '');
    setColumnsSort(columnsSort);
  }, []);

  return (
      <div>
        <Box pb={5}>
          <Text
              color={TextColor.PRIMARY}
              size={TextSize.S}
          >
            {t('returnsPage.disposalTab.info_1')}
          </Text>
        </Box>
        <Box
            pb={5}
            className={cn(!isFilterOpen && styles.hidden)}
        >
          <Column phone={12}>
            <Row
                justify="right"
                gap={5}
            >
              <Filters
                  id="returns-disposal"
                  openFilters={() => setIsFilterOpen(true)}
                  items={[
                    {
                      name: 'trackNumbers',
                      type: FilterTypes.MULTI_SEARCH,
                      placeholder: t('filters.searchByTrackNumber')
                    },
                    {
                      name: 'sellerIds',
                      type: FilterTypes.SELLER
                    },
                    {
                      name: 'dateRange',
                      type: FilterTypes.DATE_RANGE,
                      placeholder: t('returnsPage.disposalTab.date'),
                      fieldNames: ['startDate', 'endDate']
                    }
                  ]}
                  onChange={(v, isReady) => {
                    if (isReady) {
                      setIsInitialized(true);
                    }
                    setInnerFilters(v);
                  }}
                  values={innerFilters}
                  asPanel
              />
            </Row>
          </Column>
        </Box>
        <DataTable<TDisposalParcel>
            columns={columns}
            columnsSort={columnsSort}
            dataRef={dataRef}
            defaultPageSize={10}
            filters={innerFilters}
            getData={getDisposal}
            notFound={t('returnsPage.disposalTab.noParcels')}
            pending={!isInitialized}
            queryKey="disposalParcels"
        />
        <Modal
            bodyWithoutIndentations
            isOpen={isOpenParcelWindow}
            onClickOverlay={onCloseParcel}
            onClose={onCloseParcel}
            width={900}
        >
          <CardParcelModalWindow
              callFrom="disposalTab"
              handlerSetIsOpenParcelWindow={setIsOpenParcelWindow}
              parcel={parcel!}
          />
        </Modal>
      </div>
  );
};

export default DisposalTab;
